import React, { useState, useEffect } from "react";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import ReactSwitch from "react-switch";

// css
import "./NavSettings.css";

const NavSettings = () => {
  const { user } = useAuthContext();
  const { updateDocument } = useFirestore("settings");
  const { document: navTypeDoc } = useDocument("settings", "navbarType");
  const { document: NavMenuNames } = useDocument("settings", "EditNavbarMenus");

  const [activeNav, setActiveNav] = useState("");
  const [editingField, setEditingField] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [quoteButtonStatus, setQuoteButtonStatus] = useState(false);

  useEffect(() => {
    if (navTypeDoc) {
      setActiveNav(navTypeDoc.activeNav || "");
    }
  }, [navTypeDoc]);

  useEffect(() => {
    if (NavMenuNames && typeof NavMenuNames.getQuoteButton === "boolean") {
      setQuoteButtonStatus(NavMenuNames.getQuoteButton);
    }
  }, [NavMenuNames]);
  
  const navTypeLabels = {
    LeftNav: "Left Navigation",
    RightNav: "Right Navigation",
    BottomNav: "Bottom Navigation",
    TopNav: "Top Navigation",
    FixNav: "Fixed Navigation",
    secondaryNav: "Secondary Navigation"
  };

  const handleRoleChange = async (navTypeLabel) => {
    try {
      await updateDocument("navbarType", { activeNav: navTypeLabel });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };

  const handleEditClick = (field) => {
    setEditingField(field);
    setEditedValue(NavMenuNames ? NavMenuNames[field] : "");
  };

  const handleSaveClick = async (field) => {
    setEditingField(null);
    try {
      const updatedNavMenuNames = { ...NavMenuNames, [field]: editedValue };
      await updateDocument("EditNavbarMenus", updatedNavMenuNames);
    } catch (error) {
      console.error("Error updating menu name:", error);
    }
  };

  // Exclude the 'id' and 'getQuoteButton' keys from the object to be rendered in the UI
  const filteredNavMenuNames = NavMenuNames
    ? Object.entries(NavMenuNames).filter(([key]) => key !== "id" && key !== "getQuoteButton")
    : [];


  const handleToggleChange = async (checked) => {
    setQuoteButtonStatus(checked); // Update the state locally
    try {
      await updateDocument("EditNavbarMenus", {
        getQuoteButton: checked,
      });
    } catch (error) {
      console.error("Error updating page status:", error);
    }
  };

  return (
    <div>
        <div className="container role">
          <div className="section-title">
            <p>Navbar Setting</p>
          </div>
          <br/>
          <div className="section-title">
            <h2>Select Your Nav Style</h2>
          </div>
          <div className="radio-container">
            {Object.keys(navTypeLabels).map((navType, index) => (
              <label
                key={index}
                className={`radio-container ${activeNav === navType
                  ? "radio_btn_checked"
                  : "service_details_radion_btn"
                  }`}
                style={{
                  color:
                    activeNav === navType ? "var(--white-color)" : "inherit",
                  backgroundColor:
                    activeNav === navType ? "var(--click-color)" : "inherit",
                }}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="nav"
                    value={navType}
                    onChange={() => {
                      setActiveNav(navType);
                      handleRoleChange(navType);
                    }}
                    className="radio-button"
                  />
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "21px" }}
                  >
                    {activeNav === navType ? "done" : "add"}
                  </span>
                  {navTypeLabels[navType]}
                </div>
              </label>
            ))}
          </div>
          <br />
          <br />
          <div className="section-title">
            <h2>Change Nav Menus Name</h2>
          </div>
          <div className="nav-setting-menu-edit-container">
            {filteredNavMenuNames.map(([key, value], index) => (
              <div key={index} className="nav-setting-menu-edit-item">
                {editingField === key ? (
                  <>
                    <input
                      type="text"
                      value={editedValue}
                      onChange={(e) => setEditedValue(e.target.value)}
                      className="nav-setting-edit-input"
                    />
                    <span
                      className="material-symbols-outlined nav-setting-save-icon"
                      onClick={() => handleSaveClick(key)}
                    >
                      done
                    </span>
                  </>
                ) : (
                  <>
                    {value}
                    &nbsp;
                    <span
                      className="material-symbols-outlined nav-setting-edit-icon"
                      onClick={() => handleEditClick(key)}
                    >
                      edit
                    </span>
                  </>
                )}
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="section-title">
            <h2>Toggle "Get Quote" Button Visibility</h2>
          </div>
          <div style={{ margin: "0px 0px 20px 0px" }}>
            <div className="d-flex align-items-center mt-2">
              <div>
                <p
                  className="active-inactive-text"
                  style={{ color: "red" }}
                >
                  Inactive
                </p>
              </div>

              <ReactSwitch
                checked={quoteButtonStatus}
                onChange={handleToggleChange}
                onColor="#149AA2"
                offColor="#F05B83"
                className="products-status-switch"
              />
              <div>
                <p
                  className="active-inactive-text"
                  style={{ color: "red" }}
                >
                  Active
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default NavSettings;

import React from "react";
import "./EventOneExp.scss";

const Experience = () => {
  return (
    <div className="experience">
      <div className="container">
        <div className="experience_inner">      
          <div className="section_header">
            <div className="left">
              <div className="icon">
              <img src="./assets/img/ecommerce/ecomTwo/icons/destination.png" alt="" className="icon" />
              </div>
              <div>
              <h2 className="title">FIND NEW EXPERIENCES</h2>
              <h6 className="sub_title">Explore. Discover. Make a Plan</h6>
              </div>
            </div>
            <div className="right">
         
            </div>
          
          </div>
          <div className="experience-images">
            <div className="image-item">
              <img src="./assets/img/ecommerce/ecomTwo/Exp1.png" alt="Music" />
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/Exp2.webp"
                alt="Comedy"
              />
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/Exp3.webp"
                alt="Courses"
              />
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/Exp4.webp"
                alt="Workshops"
              />
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/Exp5.webp"
                alt="Workshops"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;

import React, { useState, useEffect, useRef } from "react";
import "./BusinessThreeAboutUs.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CountUp from "react-countup";

const BusinessThreeAboutUs = ({ id }) => {
  const counterRef = useRef(null);
  const [counterKey, setCounterKey] = useState(Date.now());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Update key to restart counters
            setCounterKey(Date.now());
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  return (
    <div style={{ backgroundColor: "var(--white-color)" }} id={id}>
      <div className="large_padding_div"></div>
      <div className="large_padding_div"></div>

      <div className="container">
        <div className="business-three-hero-hero-section">
          <div className="business-three-hero-hero-left">
            <OwlCarousel
              className="business-three-hero-owl-theme"
              loop
              autoplay
              margin={10}
              items={1}
            >
              <div className="business-three-hero-carousel-item">
                <div className="business-three-hero-image-wrapper">
                  <img
                    src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Ff_auto%2Cq_auto%3Abest%2Cw_700%2Fv1679041277%2FWebsite%2520v5%2FBanner%2FNo_Brokerage.jpg&w=640&q=75"
                    alt="Carousel 1"
                  />
                </div>
                <div className="business-three-hero-carousel-text">
                  <h1>
                    Delivering top linen & reliable workwear for multi
                    industries
                  </h1>
                  <br />
                  <p>
                    We provide high-quality linen and durable workwear tailored
                    to meet the diverse needs of different industries.
                  </p>
                </div>
              </div>
              <div className="business-three-hero-carousel-item">
                <div className="business-three-hero-image-wrapper">
                  <img
                    src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Ff_auto%2Cq_auto%3Abest%2Cw_700%2Fv1673266619%2FWebsite%2520v5%2FBanner%2FCoed-2.jpg&w=640&q=75"
                    alt="Carousel 2"
                  />
                </div>
                <div className="business-three-hero-carousel-text">
                  <h1>Excellence in linen & workwear solutions</h1>
                  <br />
                  <p>
                    Our dedication lies in offering superior linen products and
                    cutting-edge workwear, ensuring comfort and efficiency for
                    all users.{" "}
                  </p>
                </div>
              </div>
            </OwlCarousel>
            <div className="business-three-hero-search-bar">
              <input type="text" placeholder="Find in and around..." />
              <button>Search</button>
            </div>
          </div>
          <div className="business-three-hero-hero-right">
            <div className="business-three-hero-hero-card">
              <img
                src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Ff_auto%2Cq_auto%2Fv1653291829%2FWebsite%2520v5%2FProductCards%2FStudent_1_copy.png&w=1200&q=75"
                alt="Eco-Friendly Solutions"
              />
              <div>
                <h3>Nature-Friendly Solutions</h3>
                <span className="material-symbols-outlined">arrow_forward</span>
              </div>
            </div>
            <div className="business-three-hero-hero-card">
              <img
                src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Ff_auto%2Cq_auto%2Fv1653291829%2FWebsite%2520v5%2FProductCards%2FCo-living_1_copy.png&w=1200&q=75"
                alt="Professional Dry Cleaning"
              />
              <div>
                <h3>Professional Dry Cleaning</h3>
                <span className="material-symbols-outlined">arrow_forward</span>
              </div>
            </div>
            <div className="business-three-hero-hero-card">
              <img
                src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Ff_auto%2Cq_auto%2Fv1653291830%2FWebsite%2520v5%2FProductCards%2FApartments_1_copy.png&w=1200&q=75"
                alt="Quality Laundry Services"
              />
              <div>
                <h3>Quality Laundry Services</h3>
                <span className="material-symbols-outlined">arrow_forward</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={counterRef} className="home-three-hero-banner-below-parent">
        <div className="container">
          <div className="row">
          <div className="col-4 d-flex align-items-center justify-content-center">
              <span className="material-symbols-outlined">groups</span> &nbsp;
              <h5 style={{ color: "var(--black-color)" }}>
                <CountUp
                  key={`customers-${counterKey}`}
                  start={0}
                  end={5000}
                  duration={3}
                  suffix="+"
                />
                <span> Customers</span>
              </h5>
            </div>
           
            <div className="col-4 d-flex align-items-center justify-content-center">
              <span className="material-symbols-outlined">wifi</span> &nbsp;
              <h5 style={{ color: "var(--black-color)" }}>
                <CountUp
                  key={`services-${counterKey}`}
                  start={0}
                  end={150}
                  duration={4}
                  suffix="+"
                />
                <span> Services</span>
              </h5>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
              <span className="material-symbols-outlined">home_work</span>{" "}
              <h5 style={{ color: "var(--black-color)" }}>
                <CountUp
                  key={`cities-${counterKey}`}
                  start={0}
                  end={24}
                  duration={4}
                  suffix="+"
                />
                <span> Cities</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessThreeAboutUs;

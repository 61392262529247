import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './EducationOneTestimonialsTwo.scss'; // Your custom styles

const EducationOneTestimonialsTwo = () => {
  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: true,
    center: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="EducationOneTestimonials-section">
      <div className="container">
      <div className="section-title">
        <h2>What Students Say</h2>
      </div>
      <OwlCarousel className="owl-theme" {...options}>
        <div className="testimonial-item">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
          <div className="testimonial-author">
            <img src="https://themes.envytheme.com/edali/wp-content/uploads/2020/02/user1.jpg" alt="David Warner" />
            <h3>David Warner</h3>
            <span>Art Director</span>
          </div>
        </div>

        <div className="testimonial-item highlighted">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
          <div className="testimonial-author">
            <img src="https://via.placeholder.com/100" alt="James Anderson" />
            <h3>James Anderson</h3>
            <span>Designer</span>
          </div>
        </div>

        <div className="testimonial-item">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
          <div className="testimonial-author">
            <img src="https://via.placeholder.com/100" alt="Lina D'Souza" />
            <h3>Lina D'Souza</h3>
            <span>Copywriter</span>
          </div>
        </div>
      </OwlCarousel>
    </div>
  </div>
  );
};

export default EducationOneTestimonialsTwo;

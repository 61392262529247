import React from "react";

// import components
import EventOneExp from "../eventOneComponents/EventOneExp";
import EventOneFeature from "../eventOneComponents/EventOneFeature";
import EventOneEvents from "../eventOneComponents/EventOneEvents"
import EventOneMusic from "../eventOneComponents/EventOneMusic"
import EventOneComedy from "../eventOneComponents/EventOneComedy"
import EventOneWorkshop from "../eventOneComponents/EventOneWorkshop"
import EventOneCourse from "../eventOneComponents/EventOneCourse"
import EventOneHealth from "../eventOneComponents/EventOneHealth"
import EventOneWeek from "../eventOneComponents/EventOneWeek"
import EventOneMagazine from "../eventOneComponents/EventOneMagazine"
import EventOneFooter from "../eventOneComponents/EventOneFooter"
import EventOneBanner from "../eventOneComponents/EventOneBanner"
import EventOneArtist from "../eventOneComponents/EventOneArtist"
import EventOneCard from "../eventOneComponents/EventOneCard"


const EventOneHome = () => {
  return (
    <div className="ecom_two_home">
      <EventOneBanner />
      <EventOneExp />
      <EventOneFeature />
      <EventOneEvents />
      <EventOneMusic />
      <EventOneComedy />
      <EventOneWorkshop />
      <EventOneCourse />
      * <EventOneHealth />
      <EventOneWeek />
      <EventOneMagazine />
      <EventOneArtist />
      <EventOneCard />

      <EventOneFooter />

    </div>
  );
};

export default EventOneHome;

import React from "react";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useCollection } from "../firebase files/hooks/useCollection";
import { projectStorage } from "../firebase config/config";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { projectFirestore } from "../firebase config/config";
import Popup from "./Popup";
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCardText,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { FaEdit, FaHeart } from "react-icons/fa";
import ReactSwitch from "react-switch";
import Gallery from "react-image-gallery";
import { useEffect, useRef, useState } from "react";
const Career = ({ showOnlyOneOnHome }) => {
  // Start firebase code
  const { user } = useAuthContext();

  const { documents: careerDoc, error: careerDocError } = useCollection(
    "career",
    null,
    ["order"]
  );

  // Render only the first document if showOnlyOneOnHome is true
  const careerDocuments = showOnlyOneOnHome
    ? [careerDoc && careerDoc[0]]
    : careerDoc;

  const { updateDocument, deleteDocument } = useFirestore("career");

  // End Firebase code

  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [checked, setChecked] = useState(); // Toggle button state
  const [expandedSection, setExpandedSection] = useState(null);
  const [editedJobTitle, setEditedJobTitle] = useState("");
  const [isEditingJobTitle, setIsEditingJobTitle] = useState(false);
  const [editedJobDescription, setEditedJobDescription] = useState("");
  const [isEditingJobDescription, setIsEditingJobDescription] = useState(false);
  const [editedPrimarySkills, setEditedPrimarySkills] = useState("");
  const [isEditingPrimarySkills, setIsEditingPrimarySkills] = useState(false);
  const [editedDualProficiency, setEditedDualProficiency] = useState("");
  const [isEditingDualProficiency, setIsEditingDualProficiency] =
    useState(false);
  const [editedLocation, setEditedLocation] = useState("");
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [editedQualifications, setEditedQualifications] = useState("");
  const [isEditingQualifications, setIsEditingQualifications] = useState(false);
  const [editedResponsibilities, setEditedResponsibilities] = useState("");
  const [isEditingResponsibilities, setIsEditingResponsibilities] =
    useState(false);
  const [editedContactInformation, setEditedContactInformation] = useState("");
  const [isEditingContactInformation, setIsEditingContactInformation] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState([]); // State to hold the selected image file
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [careerIdToDelete, setCareerIdToDelete] = useState();
  const [isHeartClicked, setIsHeartClicked] = useState({});

  // useEffect(() => {
  //   if (careerDocuments && careerDocuments.length > 0) {
  //     const firstCareerDoc = careerDocuments[0];

  //     if (!deepEqual(previousDocRef.current, firstCareerDoc)) {
  //       previousDocRef.current = firstCareerDoc;
  //       setEditedFields({
  //         jobTitle: firstCareerDoc?.jobTitle || "",
  //         jobDescription: firstCareerDoc?.jobDescription || "",
  //         primarySkills: firstCareerDoc?.primarySkills || "",
  //         dualProficiency: firstCareerDoc?.dualProficiency || "",
  //         location: firstCareerDoc?.location || "",
  //         qualifications: firstCareerDoc?.qualifications || "",
  //         responsibilities: firstCareerDoc?.responsibilities || "",
  //         contactInformation: firstCareerDoc?.contactInformation || "",
  //       });
  //     }
  //   }
  // }, [careerDocuments]);

  // CODE FOR ADD IMAGE IN GALLERY
  const handleImageChange = (event) => {
    const files = event.target.files; // Get the selected files
    const selectedImagesArray = Array.from(files); // Convert FileList to array
    setSelectedImage(selectedImagesArray); // Set the selected files to state
  };
  // Function to handle confirming the selected image
  const handleConfirmImage = async (productId) => {
    try {
      setIsImageUploading(true); // Set isImageUploading to true when uploading starts

      // Retrieve current careerImages array from Firestore
      const doc = await projectFirestore
        .collection("career")
        .doc(productId)
        .get();
      const currentImages = doc.data().careerImages || []; // Get current images or initialize as empty array

      // Upload the selected images to Firebase Storage and get their download URLs
      const downloadURLs = await Promise.all(
        selectedImage.map(async (image) => {
          // Upload image to Firebase Storage
          const storageRef = projectStorage.ref(
            `career_img/${productId}/${image.name}`
          );
          await storageRef.put(image);
          // Get download URL of the uploaded image
          return storageRef.getDownloadURL();
        })
      );

      // Concatenate the new URLs with the current images
      const updatedImages = [...currentImages, ...downloadURLs];

      // Update your document in Firestore with the updated careerImages array
      await projectFirestore
        .collection("career")
        .doc(productId)
        .update({ careerImages: updatedImages });

      // Reset selected images state
      setSelectedImage([]);
      setIsImageUploading(false); // Set isImageUploading to false when uploading finishes
    } catch (error) {
      console.error("Error adding images:", error);
      setIsImageUploading(false); // Ensure isImageUploading is set to false in case of error
    }
  };
  //END CODE FOR ADD IMAGE IN GALLERY

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "jobtitle":
        setIsEditingJobTitle(true);
        break;
      case "jobdescription":
        setIsEditingJobDescription(true);
        break;
      case "primaryskills":
        setIsEditingPrimarySkills(true);
        break;
      case "dualproficiency":
        setIsEditingDualProficiency(true);
        break;
      case "location":
        setIsEditingLocation(true);
        break;
      case "qualifications":
        setIsEditingQualifications(true);
        break;
      case "responsibilities":
        setIsEditingResponsibilities(true);
        break;
      case "contactinformation":
        setIsEditingContactInformation(true);
        break;

      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName, productId) => {
    try {
      switch (fieldName) {
        case "jobtitle":
          await updateDocument(productId, { jobTitle: editedJobTitle });
          setEditedJobTitle(editedJobTitle);
          setIsEditingJobTitle(false);
          break;
        case "jobdescription":
          await updateDocument(productId, {
            jobDescription: editedJobDescription,
          });
          setEditedJobDescription(editedJobDescription);
          setIsEditingJobDescription(false);
          break;
        case "primaryskills":
          await updateDocument(productId, {
            primarySkills: editedPrimarySkills,
          });
          setEditedPrimarySkills(editedPrimarySkills);
          setIsEditingPrimarySkills(false);
          break;
        case "dualproficiency":
          await updateDocument(productId, {
            dualProficiency: editedDualProficiency,
          });
          setEditedDualProficiency(editedDualProficiency);
          setIsEditingDualProficiency(false);
          break;
        case "location":
          await updateDocument(productId, {
            location: editedLocation,
          });
          setEditedLocation(editedLocation);
          setIsEditingLocation(false);
          break;
        case "qualifications":
          await updateDocument(productId, {
            qualifications: editedQualifications,
          });
          setEditedQualifications(editedQualifications);
          setIsEditingQualifications(false);
          break;
        case "responsibilities":
          await updateDocument(productId, {
            responsibilities: editedResponsibilities,
          });
          setEditedResponsibilities(editedResponsibilities);
          setIsEditingResponsibilities(false);
          break;
        case "contactinformation":
          await updateDocument(productId, {
            contactInformation: editedContactInformation,
          });
          setEditedContactInformation(editedContactInformation);
          setIsEditingContactInformation(false);
          break;

        // Add more cases for other fields if needed
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "jobtitle":
        setIsEditingJobTitle(false);
        break;
      case "jobdescription":
        setIsEditingJobDescription(false);
        break;
      case "primaryskills":
        setIsEditingPrimarySkills(false);
        break;
      case "dualproficiency":
        setIsEditingDualProficiency(false);
        break;
      case "location":
        setIsEditingLocation(false);
        break;
      case "qualifications":
        setIsEditingQualifications(false);
        break;
      case "responsibilities":
        setIsEditingResponsibilities(false);
        break;
      case "contactinformation":
        setIsEditingContactInformation(false);
        break;

      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  // START CODE FOR SHARE SERVICE
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };
  // END CODE FOR SHARE SERVICE

  // START CODE FOR CHANGE SERVICE STATUS
  const handleToggleChange = (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status in Firestore
    projectFirestore
      .collection("career")
      .doc(productId)
      .update({ status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
        setChecked(newStatus === "active"); // Update the toggle state based on the new status
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE SERVICE STATUS

  // START CODE FOR DELETE CAREER DOC
  const handleDeleteCareerDoc = async (careerId) => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
      setCareerIdToDelete(careerId); // Set the careerId to delete when the confirmation is received
    } catch (error) {
      console.error("Error deleting career:", error);
      // Handle the error as needed
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      // Call deleteDocument function with the careerId to delete the document
      await deleteDocument(careerIdToDelete);
      setIsDeletePopupOpen(false); // Close the popup after successful deletion
      setCareerIdToDelete(null); // Reset the careerIdToDelete after deletion
    } catch (error) {
      console.error("Error deleting career:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR DELETE CAREER DOC

  // START CODE FOR DRAG SERVICES
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = careerDoc[draggedIndex];
        const droppedService = careerDoc[droppedIndex];
        const updatedServices = [...careerDoc];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG SERVICES

  //START CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE
  // Update toggleHeartStatus function
  const toggleHeartStatus = (careerId) => {
    const newHeartStatus = !isHeartClicked[careerId];
    setIsHeartClicked((prevState) => ({
      ...prevState,
      [careerId]: newHeartStatus,
    }));
    localStorage.setItem(`heartStatus_${careerId}`, newHeartStatus);
  };

  // Initialize heart status object in useEffect
  // useEffect(() => {
  //   if (careerDocuments) {
  //     const initialHeartStatusFromLocalStorage = {};
  //     careerDocuments.forEach((career) => {
  //       const localStorageKey = `heartStatus_${career && career.id}`;
  //       const storedHeartStatus = localStorage.getItem(localStorageKey);
  //       if (storedHeartStatus !== null) {
  //         initialHeartStatusFromLocalStorage[career && career.id] =
  //           storedHeartStatus === "true";
  //       } else {
  //         // If no heart status is stored, default to false
  //         initialHeartStatusFromLocalStorage[career && career.id] = false;
  //       }
  //     });
  //     setIsHeartClicked(initialHeartStatusFromLocalStorage);
  //   }
  // }, [careerDocuments]);

  //END CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE

  // START CODE FOR EXPAND CAREER CONTENT
  const toggleExpand = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  // END CODE FOR EXPAND CAREER CONTENT

  return (
    <div>
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this career?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      {careerDocuments &&
        careerDocuments.map((career, index) =>
          (user && user.role === "admin") ||
          (career && career.status === "active") ? (
            <div
              className={`${draggedIndex === index ? "dragging" : ""}`}
              draggable={user && user.role === "admin" && true}
              onDragStart={() => handleDragStart(index)}
              onDrag={(e) => handleDrag(e)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDrop(index)}
              key={index}
            >
              <div className="container">
                {user && user.role === "admin" && (
                  <div
                    className="drag-button-container-only-faq"
                    style={{
                      position: "static",
                      transform: "none",
                      textAlign: "center",
                    }}
                  >
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: "var(--click-color)",
                        fontSize: "25px",
                        cursor: "grab",
                      }}
                      title=" "
                    >
                      apps
                    </span>
                    <span className="tooltip">Drag and swap faq position</span>
                  </div>
                )}
                <div className="career-section">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="pcs_image_area">
                        <div className="bigimage_container">
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              maxWidth: "600px",
                            }}
                          >
                            {career &&
                            career.careerImages &&
                            career &&
                            career.careerImages.length > 0 ? (
                              <Gallery
                                items={
                                  career &&
                                  career.careerImages.map((url) => ({
                                    original: url,
                                    thumbnail: url,
                                  }))
                                }
                                slideDuration={1000}
                              />
                            ) : (
                              <p>No images available</p>
                            )}
                          </div>
                          {/* Box for adding more images */}
                          {user && user.role == "admin" && (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ margin: "15px 0px" }}
                            >
                              {selectedImage == 0 && (
                                <>
                                  <button
                                    className="btn_fill"
                                    onClick={() =>
                                      document
                                        .getElementById("imageUpload")
                                        .click()
                                    }
                                  >
                                    Add More Images
                                  </button>
                                  <input
                                    type="file"
                                    id="imageUpload"
                                    accept="image/*"
                                    multiple // Allow selecting multiple images
                                    style={{ display: "none" }}
                                    onChange={handleImageChange} // Call handleImageChange function when images are selected
                                  />
                                </>
                              )}

                              {selectedImage != 0 && (
                                <>
                                  {selectedImage.length !== 0 ? (
                                    <>
                                      <button
                                        className="btn_fill"
                                        onClick={() =>
                                          handleConfirmImage(career.id)
                                        }
                                        disabled={isImageUploading} // Disable the button when uploading
                                      >
                                        {isImageUploading
                                          ? "Uploading..."
                                          : "Confirm"}
                                      </button>
                                    </>
                                  ) : (
                                    <button disabled className="btn_fill">
                                      Uploading...
                                    </button>
                                  )}
                                </>
                              )}

                              {/* Render selected images */}
                              {selectedImage.map((image, index) => (
                                <img
                                  key={index}
                                  src={URL.createObjectURL(image)}
                                  alt={`Selected ${index + 1}`}
                                  style={{
                                    marginLeft: "10px",
                                    maxWidth: "100px",
                                  }}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                      <MDBCard className="mb-4">
                        <MDBCardBody style={{ textAlign: "left" }}>
                          <MDBRow>
                            <MDBCol sm="12">
                              <MDBCardText style={{ fontWeight: "bold" }}>
                                Job Title:
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingJobTitle ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={editedJobTitle}
                                      onChange={(e) =>
                                        setEditedJobTitle(e.target.value)
                                      }
                                    />
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick("jobtitle", career.id)
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("jobtitle")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {career && career.jobTitle}

                                    {!isEditingJobTitle &&
                                      user &&
                                      user.role == "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--pink)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("jobtitle")
                                          }
                                        />
                                      )}
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("jobDescription")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol
                              sm="12"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <div>
                                <MDBCardText style={{ fontWeight: "bold" }}>
                                  Job Description:
                                </MDBCardText>
                              </div>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.6rem",
                                    color: "black",
                                  }}
                                >
                                  arrow_drop_down
                                </span>
                              </div>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingJobDescription ? (
                                  <div>
                                    <div>
                                      <textarea
                                        value={editedJobDescription}
                                        onChange={(e) =>
                                          setEditedJobDescription(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "jobdescription",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("jobdescription")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "jobDescription"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.jobDescription}

                                      {!isEditingJobDescription &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick("jobdescription")
                                            }
                                          />
                                        )}
                                    </p>
                                  </div>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("primarySkills")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol
                              sm="12"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <div>
                                <MDBCardText style={{ fontWeight: "bold" }}>
                                  Primary Skills:
                                </MDBCardText>
                              </div>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.6rem",
                                    color: "black",
                                  }}
                                >
                                  arrow_drop_down
                                </span>
                              </div>
                            </MDBCol>

                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingPrimarySkills ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedPrimarySkills}
                                        onChange={(e) =>
                                          setEditedPrimarySkills(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "primaryskills",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("primaryskills")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "primarySkills"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.primarySkills}
                                      {!isEditingPrimarySkills &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick("primaryskills")
                                            }
                                          />
                                        )}
                                    </p>
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("dualproficiency")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol
                              sm="12"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <MDBCardText style={{ fontWeight: "bold" }}>
                                Good to Have Skills :
                              </MDBCardText>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.6rem",
                                    color: "black",
                                  }}
                                >
                                  arrow_drop_down
                                </span>
                              </div>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingDualProficiency ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedDualProficiency}
                                        onChange={(e) =>
                                          setEditedDualProficiency(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "dualproficiency",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("dualproficiency")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "dualproficiency"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.dualProficiency}
                                      {!isEditingDualProficiency &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick("dualproficiency")
                                            }
                                          />
                                        )}
                                    </p>
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("location")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol
                              sm="12"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <MDBCardText style={{ fontWeight: "bold" }}>
                                Location :
                              </MDBCardText>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.6rem",
                                    color: "black",
                                  }}
                                >
                                  arrow_drop_down
                                </span>
                              </div>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingLocation ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedLocation}
                                        onChange={(e) =>
                                          setEditedLocation(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick("location", career.id)
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("location")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "location"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.location}
                                      {!isEditingLocation &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick("location")
                                            }
                                          />
                                        )}
                                    </p>
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("qualifications")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol sm="12">
                              <MDBCol
                                sm="12"
                                className="d-flex align-items-center justify-content-between"
                              >
                                <MDBCardText style={{ fontWeight: "bold" }}>
                                  Qualifications :
                                </MDBCardText>
                                <div>
                                  <span
                                    class="material-symbols-outlined"
                                    style={{
                                      fontSize: "1.6rem",
                                      color: "black",
                                    }}
                                  >
                                    arrow_drop_down
                                  </span>
                                </div>
                              </MDBCol>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingQualifications ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedQualifications}
                                        onChange={(e) =>
                                          setEditedQualifications(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "qualifications",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("qualifications")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "qualifications"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.qualifications}
                                      {!isEditingQualifications &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick("qualifications")
                                            }
                                          />
                                        )}
                                    </p>
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("responsibilities")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol sm="12">
                              <MDBCol
                                sm="12"
                                className="d-flex align-items-center justify-content-between"
                              >
                                <MDBCardText style={{ fontWeight: "bold" }}>
                                  Responsibilities :
                                </MDBCardText>
                                <div>
                                  <span
                                    class="material-symbols-outlined"
                                    style={{
                                      fontSize: "1.6rem",
                                      color: "black",
                                    }}
                                  >
                                    arrow_drop_down
                                  </span>
                                </div>
                              </MDBCol>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText
                                style={{ fontWeight: "bold" }}
                              ></MDBCardText>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingResponsibilities ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedResponsibilities}
                                        onChange={(e) =>
                                          setEditedResponsibilities(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "responsibilities",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("responsibilities")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection === "responsibilities"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {career && career.responsibilities}
                                      {!isEditingResponsibilities &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick(
                                                "responsibilities"
                                              )
                                            }
                                          />
                                        )}
                                    </p>
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow>
                            <MDBCol sm="12">
                              <MDBCardText style={{ fontWeight: "bold" }}>
                                Contact Information :
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingContactInformation ? (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        value={editedContactInformation}
                                        onChange={(e) =>
                                          setEditedContactInformation(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "contactinformation",
                                            career.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick(
                                            "contactinformation"
                                          )
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {career && career.contactInformation}
                                    {!isEditingContactInformation &&
                                      user &&
                                      user.role == "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--pink)",
                                          }}
                                          onClick={() =>
                                            handleEditClick(
                                              "contactinformation"
                                            )
                                          }
                                        />
                                      )}
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          {user && user.role == "admin" && (
                            <>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="3">
                                  <MDBCardText style={{ fontWeight: "bold" }}>
                                    Status:{" "}
                                  </MDBCardText>
                                </MDBCol>
                                <MDBCol sm="12">
                                  <MDBCardText className="text-muted">
                                    <div className="d-flex">
                                      <div>
                                        <p style={{ marginTop: "2px" }}>
                                          Inactive
                                        </p>
                                      </div>
                                      <ReactSwitch
                                        checked={
                                          career && career.status === "active"
                                        }
                                        onChange={() =>
                                          handleToggleChange(
                                            career && career.id,
                                            career && career.status
                                          )
                                        }
                                        onColor="#149AA2"
                                        offColor="#F05B83"
                                        className="products-status-switch"
                                      />
                                      <div>
                                        <p style={{ marginTop: "2px" }}>
                                          Active
                                        </p>
                                      </div>
                                    </div>
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                            </>
                          )}
                        </MDBCardBody>
                      </MDBCard>
                      <div className="d-flex align-items-center namesd">
                        <MDBCol className="d-flex align-items-center">
                          <MDBCardText style={{ cursor: "pointer" }}>
                            <span
                              className="material-symbols-outlined"
                              onClick={shareURL}
                              style={{ fontSize: "23px", padding: "6px 0px" }}
                            >
                              share
                            </span>
                          </MDBCardText>

                          {user && user.role == "admin" && (
                            <MDBCardText
                              onClick={() => handleDeleteCareerDoc(career.id)}
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                style={{
                                  fontSize: "23px",
                                  padding: "6px 0px",
                                }}
                              >
                                delete
                              </span>
                            </MDBCardText>
                          )}

                          <FaHeart
                            style={{
                              fontSize: 20,
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: isHeartClicked[career && career.id]
                                ? "red"
                                : "grey",
                            }}
                            onClick={() =>
                              toggleHeartStatus(career && career.id)
                            }
                          />
                        </MDBCol>

                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/contact">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Apply
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br /> <br />
            </div>
          ) : null
        )}
    </div>
  );
};

export default Career;

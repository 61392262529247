import React, { useState, useEffect } from 'react';
import './DiwaliTheme.scss';

import { useDocument } from '../firebase files/hooks/useDocument';

const DiwaliTheme = () => {
    const [isDeepakClicked, setIsDeepakClicked] = useState(false);
    const { document: dbSettingsDocuments, error: dbSettingsError } = useDocument("settings", "organisation");

    // Effect to reset the state after 8 seconds when the component mounts
    useEffect(() => {
        setIsDeepakClicked(true);

        const timer = setTimeout(() => {
            setIsDeepakClicked(false);
        }, 8000);

        return () => clearTimeout(timer);
    }, []);

    const handleDeepakClick = () => {
        setIsDeepakClicked(prevState => !prevState);
    };

    // Effect to play firecracker sound in a loop when dark overlay is active
    useEffect(() => {
        let audio;
        if (isDeepakClicked) {
            // Play firecracker sound and loop it
            audio = new Audio("assets/img/fire-crackers.mpeg");
            audio.loop = true; // Enable looping
            audio.play().catch((error) => console.error("Audio playback error:", error));
        }

        return () => {
            if (audio) {
                audio.pause(); // Stop the audio when overlay is not active
                audio.currentTime = 0; // Reset to the start
            }
        };
    }, [isDeepakClicked]);

    return (
        <div className={`diwali-theme ${isDeepakClicked ? 'deepak-active' : ''}`}>
            <img 
                src="/assets/img/themes/diwali-lamp.gif"
                className='diwali-theme-lantern-gif-left' 
            />
            <img 
                src="/assets/img/themes/diwali-lamp.gif"
                className='diwali-theme-lantern-gif-right' 
            />
            
            {isDeepakClicked && (
                <>
                    <h1 className='diwali-theme-h1-wish'>{dbSettingsDocuments && dbSettingsDocuments.short_name} wishes you a Sparkling Diwali!</h1>
                    <div className='d-flex diwali-theme-fire-crackers-gif'>
                        <img src='/assets/img/themes/green-fire-diwali.gif' alt='Diwali Cracker' />
                        <img src='/assets/img/themes/pink-fire-diwali.gif' alt='Diwali Cracker' />
                        <img src='/assets/img/themes/yellow-fire-diwali.gif' alt='Diwali Cracker' />
                    </div>
                </>
            )}

            <div>
                <img
                    src='/assets/img/themes/deepak-diwali.gif'
                    className='diwali-theme-deepak-gif'
                    onClick={handleDeepakClick}
                    alt="Deepak"
                />
            </div>

            {isDeepakClicked && (
                <div className="diwali-theme-dark-overlay"></div>
            )}
        </div>
    );
};

export default DiwaliTheme;

import { React, useState } from "react";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import Popup from "./Popup";
import Accordion from "react-bootstrap/Accordion";

// style
import "./Faq.css";
import AddMedia from "./AddMedia";

const Faq = ({
  category,
  pageName,
  section,
  addMoreImagesButton,
  addMoreVideosButton,
}) => {
  const { user } = useAuthContext();
  const { documents: dbTextContentDocuments, error: dbTextContentError } =
    useCollection("media", ["category", "==", "faq"]);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const { updateDocument, deleteDocument } = useFirestore("media");

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productIdToDelete);
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };
  const textContentDocuments =
    dbTextContentDocuments &&
    dbTextContentDocuments.filter(
      (item) => item.mediaType.toUpperCase() === "TEXT"
    );

  // Sort the documents based on the order field
  const sortedDocuments = dbTextContentDocuments
    ? dbTextContentDocuments.sort((a, b) => a.order - b.order)
    : [];

  // START CODE FOR DRAG FAQ
  const [isDragging, setIsDragging] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = dbTextContentDocuments[draggedIndex];
        const droppedService = dbTextContentDocuments[droppedIndex];
        const updatedServices = [...dbTextContentDocuments];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG FAQ
  return (
    <div>
      <div className="top_header_pg pa_bg">
        <div className="pa_inner_page info_in_ul_li max-991">
          <ul>
            <Popup
              showPopupFlag={isDeletePopupOpen}
              setShowPopupFlag={setIsDeletePopupOpen}
              msg="Are you sure you want to delete this Faq?"
              setPopupReturn={(confirmed) => {
                if (confirmed) {
                  handleConfirmDeleteProduct();
                }
              }}
            />

            <Accordion
              defaultActiveKey="0"
              style={{
                margin: "20px 0",
                display:"flex",
                flexDirection:"column",
                gap:"20px",
                maxWidth:"80vw"
              }}
            >
              {textContentDocuments &&
                textContentDocuments.map((textContent, index) => (
                  <div
                    className={`doctor-item ss ${
                      draggedIndex === index ? "dragging" : ""
                    }`}
                    draggable={user && user.role === "admin" && true}
                    onDragStart={() => handleDragStart(index)}
                    onDrag={(e) => handleDrag(e)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={() => handleDrop(index)}
                  >
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>
                        <div className="d-flex">
                          {user && user.role === "admin" && (
                            <>
                              <div
                                onClick={() =>
                                  handleDeleteProduct(textContent.id)
                                }
                              >
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    fontSize: "23px",
                                  }}
                                >
                                  delete
                                </span>
                              </div>
                              <div className="drag-button-container-only-faq">
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    color: "var(--click-color)",
                                    fontSize: "25px",
                                    cursor: "grab",
                                  }}
                                  title=" "
                                >
                                  apps
                                </span>
                                <span className="tooltip">
                                  Drag and swap faq position
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        {textContent.heading}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div style={{ textAlign: "left" }}>
                          {textContent.longDescription}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
            </Accordion>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddMedia
                category={category}
                section={section}
                pageName={pageName}
                addMoreVideosButton={addMoreVideosButton}
                addMoreImagesButton={addMoreImagesButton}
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faq;

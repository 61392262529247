import React from 'react'
import { Link } from 'react-router-dom'

// import component 
// import CurveTop from '../curveDivider/CurveTop'
// import BottomCurve from '../curveDivider/BottomCurve'

// import css 
import './CorporateOneFooter.scss'

const CorporateOneFooter = () => {
  return (
    <div className='footer'>
    <div className="footer_top">
        <div className="container">
            <div className="ft_inner"
                style={{
                    backgroundImage: "url('/assets/img/corporate/corporateone/home/footer_top_bg.png')",
                }}>
                <div className="row align-items-center">
                    <div className="col-6">
                        <h3 className="text-white semibold df36">
                            1500+ Businesses Who Sell Smarter With Happy ERP
                        </h3>
                    </div>
                    <div className="col-6 text-end">
                        <div className="d-flex align-items-center justify-content-end" style={{
                            gap: "5px"
                        }}>
                            <div className="theme_btn with_icon btn_white">
                                Sign Up
                                <span class="material-symbols-outlined btn_icon">
                                    arrow_forward
                                </span>
                            </div>
                            <div className="theme_btn with_icon btn_white_border">
                                See All Features
                                <span class="material-symbols-outlined btn_icon">
                                    arrow_forward
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div className="footer_inner relative">
        {/* <CurveTop /> */}
        <div className="container">
            <div className="row">
                <div className="col-3">
                    <div className="footer_links">
                        <h6 className="df20 semibold text-white">
                            Useful Links
                        </h6>
                        <div className="fl_inner">
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                        </div>

                    </div>
                </div>
                <div className="col-3">
                    <div className="footer_links">
                        <h6 className="df20 semibold text-white">
                            Quick Links
                        </h6>
                        <div className="fl_inner">
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                            <Link className="fl_single" to="">
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                                Lorem Ipsum
                            </Link>
                        </div>

                    </div>
                </div>
                <div className="col-6">
                    <div className='footer_links'>
                        <h6 className="df20 semibold text-white">
                            Social Media
                        </h6>
                    </div>

                    <div className="footer_social_media">

                        <Link to="">
                            <img src="/assets/img/icons/footer_google.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_linkedin.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_youtube.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_whatsapp.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_snapchat.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_twitter.png" alt="" />
                        </Link>
                        <Link to="">
                            <img src="/assets/img/icons/footer_skype.png" alt="" />
                        </Link>





                    </div>
                </div>
            </div>
        </div>
    </div>
 
</div>
  )
}

export default CorporateOneFooter

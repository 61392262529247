import React from 'react'
import './CorporateTwoPolicy.scss'
import CorporateTwoPolicyHeader from '../corporateTwoComponents/CorporateTwoPolicyHeader/CorporateTwoPolicyHeader';
import CorporateTwoPolicyBody from '../corporateTwoComponents/CorporateTwoPolicyBody/CorporateTwoPolicyBody';


const CorporateTwoPolicy = () => {
    return (
        <div>
         <CorporateTwoPolicyHeader/>
         <CorporateTwoPolicyBody/>
        </div>
    )
}

export default CorporateTwoPolicy;
import React, { useEffect, useRef } from 'react';
import './EducationOneContact.scss';

const EducationOneContact = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight && sectionPosition > 0) {
        section.classList.add('animate');
      } else {
        section.classList.remove('animate'); // Optional: Remove class if you want to re-animate
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Initial check to apply animation on page load
    handleScroll();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="EducationOneContact-section" ref={sectionRef}>
      <div className="container">
        <div className="EducationOneContact-inner">
          <div className="EducationOneContact-section_header">
            <div className="EducationOneContact-right">
              <div className="image-container">
                <div className="main-image">
                  <img
                    src="https://eduvibe.devsvibe.com/main/wp-content/uploads/2022/10/about-image-01-2.png"
                    alt="Main"
                  />
                </div>
                <div className="overlay-image">
                  <img
                    src="https://eduvibe.devsvibe.com/main/wp-content/uploads/2022/10/about-image-02-2.png"
                    alt="Overlay"
                  />
                </div>
              </div>
            </div>

            <div className="EducationOneContact-left">
              <div className="text-content">
                <h2>ABOUT US</h2>
                <h1>Knowledge is power, Information is liberating.</h1>
                <h2>our mission/vission</h2>
                <p>
                  Our mission is to list the Best Tutors, Tuition Centers and Kids Events and Classes. Tutortot is an Education City.
                </p>
                <div className="statistics">
                  <div className="stat-item">
                    <h3>90%</h3>
                    <p>90% of students see their course through to completion.</p>
                  </div>
                  <div className="stat-item">
                    <h3>9/10</h3>
                    <p>9/10 users reported better learning outcomes.</p>
                  </div>
                </div>
                <button className="learn-more-btn">Learn More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationOneContact;

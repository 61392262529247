import React from 'react'
import PhoneInput from 'react-phone-input-2';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

// import css 
import './CorporateOneBannerLogin.scss'

const CorporateOneBannerLogin = () => {
  return (
    <div className='login_form'>
    <div className="container">
        <form action="">
            <div className="mobile_number">
                <h6 className="df20 medium text-center mb-3 text_white">
                    Login
                </h6>

                <div className="mobile_number_inner">
                    {/* <div className="form_field">
                        <PhoneInput
                            country={'in'}
                            value={''} // Provide initial value if needed
                            onChange={(phone) => console.log(phone)} // Handle phone number change
                        />
                    </div> */}
                    <div className="form_field">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className=""
                        >
                            <Form.Control type="email" placeholder="name@example.com" />
                        </FloatingLabel>
                    </div>
                    <div className="form_field" style={{
                        marginLeft:"1px"
                    }}>

                        <FloatingLabel controlId="floatingPassword" label="Password">
                            <Form.Control type="password" placeholder="Password" />
                        </FloatingLabel>
                    </div>

                    <button className="theme_btn with_icon">
                      Login
                        <span class="material-symbols-outlined btn_icon">
                            arrow_forward
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
  )
}

export default CorporateOneBannerLogin

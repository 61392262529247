import React, { useState, useEffect } from 'react';

const useLocalStorage = (key) => {
    const [data, setData] = useState(() => {
        const storedData = localStorage.getItem(key);
        return storedData ? JSON.parse(storedData) : [];
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(data));
    }, [key, data]);

    const addItem = (item) => {
        setData((prevData) => [...(prevData || []), item]);
    };

    const updateItem = (index, newItem) => {
        setData((prevData) => {
            const updatedData = [...(prevData || [])];
            updatedData[index] = newItem;
            return updatedData;
        });
    };

    const deleteItem = (index) => {
        setData((prevData) => {
            const updatedData = [...(prevData || [])];
            updatedData.splice(index, 1);
            return updatedData;
        });
    };

    return { data, addItem, updateItem, deleteItem };
};

export default useLocalStorage





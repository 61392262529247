import { BrowserRouter } from "react-router-dom";
import { useAuthContext } from "./firebase files/hooks/useAuthContext";
import { useEffect, useState } from "react";
// components
import ColorsComponent from "./default components/ColorsComponent";
import PGLoginPhone from "./firebase files/login/PGLoginPhone";

//LAUNCHING PAGE
import LaunchingPage from "./default pages/launchingPage/LaunchingPage";

// HELMET
import { Helmet } from "react-helmet";

// styles
import "./App.css";
import { useDocument } from "./firebase files/hooks/useDocument";
import FloatingSpeedDial from "./default components/FloatingSpeedDial";

// Routes of all template start
import RouteOne from "./RouteOne";
import BusinessOneRoute from "./templates/business/businessOne/businessOneRoute/BusinessOneRoute";
import BusinessTwoRoute from "./templates/business/businessTwo/businessTwoRoute/BusinessTwoRoute";
import CorporateOneRoute from "./templates/corporate/corporateOne/corporateOneRoute/CorporateOneRoute";
import BusinessThreeRoute from "./templates/business/businessThree/businessThreeRoute/BusinessThreeRoute";
import CorporateTwoRoute from "./templates/corporate/corporateTwo/corporateTwoRoute/CorporateTwoRoute";
import EventOneRoute from "./templates/events/eventOne/eventOneRoute/EventOneRoute";
import EducationOneRoute from "./templates/education/educationOne/educationOneRoute/EducationOneRoute";
import EducationTwoRoute from "./templates/education/educationTwo/educationTwoRoute/EducationTwoRoute";
// Routes of all template end

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DiwaliTheme from "./default components/DiwaliTheme";
import HoliTheme from "./default components/HoliTheme";


function App() {
  const { user } = useAuthContext();

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: organisationDocument, errors: organisationDocumentError } =
    useDocument("settings", "organisation");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
    useDocument("settings", "themePalette");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <div className={currentModeStatus === "dark" ? "dark" : "light"}>
      {/* START HELMET CODE */}
      <Helmet>
        <title>{organisationDocument && organisationDocument.app_title}</title>
        <meta
          property="og:title"
          content={organisationDocument && organisationDocument.app_title}
        />
        <link
          rel="icon"
          href={organisationDocument && organisationDocument.FavIcon}
        />
        <meta
          name="description"
          content={organisationDocument && organisationDocument.seo_description}
        />
        <meta
          property="og:description"
          content={organisationDocument && organisationDocument.seo_description}
        />
        <meta
          name="keywords"
          content={organisationDocument && organisationDocument.seo_keywords}
        />
      </Helmet>
      {/* END HELMET CODE */}

      {
        dbTemplatesPaletteDocuments &&
        (dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette" ? (
          null
        ) : dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette" ? (
          // Diwali Theme will cover the entire body
          <DiwaliTheme />
        ) : dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette" ? (
          // Holi Theme will cover the entire body
          <HoliTheme />
        ) : null)
      }

      <div className="App">
        <BrowserRouter>
          {loginPopupDocuments &&
            loginPopupDocuments &&
            loginPopupDocuments.LoginPopop == true &&
            !user && (
              <div className="home-popup">
                <div className="login-popup-overlay"></div>
                <div className="popup-content">
                  <PGLoginPhone />
                </div>
              </div>
            )}

          <div>
            <div>
              {/* PWA INSTALLATION CODE START */}
              {dbTextContentDocuments &&
                dbTextContentDocuments.status == "active" ? (
                <>
                  {deferredPrompt && (
                    <div
                      className={
                        installPopupDiv
                          ? "install-popup-div open"
                          : "install-popup-div"
                      }
                    >
                      <div>
                        <span
                          onClick={closeInstallPopup}
                          className="material-symbols-outlined close-button"
                        >
                          close
                        </span>
                        <img src="/assets/img/hc-logo.png" alt=""></img>
                        <h1>
                          For swift and efficient access, consider installing
                          the app on your device.
                        </h1>
                        <button id="btn_install" onClick={startChromeInstall}>
                          Install App
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {/* PWA INSTALLATION CODE END */}

              {!user &&
                dbLaunchingSoonDocuments &&
                dbLaunchingSoonDocuments.status === true ? (
                <>
                  <LaunchingPage />
                  <FloatingSpeedDial />
                  <ColorsComponent />
                </>
              ) : user &&
                user.role !== "admin" &&
                dbLaunchingSoonDocuments &&
                dbLaunchingSoonDocuments.status === true ? (
                <>
                  <LaunchingPage />
                  <FloatingSpeedDial />
                  <ColorsComponent />
                </>
              ) : (
                <>
                  {activeTemplate === "defaultTemplate" ? (
                    <RouteOne />
                  ) : activeTemplate === "firstTemplate" ? (
                    <BusinessTwoRoute />
                  ) : activeTemplate === "secondTemplate" ? (
                    <BusinessOneRoute />
                  ) : activeTemplate === "thirdTemplate" ? (
                    <CorporateOneRoute />
                  ) : activeTemplate === "fourthTemplate" ? (
                    <BusinessThreeRoute />
                  ) : activeTemplate === "fifthTemplate" ? (
                    <CorporateTwoRoute />
                  ) : activeTemplate === "eventOne" ? (
                    <EventOneRoute />
                  ) : activeTemplate === "educationOne" ? (
                    <EducationOneRoute />
                  ) : activeTemplate === "educationTwo" ? (
                    <EducationTwoRoute />
                  ) : <div>
                    <Skeleton width="100%" height={60} />
                    <h3><Skeleton count={1} height={400}></Skeleton> </h3>
                    <br />
                    <Skeleton width="100%" height={50} />
                    <br />
                    <Skeleton width="100%" height={50} />
                    <br />
                    <Skeleton width="100%" height={50} />
                  </div>}
                </>
              )}
            </div>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;

import React from 'react'

// import css 
import './CorporateOneCurveDividerBottom.scss'

const CorporateOneCurveDividerBottom = () => {
  return (
    <div className='curve_divider curve_bottom'>
    <img src="/assets/img/corporate/corporateone/home/curve_bottom.png" alt="" />
  </div>
  )
}

export default CorporateOneCurveDividerBottom

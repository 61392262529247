import React from 'react'
import './CorporateTwoTerms.scss'
import CorporateTwoTermsHeader from '../corporateTwoComponents/CorporateTwoTermsHeader/CorporateTwoTermsHeader';
import CorporateTwoTermsBody from '../corporateTwoComponents/CorporateTwoTermsBody/CorporateTwoTermsBody';


const CorporateTwoTerms = () => {
    return (
        <div>
          <CorporateTwoTermsHeader/>
          <CorporateTwoTermsBody/>
        </div>
    )
}

export default CorporateTwoTerms;
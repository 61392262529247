import React, { useState, useEffect } from 'react';
import './HoliTheme.scss';

import { useDocument } from '../firebase files/hooks/useDocument';

const HoliTheme = () => {
    const [isDeepakClicked, setIsDeepakClicked] = useState(false);
    const { document: dbSettingsDocuments, error: dbSettingsError } = useDocument("settings", "organisation");

    // Effect to reset the state after 8 seconds when the component mounts
    useEffect(() => {
        setIsDeepakClicked(true);

        const timer = setTimeout(() => {
            setIsDeepakClicked(false);
        }, 8000);

        return () => clearTimeout(timer);
    }, []);

    const handleDeepakClick = () => {
        setIsDeepakClicked(prevState => !prevState);
    };

    return (
        <div className={`holi-theme ${isDeepakClicked ? 'deepak-active' : ''}`}>
            <img 
                src="/assets/img/themes/holi-deco.webp"
                className='holi-theme-lantern-gif-left' 
            />
            <img 
                src="/assets/img/themes/holi-deco.webp"
                className='holi-theme-lantern-gif-right' 
            />
            
            {isDeepakClicked && (
                <>
                    <h1 className='holi-theme-h1-wish'>{dbSettingsDocuments && dbSettingsDocuments.short_name} Wishing you a Vibrant and Happy Holi!</h1>
                    <div className='d-flex holi-theme-fire-crackers-gif'>
                        <img src='/assets/img/themes/holi-colors.gif' alt="Holi" />
                        {/* <img src='https://img1.picmix.com/output/stamp/normal/0/5/3/4/534350_587dd.gif' alt='Diwali Cracker' />
                        <img src='https://static.wixstatic.com/media/e3f465_92205ca008f8490aad4ff655e1be2c1a~mv2.gif' alt='Diwali Cracker' /> */}
                    </div>
                </>
            )}

            <div>
                <img
                    src='/assets/img/Happy-Holi-unscreen.gif'
                    className='holi-theme-deepak-gif'
                    onClick={handleDeepakClick}
                    alt="Deepak"
                />
            </div>

            {isDeepakClicked && (
               <>
                <div className='holi-theme-dark-overlay-white'></div>
                <div className="holi-theme-dark-overlay"></div></>
            )}
        </div>
    );
};

export default HoliTheme;

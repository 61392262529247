import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./BusinessTwoTestimonials.scss";
import "aos/dist/aos.css";
import BusinessTwoAddTestimonials from "./BusinessTwoAddTestimonials";
import { useCollection } from "../../../../firebase files/hooks/useCollection";

const BusinessTwoTestimonials = () => {
  // Fetch testimonials
  const { documents: testimonials, error: testimonialsError } =
    useCollection("testimonials");

  const [showPopup, setShowPopup] = useState(false);

  const options = {
    items: 3,
    loop: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1, // Show only 1 item on small screens (phone view)
      },
      768: {
        items: 3, // Show 3 items on larger screens
      },
    },
  };
  return (
    <div className="ht-testimonials-home_two_testimonials_parent_div">
      <br />
      <div className="container">
        <div className="row">
          <div>
            <div class="wrapper">
              <svg>
                <text x="0%" y="50%" dy=".35em" text-anchor="start">
                  TESTIMONIALS
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <OwlCarousel {...options}>
          {testimonials &&
            testimonials.map((testimonial, index) => (
              <div
                className="ht-testimonials-div"
                key={index}
                data-aos="fade-top"
              >
                <div className="ht-testimonials-ht_testimonials_profile_div">
                  <img src={testimonial.imageUrl} alt="testimonials-img" />
                </div>
                <div className="ht-testimonials-ht-text-content-div d-flex flex-column align-items-center justify-content-center">
                  <h4>{testimonial.name}</h4>
                  <p>{testimonial.details}</p>
                  <div className="testimonial-footer d-flex align-items-center justify-content-between">
                    <div>{testimonial.location}</div>
                    <div>{testimonial.rating}</div>
                  </div>
                </div>
              </div>
            ))}
        </OwlCarousel>
      </div>
      <br />
      <div className="d-flex justify-content-center">
        <button className="btn_fill" onClick={() => setShowPopup(true)}>
          Share Feedback
        </button>
      </div>
      <br />

      {showPopup && (
        <BusinessTwoAddTestimonials
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
    </div>
  );
};

export default BusinessTwoTestimonials;

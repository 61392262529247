import React, { useState, useRef, useEffect } from "react";
import "./CorporateTwoFAQ.scss";
import { IoIosArrowDown } from "react-icons/io";
import { useCollection } from "../../../../../firebase files/hooks/useCollection";

const CorporateTwoFAQ = () => {
  const { documents: faqDocument, error: faqError } = useCollection(
    "media",
    ["category", "==", "faq"]
  );

  const [faq, setFaq] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const faqRef = useRef(null);
  const imageRef = useRef(null);

  const trialRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
            faqRef.current.classList.add("animate-faq");
          } else {
            // entry.target.classList.remove('in-view');
            // headingRef.current.classList.remove('animate-heading')
            // faqRef.current.classList.remove('animate-faq');
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const section = sectionRef.current;
    observer.observe(section);

    return () => {
      observer.unobserve(section);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("image-inview");
            imageRef.current.classList.add("animate-image");
          } else {
            entry.target.classList.remove("image-inview");
            // imageRef.current.classList.remove('animate-image');
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const trial = trialRef.current;
    observer.observe(trial);

    return () => {
      observer.unobserve(trial);
    };
  }, []);

  const faqHandler = (activeFaqId) => {
    console.log(activeFaqId)
    setActiveId(activeId === activeFaqId ? null : activeFaqId);
  };

  return (
    <div className="corporateTwo-home-faq" ref={sectionRef}>
      <div className="corporateTwo-home-faq-left" ref={trialRef}>
        <img
          src="./assets/img/corporate/corporatetwo/faqladybg.png"
          alt=""
          className="corporateTwo-home-faq-left-lady"
          ref={imageRef}
        />
      </div>

      <div className="corporateTwo-home-faq-right" ref={faqRef}>
        <div className="corporateTwo-home-faq-heading">
          <h1>Frequently asked questions</h1>
          <h2>Our FAQ's</h2>
        </div>
        <div className="corporateTwo-home-faq-container">
          {faqDocument && faqDocument.slice(0,5).map((faq, index) => {
            return (
              <div
                className="corporateTwo-home-faq-card"
                key={faq.id}
                onClick={() => faqHandler(faq.id)}
              >
                <div
                  className={`corporateTwo-home-faq-card-title ${
                    activeId === faq.id ? "active" : ""
                  }`}
                >
                  <h4>{faq.heading}</h4>
                  <span>
                    <IoIosArrowDown />
                  </span>
                </div>
                <div
                  className={`corporateTwo-home-faq-card-body ${
                    activeId === faq.id ? "visible" : "hidden"
                  }`}
                >
                  <h5>{faq.longDescription}</h5>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoFAQ;

import React from 'react'
import BusinessThreeProfile from '../businessThreeComponents/BusinessThreeProfile'

const PGBusinessThreeProfile = () => {
  return (
    <div>
      <BusinessThreeProfile/>
    </div>
  )
}

export default PGBusinessThreeProfile

import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

const QRScannerComponent = () => {
    const [scannedData, setScannedData] = useState(""); // State to hold scanned data
    const [scanSuccess, setScanSuccess] = useState(false); // State to track if the scan was successful
    const [facingMode, setFacingMode] = useState("environment"); // State to handle the camera facing mode

    const handleScan = (data) => {
        if (data) {
            setScannedData(data);
            setScanSuccess(true); // Set scan success state to true
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const toggleCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === "environment" ? "user" : "environment"
        );
    };

    return (
        <div>
            <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                facingMode={facingMode}
                style={{ width: "300px" }}
            />
            <button onClick={toggleCamera} style={{ marginTop: "10px" }}>
                Switch Camera
            </button>
            {scannedData && (
                <div style={{ marginTop: "20px" }}>
                    <h4>Scanned Data: {scannedData}</h4>
                    {scanSuccess && <h5>Scanned successfully</h5>}
                </div>
            )}
        </div>
    );
};

export default QRScannerComponent;

import React, { useState } from "react";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import Popup from "../../../../default components/Popup";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import AddMedia from "../../../../default components/AddMedia";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import "./BusinessTwoTeam.scss";
import ReactSwitch from "react-switch";
import { Link } from "react-router-dom";

const BusinessTwoTeam = ({
  category,
  pageName,
  section,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const { user } = useAuthContext();
  const isAdmin = user && user.role === "admin";

  const { documents: dbMediaDocuments } = useCollection("media", [
    "category",
    "==",
    "team",
  ]);

  const teamImagesDocuments =
    dbMediaDocuments &&
    dbMediaDocuments.filter((item) => item.mediaType.toUpperCase() === "IMAGE");
  if (teamImagesDocuments) {
    teamImagesDocuments.sort((a, b) => a.Sequence - b.Sequence);
  }

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      const documentToDelete = teamImagesDocuments.find(
        (doc) => doc.id === productIdToDelete
      );
      if (documentToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Offer deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div className="business-two-team-wrapper">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this offer?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div className="business-two-team-left">
        <div>
          <div>
            <div class="wrapper">
              <svg className="business-two-team-svg">
                <text x="0%" y="50%" dy=".35em" text-anchor="start">
                  OUR TEAM
                </text>
              </svg>
            </div>
          </div>
          <br />
          <img
            style={{ maxWidth: "100%" }}
            src="https://i.pinimg.com/originals/a4/6b/de/a46bde6bf40a0944a6696be1069040e4.gif"
          />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          {isAdmin && (
            <AddMedia
              category={category}
              pageName={pageName}
              section={section}
              addMoreVideosButton={addMoreVideosButton}
              addMoreTextContentButton={addMoreTextContentButton}
            />
          )}
          <Link to="/our-team">
            <button className="btn_fill">Meet Team</button>
          </Link>
        </div>
      </div>
      <div className="business-two-team-right">
        {teamImagesDocuments &&
          teamImagesDocuments
            .filter((media) =>
              isAdmin
                ? !section || media.section === section
                : media.status === "active" &&
                  (!section || media.section === section)
            )
            .slice(0, 4) // Only display the first four images
            .map((offerImage) => (
              <div key={offerImage.id} className="business-two-team-card">
                <img src={offerImage.mediaUrl} alt="Team member" />
                {isAdmin && (
                  <>
                    <div>
                      <MDBRow>
                        <MDBCol className="col-9">
                          <MDBCardText className="text-muted">
                            <div className="d-flex align-items-center mt-2">
                              <div>
                                <p
                                  className="active-inactive-text"
                                  style={{ color: "red" }}
                                >
                                  Inactive
                                </p>
                              </div>
                              <ReactSwitch
                                checked={offerImage.status == "active"}
                                onChange={() =>
                                  handleToggleChange(
                                    offerImage.id,
                                    offerImage.status
                                  )
                                }
                                onColor="#149AA2"
                                offColor="#F05B83"
                                className="products-status-switch"
                              />
                              <div>
                                <p
                                  className="active-inactive-text"
                                  style={{ color: "red" }}
                                >
                                  Active
                                </p>
                              </div>
                            </div>
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol className="col-3 d-flex justify-content-center">
                          <div>
                            <MDBCardText
                              onClick={() => handleDeleteProduct(offerImage.id)}
                            >
                              <span
                                className="material-symbols-outlined"
                                style={{
                                  fontSize: "23px",
                                }}
                              >
                                delete
                              </span>
                            </MDBCardText>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </>
                )}
              </div>
            ))}
      </div>
    </div>
  );
};

export default BusinessTwoTeam;

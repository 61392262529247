import React from "react";
import { useCollection } from "../firebase files/hooks/useCollection";
import "./NewArrivals.scss";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import OwlCarousel from "react-owl-carousel";
import "react-multi-carousel/lib/styles.css";

const NewArrivals = () => {
  const { documents: dbNewArrivalsDocuments, error: dbNewArrivalsError } =
    useCollection("products", ["newArrivals", "==", true]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      {dbNewArrivalsDocuments && dbNewArrivalsDocuments.length > 0 && (
        <>
          <div>
            {dbNewArrivalsError && (
              <p>Error loading new arrivals products: {dbNewArrivalsError}</p>
            )}
            <div className="top-selling-products-container">
              <div className="left-sidebar">
                <div className="rotated-text">New Arrival Products</div>
              </div>
              <div className="top-selling-products">
                {dbNewArrivalsDocuments && dbNewArrivalsDocuments.length > 0 ? (
                  <div className="owl-carousel-wrapper">
                    <OwlCarousel
                      className="owl-theme"
                      items={1}
                      loop
                      dots={false}
                      nav={false}
                      autoplay={true} // Enable autoplay
                      autoplayTimeout={8000}
                      smartSpeed={4000}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        650: {
                          items: 2,
                        },
                        1000: {
                          items: 3,
                        },
                        1300: {
                          items: 4,
                        },
                      }}
                    >
                      {dbNewArrivalsDocuments.map((product, index) => (
                        <Link to={`/product-details/${product.id}`} key={index}>
                          <div className="top-selling-product-item">
                            <img
                              src={product.images[0]}
                              alt={product.name}
                              className="top-selling-product-image"
                            />
                            <div className="top-selling-product-details">
                              <p
                                className="top-selling-product-name"
                                dangerouslySetInnerHTML={{
                                  __html: product.name.toString("html"),
                                }}
                              ></p>
                              <p className="top-selling-product-rating">
                                Rating: {product.rating}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  </div>
                ) : (
                  <p>No top-selling products available</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewArrivals;

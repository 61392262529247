import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import "./SidebarNew.css";
import DarkModeToggle from "react-dark-mode-toggle";
import { Link, useLocation, navigate } from "react-router-dom"; 

export default function SidebarNew({ activeMenu, setActiveMenu, navigate }) {
  const location = useLocation();
  const { user } = useAuthContext();

  const { document: organisationDoc } = useDocument("settings", "organisation");
  const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
    "settings",
    "navbarType"
  );
  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  // START CODE FOR LIGHT/DARK MODE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };

  // END CODE FOR LIGHT/DARK MODE

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    // Handle navigation based on menu clicked
    switch (menuName) {
      case "home":
        navigate("/");
        break;
      case "showSecondPage":
        navigate("/services");
        break;
      case "thirdMenu":
        if (appTypeDetails && appTypeDetails.apptype === "lite") {
          setActiveMenu("contact");
          if (user && user.role === "admin") {
            navigate("/adminuser");
          }
          if (user && user.role === "customer") {
            navigate("/contact");
          }
          if (!user) {
            navigate("/contact");
          }
        } else {
          setActiveMenu("dashboard");
          if (user && user.role === "admin") {
            navigate("/adminuser");
          }
          if (user && user.role === "customer") {
            navigate("/bookinglist");
          }
        }
        break;
      case "showFourthPage":
        navigate("/more");
        break;
      default:
        break;
    }
  };

  //Menus as per role
  let firstMenuIcon = "";
  let firstMenu = ""; //This is for all user type
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";
  let fifthMenu = "";
  let fifthMenuIcon = "";

  if (user) {
    firstMenuIcon = "home";
    firstMenu = "Home";
    fourthMenuIcon = "apps";
    fourthMenu = "More";
  }
  if (!user) {
    firstMenuIcon = "home";
    firstMenu = "Home";
    secondMenuIcon = "category";
    secondMenu = "Services";
    thirdMenuIcon = "perm_phone_msg";
    thirdMenu = "Contact";
    fourthMenuIcon = "apps";
    fourthMenu = "More";
  }

  if (appTypeDetails && appTypeDetails.apptype === "lite") {
    if (user && user.role === "customer") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "perm_phone_msg";
      thirdMenu = "Contact";
    }
    if (user && user.role === "admin") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "confirmation_number";
      thirdMenu = "Users";
    }
  } else {
    if (user && user.role === "customer") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "list_alt";
      thirdMenu = "Bookings";
    }
    if (user && user.role === "admin") {
      secondMenuIcon = "category";
      secondMenu = "Services";
      thirdMenuIcon = "confirmation_number";
      thirdMenu = "Users";
    }
  }
  const showThirdPage = () => {
    if (appTypeDetails && appTypeDetails.apptype === "lite") {
      setActiveMenu("contact");
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/contact");
      }
      if (!user) {
        navigate("/contact");
      }
    } else {
      setActiveMenu("dashboard");
      if (user && user.role === "admin") {
        navigate("/adminuser");
      }
      if (user && user.role === "customer") {
        navigate("/bookinglist");
      }
    }
  };

  return (
    <div>
      <div className="d-flex sidebar_nav_logo_only">
        <Button onClick={toggleDrawer(true)}>
          <span className="material-symbols-outlined">menu</span>
        </Button>
        <img
          src={organisationDoc && organisationDoc.shortLogo}
          className="logo-img"
          alt="logo"
        />
      </div>
      <Drawer
        anchor={
          navTypeDoc && navTypeDoc.activeNav === "BottomNav"
            ? "bottom"
            : navTypeDoc && navTypeDoc.activeNav === "TopNav"
            ? "top"
            : navTypeDoc && navTypeDoc.activeNav === "LeftNav"
            ? "left"
            : navTypeDoc && navTypeDoc.activeNav === "RightNav"
            ? "right"
            : ""
        }
        open={state.right}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ padding: "10px 10px" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {user ? (
              <Link
                to="/profile"
                className={`menu_single profile pointer ${
                  location.pathname === "/profile" ? "active" : ""
                }`}
                style={{ display: "flex", color: "var(--white-color)" }}
              >
                <span style={{ color: "white" }}>Hi, {user.fullName}</span>
                <div className="user_img">
                  {user.photoURL === "" ? (
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2&_gl=1*1bbo31y*_ga*MTEyODU2MDU1MS4xNjc3ODEwNzQy*_ga_CW55HF8NVT*MTY4NjIzODcwMC42OS4xLjE2ODYyMzkwMjIuMC4wLjA."
                      alt=""
                      className="nav_profile_icon"
                    />
                  ) : (
                    <img src={user && user.photoURL} alt="" />
                  )}
                </div>
              </Link>
            ) : (
              <Link to="/login">
                <div
                  className={`menu_single login pointer ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  <span className="material-symbols-outlined ba_animation">
                    login
                  </span>
                  Login
                </div>
              </Link>
            )}
            <ListItem
              button
              key="home"
              onClick={() => handleMenuClick("home")}
              className={activeMenu === "home" ? "active" : ""}
            >
              <ListItemIcon>
                <span className="material-symbols-outlined">
                  {firstMenuIcon}
                </span>{" "}
              </ListItemIcon>
              <ListItemText primary={firstMenu} />
            </ListItem>
            <ListItem
              button
              key="showSecondPage"
              onClick={() => handleMenuClick("showSecondPage")}
              className={activeMenu === "showSecondPage" ? "active" : ""}
            >
              <ListItemIcon>
                {" "}
                <span className="material-symbols-outlined">
                  {secondMenuIcon}
                </span>
              </ListItemIcon>
              <ListItemText primary={secondMenu} />
            </ListItem>
            <ListItem
              button
              key="thirdMenu"
              onClick={() => handleMenuClick("thirdMenu")}
              className={activeMenu === "contact" ? "active" : ""}
            >
              <ListItemIcon>
                <span className="material-symbols-outlined">
                  {thirdMenuIcon}
                </span>{" "}
              </ListItemIcon>
              <ListItemText primary={thirdMenu} />
            </ListItem>
            <ListItem
              button
              key="showFourthPage"
              onClick={() => handleMenuClick("showFourthPage")}
              className={activeMenu === "showFourthPage" ? "active" : ""}
            >
              <ListItemIcon>
                <span className="material-symbols-outlined">
                  {fourthMenuIcon}
                </span>{" "}
              </ListItemIcon>
              <ListItemText primary={fourthMenu} />
            </ListItem>
          </List>
          <Divider />
          <ListItem
            button
            key="showFourthPage"
            onClick={() => handleMenuClick("showFourthPage")}
            className={activeMenu === "showFourthPage" ? "active" : ""}
          >
            <ListItemIcon>
              <DarkModeToggle
                onChange={() => toggleUserDarkMode()}
                checked={localStorage.getItem("mode") === "dark"}
                size={40}
              />
            </ListItemIcon>
            <ListItemText primary="Display Mode" />
          </ListItem>
        </Box>
      </Drawer>
    </div>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'



// import css 
import './CorporateOneServices.scss'


const CorporateOneServices = () => {
  return (
    <div className='services relative'>
    <div className="container">
        <div className='services_card'>
            <div className="sc_single with_top_img">
                <div className="scs_inner border_right">
                    <div className="images">
                        <img src="/assets/img/corporate/corporateone/home/services1.png" alt="" />
                    </div>
                    <div className="content">
                        <h2>
                            CMS Hub
                        </h2>
                        <p>
                            Content management software that's flexible for marketers ect
                        </p>
                        <ul className="list">
                            <li>
                            Website Themes
                            </li>
                            <li>
                                SEO Recommendations
                            </li>
                            <li>
                                Drag-and-Drop Editor
                            </li>
                        </ul>
                          <Link className='theme_btn with_icon'>
                            Know More
                             <span class="material-symbols-outlined btn_icon">
                                arrow_forward
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="sc_single with_bottom_img">

                <div className="scs_inner border_right">
                    <div className="content">
                        <h2>
                            Free ERP
                        </h2>
                        <p>
                            Everything you need to organize track, and build better relationships
                        </p>
                        <ul className="list">
                            <li>
                                Contact Insights
                            </li>
                            <li>
                                Deals Over Business
                            </li>
                            <li>
                                Tasks Regarding Test
                            </li>
                        </ul>
                        <Link className='theme_btn with_icon'>
                            Know More
                             <span class="material-symbols-outlined btn_icon">
                                arrow_forward
                            </span>
                        </Link>
                    </div>
                    <div className="images">
                        <img src="/assets/img/corporate/corporateone/home/services2.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="sc_single with_top_img">
                <div className="scs_inner">
                    <div className="images">
                        <img src="/assets/img/corporate/corporateone/home/services3.png" alt="" />
                    </div>
                    <div className="content">
                        <h2>
                            Marketing Hub
                        </h2>
                        <p>
                            Marketing software to help you grow traffic convert more visitors
                        </p>
                        <ul className="list">
                            <li>
                                Marketing Automation
                            </li>
                            <li>
                                Lead Generation
                            </li>
                            <li>
                                Analytics
                            </li>
                        </ul>
                          <Link className='theme_btn with_icon'>
                            Know More
                             <span class="material-symbols-outlined btn_icon">
                                arrow_forward
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
  )
}

export default CorporateOneServices

import React, { useState, useEffect } from 'react';
import './AddCategories.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { projectStorage } from '../../firebase config/config';
import { useCollection } from '../../firebase files/hooks/useCollection';
import { projectFirestore } from '../../firebase config/config';
import { BeatLoader } from "react-spinners";
import ReactSwitch from "react-switch";
import { useFirestore } from '../../firebase files/hooks/useFirestore';

const AddCategories = () => {
  const [category, setCategory] = useState('');
  const [categoryImg, setCategoryImg] = useState(null);
  const [categoryImgPreview, setCategoryImgPreview] = useState(null);
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryImg, setSubCategoryImg] = useState(null);
  const [subCategoryImgPreview, setSubCategoryImgPreview] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track edit mode
  const [isLoading, setIsLoading] = useState(false);
  const [isAddSubCat, setIsAddSubCat] = useState(false);
  const [options, setOptions] = useState([]); // For autocomplete options
  const [newSubCategories, setNewSubCategories] = useState([]);
  const [ogSubCategoryName, setOGSubCategoryName] = useState('');
  const [editedCategory, setEditedCategory] = useState('');
  const [editedSubCategory, setEditedSubCategory] = useState('');
  const [editingCategoryId, setEditingCategoryId] = useState('');
  const [editingIndexNumber, setEditedIndexNumber] = useState('');
  const [editingSubCategoryId, setEditingSubCategoryId] = useState(null); // Add this line
  const [editingSubCategory, setEditingSubCategory] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]); // Store selected category IDs (multiple)
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [checked, setChecked] = useState();

  const { documents: allCategories, error: allCategoriesError } = useCollection('m_categories');
  const { updateDocument } = useFirestore("m_categories");


  useEffect(() => {
    if (allCategories && Array.isArray(allCategories)) {
      const categoryOptions = allCategories.map(cat => ({
        label: cat.category,
        id: cat.id,
      }));
      setOptions(categoryOptions);
    }
  }, [allCategories]);


  useEffect(() => {
    if (allCategories && Array.isArray(allCategories)) {
      // Assuming you want to set the first category by default
      if (isEditing && editingCategoryId) {
        const selectedCategory = allCategories.find(cat => cat.id === editingCategoryId);
        if (selectedCategory) {
          setEditedCategory(selectedCategory.category); // Set the editedCategory state with the correct category
          setCategory(selectedCategory.category); // Set the category input as well
          setCategoryImgPreview(selectedCategory.categoryImgUrl || ''); // Set category image preview
        }
      }
    }
  }, [allCategories, editingCategoryId, isEditing]);

  // Handle category dropdown selection (multiple selection support)
  const handleCategorySelect = (event, value) => {
    if (value && value.length > 0) {
      const selectedIds = value.map(cat => cat.id); // Extract the category IDs
      setSelectedCategoryIds(selectedIds); // Set the selected category IDs
    } else {
      setSelectedCategoryIds([]); // Reset if no category is selected
    }
  };

  // Filter categories based on selected category IDs
  const filteredCategories = selectedCategoryIds.length > 0
    ? allCategories.filter(cat => selectedCategoryIds.includes(cat.id))
    : allCategories;


  const handleAddCategory = async () => {
    if (!category || !subCategory || !categoryImg || !subCategoryImg) {
      alert("Please fill in all fields.");
      return;
    }
    setIsAddSubCat(true);
    setIsLoading(true); // Start loading
    try {
      // Step 1: Create a new document in Firestore with basic data
      const categoryDocRef = await projectFirestore.collection("m_categories").add({
        category: category,
        subCategories: [{ subCategoryName: subCategory, subCategoryImgUrl: "" }], // Placeholder for subcategory image URL
        status: "active",
      });

      const newDocId = categoryDocRef.id; // Get the document ID

      // Step 2: Create storage references for category and subcategory images
      const categoryImgRef = projectStorage.ref(`categories/${newDocId}/category/${categoryImg.name}`);
      const subCategoryImgRef = projectStorage.ref(`categories/${newDocId}/subcategory/${subCategoryImg.name}`);

      // Step 3: Upload category image
      const categoryUploadTask = categoryImgRef.put(categoryImg);
      categoryUploadTask.on(
        "state_changed",
        null, // Skip the progress function
        (error) => {
          console.error("Error uploading category image:", error);
        },
        async () => {
          // Step 4: Get the download URL for the category image
          const categoryImgUrl = await categoryUploadTask.snapshot.ref.getDownloadURL();

          // Step 5: Upload subcategory image
          const subCategoryUploadTask = subCategoryImgRef.put(subCategoryImg);
          subCategoryUploadTask.on(
            "state_changed",
            null, // Skip the progress function
            (error) => {
              console.error("Error uploading subcategory image:", error);
            },
            async () => {
              // Step 6: Get the download URL for the subcategory image
              const subCategoryImgUrl = await subCategoryUploadTask.snapshot.ref.getDownloadURL();

              // Step 7: Update Firestore with the image URLs
              await categoryDocRef.update({
                categoryImgUrl: categoryImgUrl,
                subCategories: [{ subCategoryName: subCategory, subCategoryImgUrl: subCategoryImgUrl, status: "active" }]
              });

              console.log('Category and subcategory added successfully!');

              // Step 8: Reset the form state
              setCategory('');
              setCategoryImg(null);
              setCategoryImgPreview(null);
              setSubCategory('');
              setSubCategoryImg(null);
              setSubCategoryImgPreview(null);
              setIsLoading(false); // Stop loading
            }
          );
        }
      );
    } catch (error) {
      console.error("Error adding category:", error);
      setIsLoading(false); // Stop loading
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      // Upload category image if changed
      let categoryImgUrl = categoryImgPreview; // Keep existing URL if no new image
      if (categoryImg) {
        const categoryImgRef = projectStorage.ref(`categories/${editingCategoryId}/category/${categoryImg.name}`);
        const categoryUploadTask = await categoryImgRef.put(categoryImg);
        categoryImgUrl = await categoryUploadTask.ref.getDownloadURL();
      }

      addupdateCategory('update');

      console.log("Category updated successfully!");
      setIsLoading(false);
      handleCancel(); // Reset form state after update
    } catch (error) {
      console.error("Error updating category:", error);
      setIsLoading(false);
    }
  };

  const handleCategoryImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategoryImg(file); // Store the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setCategoryImgPreview(reader.result); // Preview the image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubCategoryImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSubCategoryImg(file); // Store the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setSubCategoryImgPreview(reader.result); // Preview the image
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleAddCategory = () => {
    setIsEditing(false);
    setIsAddingCategory((prevState) => !prevState);
  };

  // Function to handle editing the subcategory
  const handleEditSubCategory = (categoryId, subCategoryName, subCategoryIndex) => {
    setIsAddSubCat(false);
    setIsAddingCategory(false);
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const categoryDoc = allCategories.find(cat => cat.id === categoryId);
    if (categoryDoc) {
      const subCategory = categoryDoc.subCategories[subCategoryIndex];
      setOGSubCategoryName(subCategoryName)
      setEditedIndexNumber(subCategoryIndex);
      setEditingCategoryId(categoryId);
      setEditedCategory(categoryDoc.category); // Set the category name from the found category document
      setEditedSubCategory(subCategory.subCategoryName); // Set the subcategory name
      setEditingSubCategoryId(subCategory.id); // Ensure this subcategory has a defined ID
      setSubCategoryImgPreview(subCategory.subCategoryImgUrl || ''); // Set the subcategory image preview
      setIsEditing(true); // Open the edit form
    }
  };

  // change status of Category
  const handleStatusCatToggleChange = (catId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status using updateDocument hook
    updateDocument(catId, { status: newStatus })
      .then(() => {
        setChecked(newStatus === "active");
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };

  // change status of subcategory
  const handleStatusToggleChange = async (categoryId, subCategoryToggleIndex) => {
    // console.log(categoryId, "and", subCatStatus, "and", subCatName, "and", subCategoryToggleIndex);
  
    // Find the category document by categoryId
    const categoryDoc = allCategories.find(cat => cat.id === categoryId);
  
    if (categoryDoc) {
      const updatedSubCategories = categoryDoc.subCategories.map((subCat, index) => {
        // Check if this is the subcategory being toggled
        if (index === subCategoryToggleIndex) {
          return {
            ...subCat,
            status: subCat.status === 'active' ? 'inactive' : 'active', // Toggle status
          };
        }
        return subCat; // Return unchanged subcategory if no match
      });
  
      // Update Firestore with the new status for the subcategories
      try {
        await projectFirestore.collection('m_categories').doc(categoryId).update({
          subCategories: updatedSubCategories, // Set updated subcategories
        });
        console.log("Subcategory status updated successfully!");
      } catch (error) {
        console.error("Error updating subcategory status:", error);
      }
    }
  };
  

  // add new sub cat in category
  const handleAddNewSubCat = async (categoryId, subCategoryName, index) => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setIsAddSubCat(true);
    setIsEditing(false);
    setNewSubCategories([{ name: '', imgPreview: '' }]); // Reset new subcategories array with a single entry

    setIsAddingCategory(false);
    setEditingCategoryId(categoryId); // Set category ID
    setEditingSubCategory(subCategoryName); // Set selected subcategory for editing

    // Find the category object based on the categoryId
    const selectedCategory = allCategories.find(cat => cat.id === categoryId);

    if (selectedCategory) {
      // Set the input fields with the selected category data
      setCategory(selectedCategory.category); // Set category name
      setCategoryImgPreview(selectedCategory.categoryImgUrl || ''); // Set category image preview

      // Optionally, if you want to use the selected subcategory
      if (selectedCategory.subCategories && selectedCategory.subCategories.length > 0) {
        const selectedSubCategory = selectedCategory.subCategories[index];
        if (selectedSubCategory) {
          setEditedSubCategory(selectedSubCategory.subCategoryName); // Set subcategory name
          setSubCategoryImgPreview(selectedSubCategory.subCategoryImgUrl || ''); // Set subcategory image preview
        }
      }
    }
  };

  // Add new sub cat in category
  const handleAddSubCatUpdate = async () => {
    setIsLoading(true);
    addupdateCategory('add');
  };

  const addupdateCategory = async (_iAddUpdateFlag) => {
    try {

      // Reference to the Firestore document for the specific category
      const categoryDocRef = projectFirestore.collection('m_categories').doc(editingCategoryId);
      console.log("addupdateCategory");

      // Fetch the existing subcategories from Firestore
      const categoryDoc = await categoryDocRef.get();
      const updatedSubCategories = categoryDoc.data().subCategories || []; // Ensure we have an array

      console.log("updatedSubCategories : ", updatedSubCategories);

      // Initialize an array with existing subcategories
      // const updatedSubCategories = [...existingSubCategories]; // Copy existing subcategories

      console.log("ogSubCategoryName: ", ogSubCategoryName)
      console.log("editedSubCategory : ", editedSubCategory);
      console.log("subCategoryImgPreview : ", subCategoryImgPreview);
      console.log("editingCategoryId : ", editingCategoryId);
      console.log("editingIndexNumber  : ", editingIndexNumber);

      if (_iAddUpdateFlag === 'update') {
        console.log("update run hua");

        // Find the index of the object with the matching name
        let index = updatedSubCategories.findIndex(item => item.subCategoryName === ogSubCategoryName);

        console.log("index  : ", index);

        // Remove 1 element at index 
        updatedSubCategories.splice(index, 1);

        updatedSubCategories.push({
          subCategoryName: editedSubCategory,
          subCategoryImgUrl: subCategoryImgPreview,
          // status:newStatus,
        });
      }

      if (_iAddUpdateFlag === 'add') {

        // Loop through each new subcategory, validate, upload the image, and update Firestore
        for (const [index, subCategory] of newSubCategories.entries()) {
          if (!subCategory.name || !subCategory.imgPreview) {
            alert('Subcategory name and image cannot be empty.');
            setIsLoading(false);
            return;
          }

          // Create a storage reference for the new subcategory image
          const subCategoryImgRef = projectStorage.ref(
            `categories/${editingCategoryId}/subcategory/${subCategory.name}_${index}`
          );

          // Upload the subcategory image as a base64 data URL
          const uploadTask = subCategoryImgRef.putString(subCategory.imgPreview, 'data_url');
          await uploadTask;

          // Get the download URL for the image after upload
          const subCategoryImgUrl = await subCategoryImgRef.getDownloadURL();

          console.log("before push updated sub cat : ", updatedSubCategories);


          // Add the new subcategory to the array
          updatedSubCategories.push({
            subCategoryName: subCategory.name,
            subCategoryImgUrl: subCategoryImgUrl,
            status: "active",
          });

          console.log("sub cat img ref", subCategoryImgRef);
          console.log("updated sub cat", updatedSubCategories);

        }
      }

      // Update Firestore with the new list of subcategories
      await categoryDocRef.update({
        subCategories: updatedSubCategories
      });

      console.log('Subcategories updated successfully!');

      // Reset form state after successfully adding subcategories
      setNewSubCategories([]);  // Clear new subcategories input
      setIsLoading(false);  // Reset loading state
      handleCancel();  // Close the form or reset
    } catch (error) {
      console.error('Error saving subcategories:', error);
      setIsLoading(false);  // Reset loading state on error
    }
  }

  // Handle subcategory changes (name or image)
  const handleNewSubCategoryChange = (index, field, value) => {
    const updatedSubCategories = [...newSubCategories];
    if (field === 'imgPreview') {
      const reader = new FileReader();
      reader.onloadend = () => {
        updatedSubCategories[index][field] = reader.result; // set image preview
        setNewSubCategories(updatedSubCategories);
      };
      reader.readAsDataURL(value); // convert file to base64 string
    } else {
      updatedSubCategories[index][field] = value; // update name
      setNewSubCategories(updatedSubCategories);
    }
  };

  // cancel button clicked
  const handleCancel = () => {
    setCategory('');
    setCategoryImg(null);
    setCategoryImgPreview(null);
    setSubCategory('');
    setSubCategoryImg(null);
    setSubCategoryImgPreview(null);
    setSubCategories([]);
    setIsAddSubCat(false);
    setIsEditing(false); // Disable edit mode
    setIsAddingCategory(false); // Hide the form
  };

  return (
    <div className="add-category-container">
      <div className="row d-flex align-items-center justify-content-between">
        <div className='col-12 col-md-4'>
          <div className='row'>
            <div className='col-5 col-md-12 d-flex align-items-center'>
              <h2 className="add-category-form-title">
                {isAddingCategory ? (isEditing ? "Edit Category" : "Add New Category") : "Manage Categories"}
              </h2>
            </div>
            <div className='col-7 col-md-12 d-flex align-items-center'>
              <div className='d-flex add-category-form-title-heading-btn'>
                { isAddSubCat || isEditing && !isLoading ? (
                  <button className="btn_border" onClick={handleCancel}>
                    Cancel
                  </button>
                ) : (
                  !isLoading && (
                    <button
                      className={isAddingCategory ? "btn_border" : "btn_fill"}
                      onClick={toggleAddCategory}
                    >
                      {isAddingCategory ? 'Cancel' : 'Add Category'}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-md-8'>
           {/* Dropdown for selecting categories */}
           <Autocomplete
          multiple
          options={allCategories || []}  // Ensure options is always an array
          getOptionLabel={(option) => option.category || ''}  // Provide fallback for undefined labels
          onChange={handleCategorySelect}
          renderInput={(params) => (
            <TextField {...params} label="Select Categories" variant="outlined" />
          )}
        />
        </div>
      </div>
      <br />
      {/* Add Category Start */}
      {isAddingCategory && (
        <>
          <hr />
          <br />
          <div className="add-category-card">
            <div className="row add-category-form-grid">
              {/* Category Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Add Category Details</h5>
                <br />
                <div className="d-flex align-items-center">
                  {/* Category Image Preview */}
                  {categoryImgPreview ? (
                    <img
                      src={categoryImgPreview}
                      alt="Selected Category"
                      className="add-category-preview-image"
                      onClick={() => document.getElementById('categoryImg').click()}
                    />
                  ) : (
                    <div
                      className="add-category-icon-container"
                      onClick={() => document.getElementById('categoryImg').click()}
                    >
                      <span className="material-symbols-outlined">
                        photo_camera_back
                      </span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="categoryImg"
                    onChange={handleCategoryImgChange}
                    accept="image/*"
                    style={{ display: 'none' }} // Hide the file input
                  />
                  <Box component="form">
                    <TextField
                      label="Category"
                      variant="outlined"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      placeholder="Enter category"
                      className="add-category-form-input"
                    />
                  </Box>
                </div>
              </div>

              {/* Subcategory Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Add Sub Sub Category</h5>
                <br />
                <div className="d-flex align-items-center">
                  {subCategoryImgPreview ? (
                    <img
                      src={subCategoryImgPreview}
                      alt="Selected Subcategory"
                      className="add-category-preview-image"
                      onClick={() => document.getElementById('subCategoryImg').click()}
                    />
                  ) : (
                    <div
                      className="add-category-icon-container"
                      onClick={() => document.getElementById('subCategoryImg').click()}
                    >
                      <span className="material-symbols-outlined">
                        photo_camera_back
                      </span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="subCategoryImg"
                    onChange={handleSubCategoryImgChange}
                    accept="image/*"
                    style={{ display: 'none' }} // Hide the file input
                  />
                  <Box component="form">
                    <TextField
                      label="Sub Category"
                      variant="outlined"
                      value={subCategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                      placeholder="Enter sub category"
                      className="add-category-form-input"
                    />
                  </Box>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end add-category-actions">
              <div>
                {!isLoading && (
                  <button className="btn_border" onClick={handleCancel}>
                    Cancel
                  </button>
                )}
              </div>
              &nbsp;
              {isLoading ? (
                <BeatLoader color={"var(--click-color)"} loading={isLoading} />
              ) : (
                <button className="btn_fill" onClick={handleAddCategory}>
                  Add Category
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {/* Add Category End */}

      {/* Edit Category Start */}
      {isEditing && (
        <>
          <hr />
          <br />
          <div className="add-category-card">
            <div className="row add-category-form-grid">
              {/* Category Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Edit Category</h5>
                <br />
                <div className="d-flex align-items-center">
                  {/* Category Image Preview */}
                  {categoryImgPreview ? (
                    <img
                      src={categoryImgPreview}
                      alt="Selected Category"
                      className="add-category-preview-image"
                      onClick={() => document.getElementById('categoryImg').click()}
                    />
                  ) : (
                    <div
                      className="add-category-icon-container"
                      onClick={() => document.getElementById('categoryImg').click()}
                    >
                      <span className="material-symbols-outlined">
                        photo_camera_back
                      </span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="categoryImg"
                    onChange={handleCategoryImgChange}
                    accept="image/*"
                    style={{ display: 'none' }} // Hide the file input
                  />
                  <Box component="form">
                    <TextField
                      label="Category"
                      variant="outlined"
                      value={editedCategory}
                      onChange={(e) => setEditedCategory(e.target.value)}
                      placeholder="Enter category"
                      className="add-category-form-input"
                    />
                  </Box>
                </div>
              </div>

              {/* Subcategory Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Edit Sub Category</h5>
                <br />
                <div className="d-flex align-items-center">
                  {subCategoryImgPreview ? (
                    <img
                      src={subCategoryImgPreview}
                      alt="Selected Subcategory"
                      className="add-category-preview-image"
                      onClick={() => document.getElementById('subCategoryImg').click()}
                    />
                  ) : (
                    <div
                      className="add-category-icon-container"
                      onClick={() => document.getElementById('subCategoryImg').click()}
                    >
                      <span className="material-symbols-outlined">
                        photo_camera_back
                      </span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="subCategoryImg"
                    onChange={handleSubCategoryImgChange}
                    accept="image/*"
                    style={{ display: 'none' }} // Hide the file input
                  />
                  <Box component="form">
                    <TextField
                      label="Sub Category"
                      variant="outlined"
                      value={editedSubCategory}
                      onChange={(e) => setEditedSubCategory(e.target.value)}
                      placeholder="Enter sub category"
                      className="add-category-form-input"
                    />
                  </Box>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end add-category-actions">
              <div>
                {!isLoading && (
                  <button className="btn_border" onClick={handleCancel}>
                    Cancel
                  </button>
                )}
              </div>
              &nbsp;
              {isLoading ? (
                <BeatLoader color={"var(--click-color)"} loading={isLoading} />
              ) : (
                <button className="btn_fill" onClick={handleUpdate}>
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {/* Edit Category End */}

      {/* Add new sub Category Start */}
      {isAddSubCat && (
        <>
          <hr />
          <br />
          <div className="add-category-card">
            <div className="row add-category-form-grid">
              {/* Category Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Existing Category</h5>
                <br />
                <div className="d-flex align-items-center">
                  {/* Category Image Preview */}
                  {categoryImgPreview ? (
                    <img
                      src={categoryImgPreview}
                      alt="Selected Category"
                      className="add-category-preview-image"
                      onClick={() => document.getElementById('categoryImg').click()}
                    />
                  ) : (
                    <div
                      className="add-category-icon-container"
                      onClick={() => document.getElementById('categoryImg').click()}
                    >
                      <span className="material-symbols-outlined">photo_camera_back</span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="categoryImg"
                    onChange={handleCategoryImgChange}
                    accept="image/*"
                    disabled
                    style={{ display: 'none' }}
                  />
                  <Box component="form">
                    <TextField
                      label="Category"
                      variant="outlined"
                      value={category} // Updated to use category state
                      onChange={(e) => setCategory(e.target.value)}
                      placeholder="Enter category"
                      disabled
                      className="add-category-form-input"
                    />
                  </Box>
                </div>
              </div>

              {/* Subcategory Row */}
              <div className="col-12 col-md-6 add-category-form-group">
                <br/>
                <h5>Add New Sub Category</h5>
                <br />
                <div className="d-flex flex-column">
                  {newSubCategories.map((newSubCategory, index) => (
                    <div key={index} className="add-category-form-group mb-3">
                      <div className="d-flex align-items-center">
                        {newSubCategory.imgPreview ? (
                          <img
                            src={newSubCategory.imgPreview}
                            alt="New Subcategory"
                            className="add-category-preview-image"
                            onClick={() => document.getElementById(`newSubCategoryImg-${index}`).click()}
                          />
                        ) : (
                          <div
                            className="add-category-icon-container"
                            onClick={() => document.getElementById(`newSubCategoryImg-${index}`).click()}
                          >
                            <span className="material-symbols-outlined">photo_camera_back</span>
                          </div>
                        )}
                        <input
                          type="file"
                          id={`newSubCategoryImg-${index}`}
                          onChange={(e) => handleNewSubCategoryChange(index, 'imgPreview', e.target.files[0])}
                          accept="image/*"
                          style={{ display: 'none' }}
                        />
                        <TextField
                          label="New Subcategory"
                          variant="outlined"
                          value={newSubCategory.name}
                          onChange={(e) => handleNewSubCategoryChange(index, 'name', e.target.value)}
                          placeholder="Enter subcategory name"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end add-category-actions">
              <div>
                {!isLoading && (
                  <button className="btn_border" onClick={handleCancel}>
                    Cancel
                  </button>
                )}
              </div>
              &nbsp;
              {isLoading ? (
                <BeatLoader color={"var(--click-color)"} loading={isLoading} />
              ) : (
                <button className="btn_fill" onClick={handleAddSubCatUpdate}>
                  Add Sub Category
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {/* Add new Sub Category End */}

      <br />
      <h4 style={{ fontWeight: "700" }}>
        Total Categories: <span style={{ color: "var(--logo-primary-color)" }}>{allCategories && allCategories.length}</span>
      </h4>
      <hr />
      <br />
      {/* Display filtered categories based on selected options or all categories if none selected */}
      <div className="add-category-categories-list">
        {filteredCategories && filteredCategories.length > 0 ? (
          filteredCategories.map((categoryDoc, index) => (
            <div key={index} className="add-category-category-card">
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className='d-flex align-items-center'>
                  <h4 style={{ fontWeight: "bold", color: "var(--black-color)", textTransform: "capitalize" }}>
                    {categoryDoc.category}
                  </h4>
                  <ReactSwitch
                    checked={categoryDoc.status === "active"}
                    onChange={() =>
                      handleStatusCatToggleChange(categoryDoc.id, categoryDoc.status)
                    }
                    onColor="#149AA2"
                    offColor="#F05B83"
                    className="products-status-switch"
                  />
                </div>

                <button
                  className="btn_fill"
                  onClick={() => handleAddNewSubCat(categoryDoc.id, categoryDoc.subCategoryName)}
                >
                  Add Sub Category
                </button>
              </div>

              <div className="add-category-sub-category-container">
                {categoryDoc.subCategories && categoryDoc.subCategories.length > 0 && (
                  categoryDoc.subCategories
                    .sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName)) // Sort subcategories alphabetically
                    .map((sub, subIndex) => (
                      <div className="d-flex align-items-center justify-content-between add-category-sub-category-item" key={subIndex}>
                        <div className='d-flex align-items-center'>
                        <h6 style={{ textTransform: "capitalize" }}>{sub.subCategoryName}</h6>
                        &nbsp;
                        <span onClick={() => handleEditSubCategory(categoryDoc.id, sub.subCategoryName, subIndex)}>
                          ✎
                        </span>

                        </div>
                        <div className="d-flex">
                          <p className="mr-2">Inactive</p>
                          <ReactSwitch
                            checked={sub.status === "active"}
                            onChange={() =>
                              handleStatusToggleChange(categoryDoc.id, subIndex)
                            }
                            onColor="#149AA2"
                            offColor="#F05B83"
                            className="products-status-switch"
                          />
                          <p className="ml-2">Active</p>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          ))
        ) : (
          <p style={{ color: 'var(--red-color)', textAlign: 'center' }}>No Category Available</p>
        )}
      </div>
    </div>
  );
};

export default AddCategories;

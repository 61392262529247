import React from "react";
import "./EventOneCard.scss";

const cards = () => {
  return (
    <div className="Cards_Events">
      <div className="container">
        <div className="Cards_inner">
          <div className="Cards_header_card">
            <div className="left_card">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/bookmark.png"
                alt=""
                className="icon"
              />
              <h2 className="title_card">browse events by genre</h2>
              <p className="card_para_card" >lorem efu geudw jhehuwe bjdjw dguwdhujw duwhduh ndbwjdj nwdbjw jwshuwhd jeiweik </p>
              <text className="card_text_card">fhegufhei djgf dgf hdg </text>
              <form>
                <input type="email" placeholder="kunikagera1508@gmail.com"/>
                <button type="submit">YES, I AM IN </button>
                </form>
            </div>
            <div className="right_card">
                <img
                src="./assets/img/ecommerce/ecomTwo/icons/bookmark.png"
                alt=""
                className="icon_card"
              />
              <h2 className="title_card">browse events by genre</h2>
              <p className="card_para_card" >lorem efu geugrguwdhujw duwhduh ndbwjdj nwdbjw jwshuwhd jeiweik </p>
              <text className="card_text_card">fhegufhei djgf dgf hdg </text>
              <img
              src="./assets/img/ecommerce/ecomTwo/icons/bookmark.png"
              alt=""/>
              <img
              src="./assets/img/ecommerce/ecomTwo/icons/bookmark.png"
              alt=""/>
              
              
            </div>
          </div>
        </div>
    </div>
</div>
  );
};
export default cards

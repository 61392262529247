import React from "react";

import "./EducationTwoWhyUs.scss";

const EducationTwoWhyUs = () => {
  return (
    <section className="et_whyus sect_padding">
      <div className="container">
        <div className="etw_top_left_img">
          <img src="./assets/img/educationtwo/why_us_left_animatin.png" />
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="etw_left">
              <div className="sect_header">
                <span className="sect_badge">WHY CHOOSE US</span>
                <h3 className="sect_title">
                  <div className="h3_inner">
                    Creating A Community Of Life Long{" "}
                    <span className="relative">
                      Learners.
                      <span class="title-shape">
                        <svg
                          width="150"
                          height="65"
                          viewBox="0 0 168 65"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="highligt_text"
                        >
                          <path
                            d="M73.3761 8.49147C78.4841 6.01353 82.5722 4.25154 88.8933 3.3035C94.2064 2.50664 99.6305 2.0701 104.981 1.94026C120.426 1.56549 135.132 4.90121 146.506 9.70405C158.628 14.8228 166.725 22.5638 166.074 31.6501C165.291 42.5779 151.346 51.7039 133.508 56.8189C110.253 63.4874 81.7065 63.8025 58.5605 60.8285C37.5033 58.123 11.6304 51.7165 3.58132 40.0216C-3.43085 29.8337 12.0728 18.1578 27.544 11.645C40.3656 6.24763 55.7082 2.98328 70.8043 4.08403C81.9391 4.89596 93.2164 6.87822 102.462 9.99561C112.874 13.5066 120.141 18.5932 127.862 23.6332"
                            stroke="var(--click-color)"
                            stroke-width="3"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </h3>
              </div>
              <p className="theme_description">
                We offer unparalleled expertise and innovative teaching methods.
                Experience personalized support and achieve outstanding results
                with us..
              </p>
              <div className="why_us_cards">
                <div className="wuc_single">
                  <div className="title">
                    <span class="material-symbols-outlined">check_circle</span>
                    World Class Teachers
                  </div>
                  <div className="description">
                    Choose us for world-class teaching that unlocks your full
                    potential with unmatched expertise and dedication.
                  </div>
                </div>
                <div className="wuc_single">
                  <div className="title">
                    <span class="material-symbols-outlined">check_circle</span>
                    24/7 Access
                  </div>
                  <div className="description">
                    School is tough. Getting a tutor is easy. Get a real tutor
                    anytime, anywhere in our online classroom.
                  </div>
                </div>
                <div className="wuc_single">
                  <div className="title">
                    <span class="material-symbols-outlined">check_circle</span>
                    Star Membership
                  </div>
                  <div className="description">
                    Tutortot provides free listing to Tutors and The Institutes.
                    It offers Star membership, which includes special
                    privileges:
                  </div>
                </div>
                <div className="wuc_single">
                  <div className="title">
                    <span class="material-symbols-outlined">check_circle</span>
                    Results
                  </div>
                  <div className="description">
                    Provide evidence of successful outcomes or testimonials.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="etw_right">
              <img
                src="./assets/img/educationtwo/why_right_img.jpg"
                alt=""
                className="main_img"
              />
              <div className="etw_right_top_img">
                <img src="./assets/img/educationtwo/why_us_top.png" />
              </div>
              <div className="etw_right_bottom_img">
                <img src="./assets/img/educationtwo/why_us_bottom.png" />
              </div>
              <div className="etw_top_right_img">
                <img src="./assets/img/educationtwo/why_us_top_right.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationTwoWhyUs;

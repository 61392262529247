import React from "react";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useCollection } from "../firebase files/hooks/useCollection";
import { projectStorage } from "../firebase config/config";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { projectFirestore } from "../firebase config/config";
import TextEditor from "./TextEditor";

import Popup from "./Popup";
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCardText,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { FaEdit, FaHeart } from "react-icons/fa";
import ReactSwitch from "react-switch";
import Gallery from "react-image-gallery";
import { useEffect, useState } from "react";
const Features = () => {
  // Start firebase code
  const { user } = useAuthContext();

  const { documents: featuresDoc, error: featuresDocError } = useCollection(
    "features",
    null,
    ["order"]
  );

  // Render only the first document if showOnlyOneOnHome is true
  //   const featuresDoc = showOnlyOneOnHome
  //     ? [careerDoc && careerDoc[0]]
  //     : careerDoc;

  const { updateDocument, deleteDocument } = useFirestore("features");

  // End Firebase code

  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [checked, setChecked] = useState(); // Toggle button state
  const [expandedSection, setExpandedSection] = useState(null);
  const [editedFeaturesTitle, setEditedFeaturesTitle] = useState("");
  const [isEditingFeaturesTitle, setIsEditingFeaturesTitle] = useState(false);
  const [editedFeaturesDescription, setEditedFeaturesDescription] =
    useState("");
  const [isEditingJobDescription, setIsEditingJobDescription] = useState(false);

  const [selectedImage, setSelectedImage] = useState([]); // State to hold the selected image file
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [featureIdToDelete, setFeatureToDelete] = useState();
  const [isHeartClicked, setIsHeartClicked] = useState({});

  useEffect(() => {
    // Fetch document data and set edited values
    if (featuresDoc && featuresDoc.length > 0) {
      const firstFeatureDoc = featuresDoc[0];
      setEditedFeaturesTitle((firstFeatureDoc && firstFeatureDoc.Title) || "");
      setEditedFeaturesDescription(
        (firstFeatureDoc && firstFeatureDoc.Description) || ""
      );
    }
  }, [featuresDoc]);

  // CODE FOR ADD IMAGE IN GALLERY
  const handleImageChange = (event) => {
    const files = event.target.files; // Get the selected files
    const selectedImagesArray = Array.from(files); // Convert FileList to array
    setSelectedImage(selectedImagesArray); // Set the selected files to state
  };
  // Function to handle confirming the selected image
  const handleConfirmImage = async (productId) => {
    try {
      setIsImageUploading(true); // Set isImageUploading to true when uploading starts

      // Retrieve current featuresImages array from Firestore
      const doc = await projectFirestore
        .collection("features")
        .doc(productId)
        .get();
      const currentImages = doc.data().featuresImages || []; // Get current images or initialize as empty array

      // Upload the selected images to Firebase Storage and get their download URLs
      const downloadURLs = await Promise.all(
        selectedImage.map(async (image) => {
          // Upload image to Firebase Storage
          const storageRef = projectStorage.ref(
            `features_img/${productId}/${image.name}`
          );
          await storageRef.put(image);
          // Get download URL of the uploaded image
          return storageRef.getDownloadURL();
        })
      );

      // Concatenate the new URLs with the current images
      const updatedImages = [...currentImages, ...downloadURLs];

      // Update your document in Firestore with the updated featuresImages array
      await projectFirestore
        .collection("features")
        .doc(productId)
        .update({ featuresImages: updatedImages });

      // Reset selected images state
      setSelectedImage([]);
      setIsImageUploading(false); // Set isImageUploading to false when uploading finishes
    } catch (error) {
      console.error("Error adding images:", error);
      setIsImageUploading(false); // Ensure isImageUploading is set to false in case of error
    }
  };
  //END CODE FOR ADD IMAGE IN GALLERY

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "featurestitle":
        setIsEditingFeaturesTitle(true);
        break;
      case "featuresdescription":
        setIsEditingJobDescription(true);
        break;
      default:
        break;
    }
  };

  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "featurestitle":
          setIsEditingFeaturesTitle(false);
          break;
        case "featuresdescription":
          setIsEditingJobDescription(false);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "featurestitle":
        setIsEditingFeaturesTitle(false);
        break;
      case "featuresdescription":
        setIsEditingJobDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  // START CODE FOR SHARE SERVICE
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };
  // END CODE FOR SHARE SERVICE

  // START CODE FOR CHANGE SERVICE STATUS
  const handleToggleChange = (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status in Firestore
    projectFirestore
      .collection("features")
      .doc(productId)
      .update({ status: newStatus })
      .then(() => {
        console.log("Product status updated successfully");
        setChecked(newStatus === "active"); // Update the toggle state based on the new status
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE SERVICE STATUS

  // START CODE FOR DELETE feature DOC
  const handleDeleteFeaturesDoc = async (featuresId) => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
      setFeatureToDelete(featuresId); // Set the featuresId to delete when the confirmation is received
    } catch (error) {
      console.error("Error deleting feature:", error);
      // Handle the error as needed
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      // Call deleteDocument function with the featuresId to delete the document
      await deleteDocument(featureIdToDelete);
      setIsDeletePopupOpen(false); // Close the popup after successful deletion
      setFeatureToDelete(null); // Reset the featureIdToDelete after deletion
    } catch (error) {
      console.error("Error deleting feature:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR DELETE feature DOC

  // START CODE FOR DRAG SERVICES
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = featuresDoc[draggedIndex];
        const droppedService = featuresDoc[droppedIndex];
        const updatedServices = [...featuresDoc];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG SERVICES

  //START CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE
  // Update toggleHeartStatus function
  const toggleHeartStatus = (featuresId) => {
    const newHeartStatus = !isHeartClicked[featuresId];
    setIsHeartClicked((prevState) => ({
      ...prevState,
      [featuresId]: newHeartStatus,
    }));
    localStorage.setItem(`heartStatus_${featuresId}`, newHeartStatus);
  };

  // Initialize heart status object in useEffect
  useEffect(() => {
    if (featuresDoc) {
      const initialHeartStatusFromLocalStorage = {};
      featuresDoc.forEach((features) => {
        const localStorageKey = `heartStatus_${features && features.id}`;
        const storedHeartStatus = localStorage.getItem(localStorageKey);
        if (storedHeartStatus !== null) {
          initialHeartStatusFromLocalStorage[features && features.id] =
            storedHeartStatus === "true";
        } else {
          // If no heart status is stored, default to false
          initialHeartStatusFromLocalStorage[features && features.id] = false;
        }
      });
      setIsHeartClicked(initialHeartStatusFromLocalStorage);
    }
  }, [featuresDoc]);

  //END CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE

  // START CODE FOR EXPAND FEATURES CONTENT
  const toggleExpand = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  // END CODE FOR EXPAND FEATURES CONTENT

  return (
    <div>
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this features?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />

      {featuresDoc &&
        featuresDoc.map((features, index) =>
          (user && user.role === "admin") ||
          (features && features.status === "active") ? (
            <div
              className={`${draggedIndex === index ? "dragging" : ""}`}
              draggable={user && user.role === "admin" && true}
              onDragStart={() => handleDragStart(index)}
              onDrag={(e) => handleDrag(e)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDrop(index)}
              key={index}
            >
              <div className="container">
                {user && user.role === "admin" && (
                  <div
                    className="drag-button-container-only-faq"
                    style={{
                      position: "static",
                      transform: "none",
                      textAlign: "center",
                    }}
                  >
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: "var(--click-color)",
                        fontSize: "25px",
                        cursor: "grab",
                      }}
                      title=" "
                    >
                      apps
                    </span>
                    <span className="tooltip">Drag and swap faq position</span>
                  </div>
                )}
                <div className="career-section">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="pcs_image_area">
                        <div className="bigimage_container">
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              maxWidth: "600px",
                            }}
                          >
                            {features &&
                              features.featuresImages &&
                              features.featuresImages.length > 0 && (
                                <Gallery
                                  items={features.featuresImages.map((url) => {
                                    // Extract the file extension
                                    const extension = url
                                      .split(".")
                                      .pop()
                                      .split("?")[0]
                                      .toLowerCase();
                                    if (
                                      extension == "mp4" ||
                                      extension === "webm" ||
                                      extension === "ogg"
                                    ) {
                                      // If it's a video
                                      return {
                                        original: url,
                                        thumbnail: "", // You might want to set a thumbnail for videos
                                        renderItem: () => (
                                          <video controls>
                                            <source
                                              src="https://firebasestorage.googleapis.com/v0/b/cloudify-dev-84f4e.appspot.com/o/features_img%2FftH5wJuGARl37amdeVPC%2Fbig_buck_bunny_720p_1mb.mp4?alt=media&token=2ce0d2a4-3805-44d1-aa48-07220561bacb"
                                              type={`video/${extension}`}
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        ),
                                      };
                                    } else {
                                      // If it's an image
                                      return {
                                        original: url,
                                        thumbnail: url,
                                      };
                                    }
                                  })}
                                  slideDuration={1000}
                                />
                              )}
                          </div>
                          {/* Box for adding more images */}
                          {user && user.role == "admin" && (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ margin: "15px 0px" }}
                            >
                              {selectedImage == 0 && (
                                <>
                                  <button
                                    className="btn_fill"
                                    onClick={() =>
                                      document
                                        .getElementById("mediaUpload")
                                        .click()
                                    }
                                  >
                                    Add More
                                  </button>
                                  <input
                                    type="file"
                                    id="mediaUpload"
                                    accept="image/*,video/*" // Allow selecting both images and videos
                                    multiple // Allow selecting multiple files
                                    style={{ display: "none" }}
                                    onChange={handleImageChange} // Call handleMediaChange function when files are selected
                                  />
                                </>
                              )}

                              {selectedImage != 0 && (
                                <>
                                  {selectedImage.length !== 0 ? (
                                    <>
                                      <button
                                        className="btn_fill"
                                        onClick={() =>
                                          handleConfirmImage(features.id)
                                        }
                                        disabled={isImageUploading} // Disable the button when uploading
                                      >
                                        {isImageUploading
                                          ? "Uploading..."
                                          : "Confirm"}
                                      </button>
                                    </>
                                  ) : (
                                    <button disabled className="btn_fill">
                                      Uploading...
                                    </button>
                                  )}
                                </>
                              )}

                              {/* Render selected images */}
                              {selectedImage.map((image, index) => (
                                <img
                                  key={index}
                                  src={URL.createObjectURL(image)}
                                  alt={`Selected ${index + 1}`}
                                  style={{
                                    marginLeft: "10px",
                                    maxWidth: "100px",
                                  }}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                      <MDBCard className="mb-4">
                        <MDBCardBody style={{ textAlign: "left" }}>
                          <MDBRow>
                            <MDBCol sm="12">
                              <MDBCardText style={{ fontWeight: "bold" }}>
                                Title:
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingFeaturesTitle ? (
                                  <div>
                                    <TextEditor
                                      initialDescription={editedFeaturesTitle}
                                      documentId={features.id}
                                      docFieldName="Title"
                                      collectionName="features"
                                      onSave={() =>
                                        handleSaveClick("featurestitle")
                                      }
                                      onCancel={() =>
                                        handleCancelClick("featurestitle")
                                      }
                                    />
                                    {/* <input
                                      type="text"
                                      value={editedFeaturesTitle}
                                      onChange={(e) =>
                                        setEditedFeaturesTitle(e.target.value)
                                      }
                                    />
                                    <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "featurestitle",
                                            features.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick("featurestitle")
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div> */}
                                  </div>
                                ) : (
                                  <>
                                    {/* {features && features.Title} */}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: features.Title.toString("html"),
                                      }}
                                    ></p>
                                    {!isEditingFeaturesTitle &&
                                      user &&
                                      user.role == "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--pink)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("featurestitle")
                                          }
                                        />
                                      )}
                                  </>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <hr />
                          <MDBRow
                            onClick={() => toggleExpand("featuresdescription")}
                            style={{ cursor: "pointer" }}
                          >
                            <MDBCol
                              sm="12"
                              className="d-flex align-items-center justify-content-between"
                            >
                              <div>
                                <MDBCardText style={{ fontWeight: "bold" }}>
                                  Description:
                                </MDBCardText>
                              </div>
                              <div>
                                <span
                                  class="material-symbols-outlined"
                                  style={{
                                    fontSize: "1.6rem",
                                    color: "black",
                                  }}
                                >
                                  arrow_drop_down
                                </span>
                              </div>
                            </MDBCol>
                            <MDBCol sm="12">
                              <MDBCardText className="text-muted">
                                {isEditingJobDescription ? (
                                  <div>
                                    <div>
                                      {/* <textarea
                                        value={editedFeaturesDescription}
                                        onChange={(e) =>
                                          setEditedFeaturesDescription(
                                            e.target.value
                                          )
                                        }
                                      /> */}
                                      <TextEditor
                                        initialDescription={
                                          editedFeaturesDescription
                                        }
                                        documentId={features.id}
                                        docFieldName="Description"
                                        collectionName="features"
                                        onSave={() =>
                                          handleSaveClick("featuresdescription")
                                        }
                                        onCancel={() =>
                                          handleCancelClick(
                                            "featuresdescription"
                                          )
                                        }
                                      />
                                    </div>
                                    {/* <div className="d-flex">
                                      <button
                                        className="product_edit_save_btn"
                                        onClick={() =>
                                          handleSaveClick(
                                            "featuresdescription",
                                            features.id
                                          )
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="product_edit_save_btn cancel-btn"
                                        onClick={() =>
                                          handleCancelClick(
                                            "featuresdescription"
                                          )
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Cancel
                                      </button>
                                    </div> */}
                                  </div>
                                ) : (
                                  <div>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace:
                                          expandedSection ===
                                          "featuresdescription"
                                            ? "normal"
                                            : "nowrap",
                                      }}
                                    >
                                      {/* {features && features.Description} */}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            features &&
                                            features.Description.toString(
                                              "html"
                                            ),
                                        }}
                                      ></p>
                                      {!isEditingJobDescription &&
                                        user &&
                                        user.role == "admin" && (
                                          <FaEdit
                                            style={{
                                              fontSize: 20,
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "var(--pink)",
                                            }}
                                            onClick={() =>
                                              handleEditClick(
                                                "featuresdescription"
                                              )
                                            }
                                          />
                                        )}
                                    </p>
                                  </div>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>

                          {user && user.role == "admin" && (
                            <>
                              <hr />
                              <MDBRow>
                                <MDBCol sm="3">
                                  <MDBCardText style={{ fontWeight: "bold" }}>
                                    Status:
                                  </MDBCardText>
                                </MDBCol>
                                <MDBCol sm="12">
                                  <MDBCardText className="text-muted">
                                    <div className="d-flex">
                                      <div>
                                        <p style={{ marginTop: "2px" }}>
                                          Inactive
                                        </p>
                                      </div>
                                      <ReactSwitch
                                        checked={
                                          features &&
                                          features &&
                                          features.status === "active"
                                        }
                                        onChange={() =>
                                          handleToggleChange(
                                            features && features.id,
                                            features && features.status
                                          )
                                        }
                                        onColor="#149AA2"
                                        offColor="#F05B83"
                                        className="products-status-switch"
                                      />
                                      <div>
                                        <p style={{ marginTop: "2px" }}>
                                          Active
                                        </p>
                                      </div>
                                    </div>
                                  </MDBCardText>
                                </MDBCol>
                              </MDBRow>
                            </>
                          )}
                        </MDBCardBody>
                      </MDBCard>
                      <div className="d-flex align-items-center namesd">
                        <MDBCol className="d-flex align-items-center">
                          <MDBCardText style={{ cursor: "pointer" }}>
                            <span
                              className="material-symbols-outlined"
                              onClick={shareURL}
                              style={{ fontSize: "23px", padding: "6px 0px" }}
                            >
                              share
                            </span>
                          </MDBCardText>

                          {user && user.role == "admin" && (
                            <MDBCardText
                              onClick={() =>
                                handleDeleteFeaturesDoc(features.id)
                              }
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                style={{
                                  fontSize: "23px",
                                  padding: "6px 0px",
                                }}
                              >
                                delete
                              </span>
                            </MDBCardText>
                          )}

                          <FaHeart
                            style={{
                              fontSize: 20,
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: isHeartClicked[features && features.id]
                                ? "red"
                                : "grey",
                            }}
                            onClick={() =>
                              toggleHeartStatus(features && features.id)
                            }
                          />
                        </MDBCol>

                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/contact">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Apply
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br /> <br />
            </div>
          ) : null
        )}
    </div>
  );
};

export default Features;

import React, { useState,useEffect } from "react";
import "./CorporateTwoPGAboutInfo.scss";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";
import { useFirestore } from "../../../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import RichTextEditor from "react-rte";

const CorporateTwoPGAboutInfo = () => {
  const { user } = useAuthContext();

  const { document: pagesTextDataDocument, error: pagesTextDataError } =
    useDocument("pages-data", "about");

  const { updateDocument, response } = useFirestore("pages-data");

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (pagesTextDataDocument) {
      setValue(
        RichTextEditor.createValueFromString(
          pagesTextDataDocument.aboutInfo,
          "html"
        )
      );
    }
  }, [pagesTextDataDocument]);

  const handleSaveClick = async () => {
    try {
      const content = value.toString("html");

      // Update the document in Firestore
      await updateDocument("about", { aboutInfo: content });

      if (response.error) {
        console.error("Error updating document:", response.error);
      } else {
        console.log("Document updated successfully");
        setHasChanges(false); // Reset changes tracking after successful save
      }
    } catch (error) {
      console.error("An error occurred while updating the document:", error);
    }
  };

  
  const handleEditorChange = (newValue) => {
    setValue(newValue);
    // Compare the current editor value with the original document to detect changes
    if (newValue.toString("html") !== pagesTextDataDocument.aboutInfo) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  return (
    <div className="corporateTwo-aboutpg-info">
    {user && user.role == "admin" && (
      <div>
        <RichTextEditor value={value} onChange={handleEditorChange} />
        {hasChanges && (
          <button
            className="product_edit_save_btn"
            onClick={handleSaveClick}
          >
            Save
          </button>
        )}
      </div>
    )}
    <p
      dangerouslySetInnerHTML={{
        __html:
          pagesTextDataDocument &&
          pagesTextDataDocument.aboutInfo.toString("html"),
      }}
    ></p>
  </div>
  )
};

export default CorporateTwoPGAboutInfo;

import React, { useState, useEffect } from "react";
import "./BSSearchAndFilters.css";

const defaultFilterList = ["ALL", "ACTIVE", "INACTIVE"];
const defaultFilterLength = 0;

const BSSearchAndFilters = ({
  onSearch,
  onFilterChange,
  changeFilter,
  filterList,
  filterLength,
}) => {
  const [isPendingSelected, setIsPendingSelected] = useState(false);
  const [isCompleteSelected, setIsCompleteSelected] = useState(false);
  const [isCancelSelected, setIsCancelSelected] = useState(false);
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [isFrontdeskSelected, setIsFrontdeskSelected] = useState(false);
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    onSearch(event.target.value);
  };
  const handleSearch = () => {
    onSearch(query);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    onFilterChange(event.target.value);
  };

  //   Start change filter icon in phone view
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
    if (!isCardVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };
  const handleButtonClick = (buttonType) => {
    switch (buttonType) {
      case "pending":
        setIsPendingSelected(!isPendingSelected);
        break;
      case "completed":
        setIsCompleteSelected(!isCompleteSelected);
        break;
      case "cancelled":
        setIsCancelSelected(!isCancelSelected);
        break;
      case "customer":
        setIsCustomerSelected(!isCustomerSelected);
        break;
      case "frontdesk":
        setIsFrontdeskSelected(!isFrontdeskSelected);
        break;
      case "admin":
        setIsAdminSelected(!isAdminSelected);
        break;
      default:
        break;
    }
  };

  //   start four category filter
  // const { user } = useAuthContext()
  let defaultFilterValue = filterList[0];
  const [currentFilter, setCurrentFilter] = useState(defaultFilterValue);

  if (!filterList) filterList = defaultFilterList;
  if (!filterLength) filterLength = defaultFilterLength;

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter);
    changeFilter(newFilter);
  };

  return (
    <>
      <div className="search-sort-paret-div d-flex align-items-center justify-content-center">
        <div className="search_bar">
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Search..."
          />
          <div class="search_icon">
            <span onClick={handleSearch} class="material-symbols-outlined">
              search
            </span>
          </div>
        </div>
        <div className="filter_dropdown yearly">
          <div style={{ margin: "6px 0px 0px 10px" }}>
            <select
              value={filter}
              onChange={handleFilterChange}
              className="search_y_year"
            >
              <option value="">All</option>
              <option value="last-month">Last Month</option>
              <option value="last-3-months">Last 3 Months</option>
              <option value="2023">Year 2023</option>
              <option value="2024">Year 2024</option>
            </select>
          </div>
          <div style={{ margin: "6px 0px 0px 10px" }}>
            <span
              className="material-symbols-outlined filters-icon-user-list"
              onClick={toggleCardVisibility}
              style={{ fontSize: "25px" }}
            >
              tune
            </span>
          </div>
        </div>
      </div>
      {isCardVisible && (
        <div>
          <div className="overlay-stop" onClick={toggleCardVisibility}></div>
          <div className="animated-card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h5
                  style={{
                    padding: "15px",
                    fontWeight: "700",
                    color: "var(--pink)",
                  }}
                >
                  Filter Bookings
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: "#e7e7e7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  marginRight: "20px",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                }}
                onClick={toggleCardVisibility}
              >
                <span class="material-symbols-outlined">close</span>
              </div>
            </div>

            <div className="filter_dropdown yearly">
              <h6
                style={{
                  margin: "5px 0px 10px 15px",
                  fontWeight: "600",
                  color: "var(--click-color)",
                }}
              >
                By Date
              </h6>
              <select
                value={filter}
                onChange={handleFilterChange}
                className="search_y_year_mobile"
              >
                <option value="">All</option>
                <option value="last-month">Last Month</option>
                <option value="last-3-months">Last 3 Months</option>
                <option value="2023">Year 2023</option>
                <option value="2024">Year 2024</option>
              </select>
            </div>
            <div className="filter_dropdown yearly">
              <h6
                style={{
                  margin: "20px 0px 10px 15px",
                  fontWeight: "600",
                  color: "var(--click-color)",
                }}
              >
                Custom Date
              </h6>
              <button
                className={`${
                  isPendingSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("pending")}
              >
                Pending
              </button>
              <button
                className={`${
                  isCompleteSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("completed")}
              >
                Completed
              </button>
              <button
                className={`${
                  isCancelSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("cancelled")}
              >
                Cancelled
              </button>
            </div>
            <div className="filter_dropdown yearly">
              <h6
                style={{
                  margin: "20px 0px 10px 15px",
                  fontWeight: "600",
                  color: "var(--click-color)",
                }}
              >
                By Status
              </h6>
              <button
                className={`${
                  isPendingSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("pending")}
              >
                Pending
              </button>
              <button
                className={`${
                  isCompleteSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("completed")}
              >
                Completed
              </button>
              <button
                className={`${
                  isCancelSelected
                    ? "filter-user-status-selected-button"
                    : "search_y_year_mobile"
                }`}
                onClick={() => handleButtonClick("cancelled")}
              >
                Cancelled
              </button>
            </div>
            <div>
              <h6
                style={{
                  margin: "20px 0px 10px 15px",
                  fontWeight: "600",
                  color: "var(--click-color)",
                }}
              >
                By Roles
              </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button
                  className={`${
                    isCustomerSelected
                      ? "filter-user-selected-button"
                      : "search_roles_mobile"
                  }`}
                  onClick={() => handleButtonClick("customer")}
                >
                  Customer
                </button>
                <button
                  className={`${
                    isFrontdeskSelected
                      ? "filter-user-selected-button"
                      : "search_roles_mobile"
                  }`}
                  onClick={() => handleButtonClick("frontdesk")}
                >
                  Frontdesk
                </button>
                <button
                  className={`${
                    isAdminSelected
                      ? "filter-user-selected-button"
                      : "search_roles_mobile"
                  }`}
                  onClick={() => handleButtonClick("admin")}
                >
                  Admin
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "30px",
              }}
            >
              <button
                className="cancel_filter_btn_border"
                onClick={toggleCardVisibility}
              >
                Cancel
              </button>
              <button
                className="apply_filter_btn_border"
                onClick={toggleCardVisibility}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
      {/* quick filter */}
      <div className="project-filter">
        <nav className="four_services_filter_parent">
          {filterList.map((filternames) => (
            <button
              key={filternames}
              onClick={() => handleClick(filternames)}
              className={currentFilter === filternames ? "active" : ""}
            >
              <span>{filternames}</span>
              {currentFilter === filternames ? (
                <small>{filterLength}</small>
              ) : (
                ""
              )}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default BSSearchAndFilters;

import React, { useState, useEffect, useRef } from "react";
import "./BusinessThreeWhyUs.scss";

const BusinessThreeWhyUs = ({ id }) => {
  const [activeTab, setActiveTab] = useState("Why We Stand Out");
  const [animationClass, setAnimationClass] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const headingRef = useRef(null);

  const tabs = [
    "Why We Stand Out",
    "Our Advantages",
    "Our Benefits",
    "What Sets Us Apart",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTabIndex((prevIndex) => (prevIndex + 1) % tabs.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleTabClick(tabs[tabIndex]);
  }, [tabIndex]);

  const handleTabClick = (tab) => {
    setAnimationClass("business-three-why-choose-us-animate");
    setTimeout(() => {
      setActiveTab(tab);
      setAnimationClass("");
    }, 500);
  };

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING

  return (
    <div className="business-three-why-choose-us-container-parent" id={id}>
      <div className="small_padding_div"></div>

      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
          <h3>
            <span>Why Us?</span> Here's Why
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span class="material-symbols-outlined">arrow_forward_ios</span>{" "}
        </div>
      </div>
      <div className="container business-three-why-choose-us-container">
        <div className="business-three-why-choose-us-left-panel">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`business-three-why-choose-us-slip ${
                activeTab === tab ? "business-three-why-choose-us-active" : ""
              }`}
              onClick={() => handleTabClick(tab)}
            >
              <span className="material-symbols-outlined">{getIcon(tab)}</span>
              <h5 className="business-three-why-choose-us-tab-text">
                {activeTab === tab ? tab : tab.charAt(0)}
              </h5>
            </div>
          ))}
        </div>
        <div className="business-three-why-choose-us-card">
          <div
            className={`business-three-why-choose-us-card-content ${animationClass}`}
          >
            {activeTab === "Why We Stand Out" && (
              <div className="business-three-why-choose-us-tab-content">
                <img
                  src="https://oorjainstitute.com/wp-content/uploads/2023/02/BEst-Web-Design-Courses-in-Daryaganj.png"
                  alt="Why"
                />
                <p className="business-three-why-choose-us-tab-description">
                  Why choose us? We offer unparalleled expertise, dedicated
                  support, and a proven track record of success.
                </p>
              </div>
            )}
            {activeTab === "Our Advantages" && (
              <div className="business-three-why-choose-us-tab-content">
                <img
                  src="https://mulytic-web-content.s3.amazonaws.com/media/Mulytic/Photos/why_choose_us_SgK2eiE.png"
                  alt="Who"
                />
                <p className="business-three-why-choose-us-tab-description">
                  Who we are is defined by our commitment to excellence,
                  innovation, and customer satisfaction.
                </p>
              </div>
            )}
            {activeTab === "Our Benefits" && (
              <div className="business-three-why-choose-us-tab-content">
                <img
                  src="https://thermonicindia.com/wp-content/uploads/2023/03/why-choose-us-01-1024x622.jpg"
                  alt="Our Benefits"
                />
                <p className="business-three-why-choose-us-tab-description">
                  What we do is deliver cutting-edge solutions that drive growth
                  and create lasting value for our clients.
                </p>
              </div>
            )}
            {activeTab === "What Sets Us Apart" && (
              <div className="business-three-why-choose-us-tab-content">
                <img
                  src="https://th.bing.com/th/id/OIP.uXjgm0du66NDpFwQoRuMGwHaD3?w=1600&h=836&rs=1&pid=ImgDetMain"
                  alt="What Sets Us Apart"
                />
                <p className="business-three-why-choose-us-tab-description">
                  When it comes to delivering results, we are always on time,
                  ensuring our clients achieve their goals.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="small_padding_div"></div>
    </div>
  );
};

const getIcon = (tab) => {
  switch (tab) {
    case "Our Advantages":
      return "handshake";
    case "Why We Stand Out":
      return "touch_app";
    case "Our Benefits":
      return "volunteer_activism";
    case "What Sets Us Apart":
      return "thumb_up";
    default:
      return "";
  }
};

export default BusinessThreeWhyUs;

import React from "react";
import "./CorporateTwoDownloadApp.scss";

const CorporateTwoDownloadApp = () => {
  return (
    <div className="corporateTwo-home-downloadApp">
      <div className="corporateTwo-home-downloadApp-info">
        <div className="corporateTwo-home-downloadApp-heading">
          <h1>Experience most with our App</h1>
          <h2>Get our App</h2>
        </div>

        <div className="corporateTwo-home-downloadApp-source">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fplay-store-icon.png?alt=media&token=71794151-d02c-4bae-b48e-57aadb0e6745"
              alt="google playstore"
            />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fapple-store-icon.png?alt=media&token=ba2eda36-13c6-42d0-9e11-b6912ac31127"
            alt="apple app store"
          />
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoDownloadApp;

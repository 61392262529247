import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./BusinessTwoFaqs.scss";
import Popup from "../../../../default components/Popup";
import Accordion from "react-bootstrap/Accordion";
import AddMedia from "../../../../default components/AddMedia";

// FIREBASE
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";

const BusinessTwoFaqs = ({
  category,
  pageName,
  section,
  addMoreImagesButton,
  addMoreVideosButton,
}) => {
  const { user } = useAuthContext();
  const { documents: dbTextContentDocuments, error: dbTextContentError } =
    useCollection("media", ["category", "==", "faq"]);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const { updateDocument, deleteDocument } = useFirestore("media");

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productIdToDelete);
      setIsDeletePopupOpen(false); // Close popup on successful delete
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const textContentDocuments =
    dbTextContentDocuments &&
    dbTextContentDocuments.filter(
      (item) => item.mediaType.toUpperCase() === "TEXT"
    );

  // Sort the documents based on the order field
  const sortedDocuments = dbTextContentDocuments
    ? dbTextContentDocuments.sort((a, b) => a.order - b.order)
    : [];

  // START CODE FOR DRAG FAQ
  const [isDragging, setIsDragging] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = dbTextContentDocuments[draggedIndex];
        const droppedService = dbTextContentDocuments[droppedIndex];
        const updatedServices = [...dbTextContentDocuments];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG FAQ

  return (
    <div className="BusinessTwoFaqs-parent">
      <div className="container">
        <div className="row BusinessTwoFaqs-faq-row-div">
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <ul>
              <Popup
                showPopupFlag={isDeletePopupOpen}
                setShowPopupFlag={setIsDeletePopupOpen}
                msg="Are you sure you want to delete this Faq?"
                setPopupReturn={(confirmed) => {
                  if (confirmed) {
                    handleConfirmDeleteProduct();
                  }
                }}
              />
              <div>
                <div class="wrapper">
                  <svg>
                    <text x="0%" y="50%" dy=".35em" text-anchor="start">
                      FAQ's
                    </text>
                  </svg>
                </div>
              </div>
              {/* <h1
                className="BusinessTwoFaqs-faq-heading"
              >
                FAQ's
              </h1> */}
              <Accordion defaultActiveKey="0">
                {textContentDocuments &&
                  textContentDocuments.map((textContent, index) => (
                    <div
                      className={`doctor-item ss ${
                        draggedIndex === index ? "dragging" : ""
                      }`}
                      style={{ marginTop: "10px" }}
                      draggable={user && user.role === "admin"}
                      onDragStart={() => handleDragStart(index)}
                      onDrag={handleDrag}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={() => handleDrop(index)}
                      key={index} // Add key here to ensure unique identification
                    >
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>
                          <div className="d-flex">
                            {user && user.role === "admin" && (
                              <>
                                <div
                                  onClick={() =>
                                    handleDeleteProduct(textContent.id)
                                  }
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "23px" }}
                                  >
                                    delete
                                  </span>
                                </div>
                                <div className="drag-button-container-only-faq">
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      color: "var(--click-color)",
                                      fontSize: "25px",
                                      cursor: "grab",
                                    }}
                                    title="Drag to reorder"
                                  >
                                    apps
                                  </span>
                                  <span className="tooltip">
                                    Drag and swap FAQ position
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                          {textContent.heading}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div style={{ textAlign: "left" }}>
                            {textContent.longDescription}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ))}
              </Accordion>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <AddMedia
                  category={category}
                  section={section}
                  pageName={pageName}
                  addMoreVideosButton={addMoreVideosButton}
                  addMoreImagesButton={addMoreImagesButton}
                />
              </div>
            </ul>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <img
              // src="/assets/img/faq-tranparent-img.png"
              style={{
                width: "75%",
                marginTop: "-75px",
              }}
              src="https://i.pinimg.com/originals/69/df/62/69df621eac2bb71d5d75d36574aae64b.gif"
              alt="faq img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTwoFaqs;

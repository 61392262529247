import React, { useEffect, useRef } from "react";
import "./BusinessThreeNewsAndBlogs.scss";

const BusinessThreeNewsAndBlogs = ({ id }) => {
  const headingRef = useRef(null);

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING
  return (
    <div className="news-n-blogs-three-parent" id={id}>
      <div className="medium_padding_div"></div>
      <div className="small_padding_div"></div>

      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
        <h3>
            <span>News</span> and <span>Blog</span> Highlights
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span class="material-symbols-outlined">arrow_forward_ios</span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* Left Column */}
          <div className="col-lg-6 col-12">
            <div className="news-n-blogs-three-left-parent">
              <img
                src="https://smart-laundry.developmentalphawizz.com/yoga/images/blog/1700123208.jpg"
                alt="Main Blog"
              />
              <div className="content">
                <div className="date">26 Jul, 2024</div>
                <h4>
                  Inside Linen Production: How Linens Are Made | And Wilkins
                  Linen
                </h4>
                <p>
                  Skillible Salesforce Authorized Partner Salesforce Authorized
                  Partner Skillible is an authorized Salesforce Workforce
                  Development Partner. Skillible Pre-Trained Developers
                  Pre-Trained Developers Get access to upskilled resources &
                  spend zero amount.
                </p>
                <button className="btn_fill">Read</button>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-lg-6 col-12">
            <div className="row">
              {/* Top Right Card */}
              <div className="col-lg-12 col-12">
                <div className="news-n-blogs-three-right-parent">
                  <img
                    src="https://smart-laundry.developmentalphawizz.com/yoga/images/blog/1714218972.png"
                    alt="Blog 1"
                  />
                  <div className="content">
                    <div className="date">26 Jul, 2024</div>
                    <h4>
                      Inside Linen Production: How Linens Are Made |And Wilkins
                      Linen
                    </h4>
                    <p>
                      Skillible Salesforce Authorized Partner Salesforce
                      Authorized Partner Skillible is an authorized Salesforce
                      Workforce Development Partner. Skillible Pre-Trained
                      Developers Pre-Trained Developers Get access to upskilled
                      resources & spend zero amount.
                    </p>
                    <button className="btn_fill">Read</button>
                  </div>
                </div>
              </div>

              {/* Bottom Right Card */}
              <div className="col-lg-12 col-12">
                <div className="news-n-blogs-three-right-parent">
                  <img
                    src="https://smart-laundry.developmentalphawizz.com/yoga/images/blog/1717237576.jpg"
                    alt="Blog 2"
                  />
                  <div className="content">
                    <div className="date">26 Jul, 2024</div>
                    <h4>
                      Inside Linen Production: How Linens Are Made | Wilkins
                      Linen
                    </h4>
                    <p>
                      Skillible Salesforce Authorized Partner Salesforce
                      Authorized Partner Skillible is an authorized Salesforce
                      Workforce Development Partner. Skillible Pre-Trained
                      Developers Pre-Trained Developers Get access to upskilled
                      resources & spend zero amount.
                    </p>
                    <button className="btn_fill">Read</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="small_padding_div"></div>

      <div className="large_padding_div"></div>
    </div>
  );
};

export default BusinessThreeNewsAndBlogs;

import React from "react";
import { useCollection } from "../../firebase files/hooks/useCollection";
import UserList from "../../default components/UserList";

const PGAdminUser = () => {
  const { documents: users, error } = useCollection("users", null, [
    "createdAt",
    "desc",
  ]);
  return (
    <div className="pgadminuser pg_padding">
      {error && <p className="error">{error}</p>}
      {users && (
        <div className="userlist ">
          <div className="container-fluid">
            <div className="user_card">
              {users.map((user) => (
                <UserList key={user && user.uid} user={user} />
              ))}
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
};

export default PGAdminUser;

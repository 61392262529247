import React, { useState, useEffect } from "react";
import { useDocument } from "./useDocument"; 

const useColors = () => {
  const { document: colorsDocument, error: colorsDocumentError } = useDocument(
    "settings",
    "Colors"
  );

  const [colors, setColors] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("mode") || "light");

  const updateColors = () => {
    if (colorsDocument) {
      const themeColors =
        theme === "dark"
          ? colorsDocument.DarkScreenTheme
          : colorsDocument.LightScreenTheme;

      if (themeColors) {
        const logoPrimaryColor = themeColors.logoPrimaryColor;
        const clickColor = themeColors.clickColor;
        const cardBgColor = themeColors.cardBgColor;
        const headingColor = themeColors.headingColor;
        const subheadingColor = themeColors.subheadingColor;

        setColors({
          clickColor,
          logoPrimaryColor,
          cardBgColor,
          headingColor,
          subheadingColor,
        });
      }
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem("mode") ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      localStorage.setItem("mode", "light");
      setTheme("light");
    } else {
      setTheme(localStorage.getItem("mode"));
    }

    const handleStorageChange = () => {
      const storedTheme = localStorage.getItem("mode");
      if (storedTheme) {
        setTheme(storedTheme); 
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    updateColors(); 
  }, [theme, colorsDocument]); 

  return { colors, colorsDocumentError };
};

export default useColors;

import React from "react";
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { timestamp } from "../firebase config/config";
import "./CreateTicket.scss";

const CreateTicket = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Add and remove class
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { addDocument, response: addDocumentResponse } = useFirestore("tickets");
  const [formError, setFormError] = useState(null);
  const [ticketType, setTicketType] = useState("General Inquiries");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorMsg = "You are not logged-in";
    let message = document.getElementById("id_message").value;

    const newMessage = {
      email: "",
      message: message,
      postedBy: "Propdial",
      status: "active",
      type: ticketType,
      updatedAt: timestamp.fromDate(new Date()),
    };

    if (!user || user === null) setFormError(errorMsg);
    else {
      await addDocument(newMessage);
      navigate("/ticketdetail");
    }
  };

  return (
    <div className="create_ticket_top_header_pg">
      <div
        className="d-flex align-items-center justify-content-center create_ticket_right_main_form"
        style={{
          backgroundImage: "url('/assets/img/lsbg.png')",
        }}
      >
        <div className="create_ticket_rmf_inner">
          <form onSubmit={handleSubmit} className="create_ticket_form-w">
            <h4 className="create_ticket_title">Contact Us</h4>

            <div className="create_ticket_fl_form_field create_ticket_st-2 create_ticket_top_margin">
              <label htmlFor="" className="create_ticket_no-floating">
                For
              </label>
              <select
                onChange={(e) => {
                  setTicketType(e.target.value);
                }}
              >
                <option>General Inquiries</option>
                {/* <option>Billing and Account Management</option> */}
                <option>Technical Issues</option>
                {/* <option>Training Requests</option> */}
              </select>
            </div>
            <div className="create_ticket_form_field create_ticket_st-2">
              <div className="create_ticket_field_inner">
                <textarea
                  id="id_message"
                  required
                  maxLength={500}
                  type="text"
                  placeholder="Message"
                  name="message"
                />
                <div className="create_ticket_field_icon">
                  <span className="material-symbols-outlined">chat</span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="create_ticket_submit_btn mt-4">
                <button
                  type="submit"
                  className="btn_fill"
                >
                  Send
                </button>
              </div>
              {formError && <p className="error">{formError}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;

import React, { useState, useEffect } from "react";
import "./CorporateTwoTestimonials.scss";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaQuoteLeft } from "react-icons/fa";

const testimonialsData = [
  {
    id: 1,
    user: "Naman Gaur",
    userImage:
      "https://images.unsplash.com/photo-1640951613773-54706e06851d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHVzZXJ8ZW58MHx8MHx8fDA%3D",
    review:
      "Very good service, completely satisfied with smart wash laundry and will surely visit again",
  },
  {
    id: 2,
    user: "Sanskar Solanki",
    userImage:
      "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress&cs=tinysrgb&w=600",
    review:
      "Timely delivery and satisfactory results were promised by smart wash and they delivered every promise",
  },
  {
    id: 3,
    user: "Atul Tripathi",
    userImage:
      "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=600",
    review:
      "It was a great experience with smart wash and they listned every request and were happy to assist",
  },
  {
    id: 4,
    user: "PRS SAI",
    userImage:
      "https://images.unsplash.com/photo-1640960543409-dbe56ccc30e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHVzZXJ8ZW58MHx8MHx8fDA%3D",
    review:
      "Delivery given on time , service & packaging done excellent , Very professionally managed",
  },
  {
    id: 5,
    user: "Anil Kanade",
    userImage:
      "https://images.unsplash.com/photo-1630910561339-4e22c7150093?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fHVzZXJ8ZW58MHx8MHx8fDI%3D",
    review: "Clean and hygiene",
  },
];

const CorporateTwoTestimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentTestimonial]);

  const handlePrevious = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentTestimonial((prev) =>
        prev > 0 ? prev - 1 : testimonialsData.length - 1
      );
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentTestimonial((prev) =>
        prev < testimonialsData.length - 1 ? prev + 1 : 0
      );
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  return (
    <div className="corporateTwo-home-testimonials">
      <div className="testimonials-content">
        <div className="corporateTwo-home-testimonials-heading">
          <h1>Our Testimonials</h1>
          <h2>What people say ?</h2>
        </div>

        <div className="corporateTwo-home-testimonials-container">
          {testimonialsData.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className={`corporateTwo-home-testimonial-card ${
                index === currentTestimonial
                  ? "active"
                  : index ===
                    (currentTestimonial - 1 + testimonialsData.length) %
                      testimonialsData.length
                  ? "prev"
                  : "next"
              }`}
            >
              <FaQuoteLeft className="quote-icon" />
              <img
                src={testimonial.userImage}
                alt={`${testimonial.user} profile`}
              />
              <div className="corporateTwo-home-testimonial-userinfo">
                <p className="review">{testimonial.review}</p>
                <h4 className="user">{testimonial.user}</h4>
              </div>
            </div>
          ))}
        </div>

        <div className="corporateTwo-home-testimonials-controls">
          <button onClick={handlePrevious} aria-label="Previous testimonial">
            <IoIosArrowBack />
          </button>
          <button onClick={handleNext} aria-label="Next testimonial">
            <IoIosArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoTestimonials;

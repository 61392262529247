import React, { useState } from "react";
import "./BusinessTwoOffers.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import Popup from "../../../../default components/Popup";
import AddMedia from "../../../../default components/AddMedia";
import ReactSwitch from "react-switch";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { projectStorage } from "../../../../firebase config/config";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
const BusinessTwoOffers = ({
  category,
  pageName,
  section,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
    },
  };

  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbMediaDocuments, error: dbMediaError } = useCollection(
    "media",
    ["category", "==", "offers"]
  );

  const offerImagesDocuments =
    dbMediaDocuments &&
    dbMediaDocuments.filter((item) => item.mediaType.toUpperCase() === "IMAGE");

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating offer status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = offerImagesDocuments.find(
        (doc) => doc.id === productIdToDelete
      );
      console.log("Document to delete:", documentToDelete);

      if (documentToDelete) {
        // Log the constructed storage path with the filename
        const storagePath = `images/${productIdToDelete}`;

        // Recursive function to delete all files and subfolders
        const deleteFolderRecursive = async (ref) => {
          const listResult = await ref.listAll();
          const deletePromises = listResult.items.map((item) => item.delete());
          await Promise.all(deletePromises);

          // Recursively delete subfolders
          const deleteFolderPromises = listResult.prefixes.map(
            deleteFolderRecursive
          );
          await Promise.all(deleteFolderPromises);
        };

        // Get the reference to the folder
        const storageRef = projectStorage.ref(storagePath);

        // Start the recursive deletion
        await deleteFolderRecursive(storageRef);

        // Delete the document from Firestore
        await deleteDocument(productIdToDelete);

        console.log("Offer deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div className="Business-Two-Offers-container">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this offer?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div className="Business-Two-Offers-left-side">
        <div className="Business-Two-Offers-content">
          <h1>
            <b>Special Offers</b>
          </h1>
          <h3>FLAT 30% Discount</h3>
        </div>
      </div>
      <div className="Business-Two-Offers-right-side">
        {offerImagesDocuments && offerImagesDocuments.length > 0 && (
          <OwlCarousel className="owl-theme" {...options}>
            {offerImagesDocuments &&
              offerImagesDocuments
                .filter((media) =>
                  isAdmin
                    ? !section || media.section == section
                    : media.status == "active" &&
                      (!section || media.section == section)
                )
                .map((offerImage) => (
                  <div key={offerImage.id} className="d-flex flex-column">
                    <div className="business-two-offer-item">
                      <img src={offerImage.mediaUrl} alt="carousel item 1" />
                    </div>
                    {isAdmin && (
                      <>
                        <div style={{ margin: "0px 0px 20px 15px" }}>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex align-items-center mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={offerImage.status == "active"}
                                    onChange={() =>
                                      handleToggleChange(
                                        offerImage.id,
                                        offerImage.status
                                      )
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{ color: "red" }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 d-flex justify-content-center mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() =>
                                    handleDeleteProduct(offerImage.id)
                                  }
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </>
                    )}
                  </div>
                ))}
          </OwlCarousel>
        )}

        <AddMedia
          category={category}
          pageName={pageName}
          section={section}
          addMoreVideosButton={addMoreVideosButton}
          addMoreTextContentButton={addMoreTextContentButton}
        />
      </div>
    </div>
  );
};

export default BusinessTwoOffers;

import React, { useState } from "react";
import "./BusinessTwoServices.scss";
import OwlCarousel from "react-owl-carousel";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { projectStorage } from "../../../../firebase config/config";
import ReactSwitch from "react-switch";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import Popup from "../../../../default components/Popup";
import { Link } from "react-router-dom";

const BusinessTwoServices = () => {
  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbServicesDocuments, error: dbBannersError } =
    useCollection("products");

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("banner status updated successfully");
    } else {
      console.error("Error updating banner status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting product ID:", productIdToDelete);
      const documentToDelete = dbServicesDocuments.find(
        (doc) => doc.id === productIdToDelete
      );
      console.log("Document to delete:", documentToDelete);

      if (documentToDelete) {
        // Log the constructed storage path with the filename
        const storagePath = `images/${productIdToDelete}`;

        // Recursive function to delete all files and subfolders
        const deleteFolderRecursive = async (ref) => {
          const listResult = await ref.listAll();
          const deletePromises = listResult.items.map((item) => item.delete());
          await Promise.all(deletePromises);

          // Recursively delete subfolders
          const deleteFolderPromises = listResult.prefixes.map(
            deleteFolderRecursive
          );
          await Promise.all(deleteFolderPromises);
        };

        // Get the reference to the folder
        const storageRef = projectStorage.ref(storagePath);

        // Start the recursive deletion
        await deleteFolderRecursive(storageRef);

        // Delete the document from Firestore
        await deleteDocument(productIdToDelete);

        console.log("banner deleted successfully");
      } else {
        console.warn("Invalid document or not an image type.");
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div className="home_two_service_parent_div">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this banner?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div className="container">
        <div className="row">
          {/* LEFT SECTION */}
          <div className="col-md-4 col-lg-4">
            <div className="home_two_service_left_card">
              <div>
                <img
                  src="/assets/img/box-unscreen.gif"
                  alt="product details card"
                />
              </div>
              <div>
                <div class="wrapper">
                  <svg>
                    <text
                      x="100%"
                      y="50%"
                      dy=".35em"
                      text-anchor="end"
                      width="100%"
                    >
                      OUR SERVICES
                    </text>
                  </svg>
                </div>
              </div>
              <div>
                <p>
                  Our freshly brewed list of the best residential projects from
                  top rated builders in the country, backed by our award-winning
                  start-to-finish services.
                </p>
              </div>
              <div>
                <Link to="/services">
                  <button className="btn_fill">View All</button>
                </Link>
              </div>
            </div>
          </div>
          {/* RIGHT SECTION */}
          <div className="col-md-8 col-lg-8">
            <div className="home_two_service_Right_card">
              <div>
                <OwlCarousel
                  items={1}
                  loop
                  dots={false}
                  nav={false}
                  autoplay={true} // Enable autoplay
                  autoplayTimeout={8000}
                  smartSpeed={4000}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 1,
                    },
                  }}
                >
                  {dbServicesDocuments &&
                    dbServicesDocuments.map((bannerImages) =>
                      (user && user.role === "admin") ||
                      bannerImages.status === "active" ? (
                        <div key={bannerImages.id}>
                          <div className="row">
                            <div className="col-md-5">
                              <img src={bannerImages.images[0]} alt="banner" />
                            </div>
                            <div className="col-md-7 home_two_service_Right_services_details">
                              <div className="d-flex align-items-center justify-content-end">
                                <h6>
                                  {bannerImages.rating}
                                  <span> | Exclusive</span>
                                </h6>
                              </div>
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: bannerImages.name.toString("html"),
                                  }}
                                ></p>
                              </div>
                              <div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      bannerImages.short_description.toString(
                                        "html"
                                      ),
                                  }}
                                ></p>
                              </div>
                              <div>
                                <button className="btn_fill">details</button>
                              </div>
                            </div>
                          </div>
                          {isAdmin && (
                            <div
                              style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                marginTop: "10px",
                                padding: "0px 20px",
                              }}
                            >
                              <MDBRow>
                                <MDBCol className="col-9">
                                  <MDBCardText className="text-muted">
                                    <div className="d-flex align-items-center mt-2">
                                      <div>
                                        <p
                                          className="active-inactive-text"
                                          style={{ color: "var(--red-color)" }}
                                        >
                                          Inactive
                                        </p>
                                      </div>
                                      <ReactSwitch
                                        checked={
                                          bannerImages.status === "active"
                                        }
                                        onChange={() =>
                                          handleToggleChange(
                                            bannerImages.id,
                                            bannerImages.status
                                          )
                                        }
                                        onColor="#149AA2"
                                        offColor="#F05B83"
                                        className="products-status-switch"
                                      />
                                      <div>
                                        <p
                                          className="active-inactive-text"
                                          style={{
                                            color: "var(--click-color)",
                                          }}
                                        >
                                          Active
                                        </p>
                                      </div>
                                    </div>
                                  </MDBCardText>
                                </MDBCol>
                                <MDBCol className="col-3 d-flex justify-content-center mt-2">
                                  <div>
                                    <MDBCardText
                                      onClick={() =>
                                        handleDeleteProduct(bannerImages.id)
                                      }
                                    >
                                      <span
                                        className="material-symbols-outlined"
                                        style={{ fontSize: "23px" }}
                                      >
                                        delete
                                      </span>
                                    </MDBCardText>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            </div>
                          )}
                        </div>
                      ) : null
                    )}
                </OwlCarousel>
                {/* Status Slip Component */}
                <div className="status-slip">
                  <div className="status-indicator active"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTwoServices;

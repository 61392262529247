import React from 'react'

// import css 
import './CorporateOnePartner.scss'

const CorporateOnePartner = () => {
  return (
    <div className='partners'>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner1.png" alt="" />
    </div>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner2.png" alt="" />
    </div>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner3.png" alt="" />
    </div>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner4.png" alt="" />
    </div>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner5.png" alt="" />
    </div>
    <div className="partner_single">
      <img src="/assets/img/corporate/corporateone/home/partner6.png" alt="" />
    </div>
  </div>
  )
}

export default CorporateOnePartner

import React, { useEffect, useState } from "react";
import "./EducationTwoAboutUs.scss";

const EducationTwoAboutUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallScreen = windowWidth <= 667;
  const hideShapes = windowWidth <= 767;

  return (
    <section className="et_aboutus sect_padding" style={{ position: "relative" }}>
      {isSmallScreen && (
        <div
          className="blurred-background"
          style={{
            backgroundImage: "url('./assets/img/educationtwo/about_main_img.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            filter: "blur(2px)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
          }}
        ></div>
      )}

      {isSmallScreen && (
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        ></div>
      )}

      <div
        className="container"
        style={{ position: "relative", zIndex: 2, color: "white", fontWeight: "bold" }}
      >
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="eta_left">
              {!isSmallScreen && (
                <>
                  <img
                    src="./assets/img/educationtwo/about_main_img.jpg"
                    alt=""
                    className="main_img"
                  />
                  {!hideShapes && (
                    <>
                      <img
                        src="./assets/img/educationtwo/about_img_top.jpg"
                        alt=""
                        className="img_top"
                      />
                      <img
                        src="./assets/img/educationtwo/about_img_bottom.jpg"
                        alt=""
                        className="img_bottom"
                      />
                      <img
                        src="./assets/img/educationtwo/about_shape.png"
                        alt=""
                        className="shape_img"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="eta_right">
              <div className="sect_header">
                <span className="sect_badge" style={{ color: "white !important" }}>
                  Our about us
                </span>
                <h3 className="sect_title" style={{ color: "white !important" }}>
                  <div className="h3_inner">
                    Learn & Grow your{" "}
                    <span className="relative" style={{ color: "white !important" }}>
                      skills
                      <span className="title-shape">
                        {/* SVG remains unchanged */}
                      </span>
                    </span>{" "}
                    From anywhere
                  </div>
                </h3>
              </div>
              <p className="theme_description" style={{ color: "white !important" }}>
                Our mission is to list the Best Tutors, Tuition Centers, and Kids
                Events and Classes. Tutortot is an Education City.
              </p>
              <div className="more_info">
                <div className="mi_left">
                  <h5 style={{ color: "white !important" }}>Exceptional tutors.</h5>
                  <p className="theme_description" style={{ color: "white !important" }}>
                    Exceptional tutors. We only accept the best from thousands
                    of applicants, so you can choose from the widest range of
                    qualified experts.
                  </p>
                </div>
                <div className="mi_right">
                  <h5 style={{ color: "white !important" }}>Variety of Subjects</h5>
                  <p className="theme_description" style={{ color: "white !important" }}>
                    PreSchool, Nursery, Kindergarten. Algebra, Calculus, and
                    Statistics to English, Chemistry, and Physics. You name it,
                    we list them.
                  </p>
                </div>
              </div>
              <div className="theme_btn btn_fill with_icon" style={{ color: "white !important" }}>
                More About Us
                <span className="material-symbols-outlined">arrow_right_alt</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationTwoAboutUs;

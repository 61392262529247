import React, { useState, useRef } from "react";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import {
  projectStorage,
  timestamp,
  projectFirestore,
} from "../firebase config/config";
import "./AddMedia.css";

const AddMedia = ({
  category,
  pageName,
  section,
  addMoreImagesButton = true,
  addMoreVideosButton = true,
  addMoreTextContentButton = true,
}) => {
  const { user } = useAuthContext();
  const { addDocument, response } = useFirestore("media");

  const fileInputRef = useRef(null);
  const [newMediaUrl, setNewMediaUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [newTextContent, setNewTextContent] = useState("");
  const [newHeadingTextContent, setNewHeadingTextContent] = useState("");
  // console.log("type", selectedImage.type);
  const handleAddMoreImages = () => {
    // Trigger file input click using ref
    fileInputRef.current.click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    // setUploadedImage(true); // Show file input after selecting an image
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true); // Set uploading status to true

      // Determine the media type based on the selected file
      const mediaType = selectedImage.type.startsWith("image/")
        ? "image"
        : "video";
      // Create a new media document in the "media" collection
      const newMediaRef = await projectFirestore.collection("media").add({
        pageName: pageName,
        mediaType: mediaType,
        category: category,
        section: section,
        status: "active",
        createdAt: timestamp.fromDate(new Date()),
      });

      // Perform actions with the selected media, e.g., store in local storage
      const reader = new FileReader();
      reader.onloadend = () => {
        const mediaData = reader.result;
        // Store mediaData in local storage or handle as needed
        localStorage.setItem("mediaData", mediaData);
      };
      reader.readAsDataURL(selectedImage);

      // Upload the media to the appropriate folder in Firebase Storage
      if (selectedImage || newMediaUrl.trim()) {
        // Get the ID of the newly created document
        const newMediaId = newMediaRef.id;

        // Determine the folder name based on the media type
        const storageRef = projectStorage.ref(`${mediaType}s/${newMediaId}`);

        // Upload the media to the created folder in Firebase Storage
        const mediaRef = storageRef.child(
          selectedImage ? selectedImage.name : newMediaUrl
        );
        if (selectedImage) {
          await mediaRef.put(selectedImage);
        } else {
          // If it's a video, you may need to handle the upload differently
          // Here, you can upload a video by URL or implement your video upload logic
        }

        // Get the URL of the uploaded media
        const mediaUrl = await mediaRef.getDownloadURL();

        // Update the document with the media URL
        await newMediaRef.update({
          mediaUrl: mediaUrl,
        });
        setSelectedImage(null);
        setNewMediaUrl(""); // Reset video URL input
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    } finally {
      setIsUploading(false); // Reset uploading status
    }
  };

  // Add video in firesbase
  const handleAddMedia = async () => {
    // Check if the input is empty
    if (!newMediaUrl.trim()) {
      // Display an error message or handle empty input
      alert("Please fill video url");
      return;
    }

    const newMedia = {
      pageName: pageName,
      mediaType: "video",
      mediaUrl: newMediaUrl,
      category: category,
      section: section,
      status: "active",
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newMedia);

    if (!response.error) {
      // Clear the input field after adding the video
      setNewMediaUrl("");
    }
  };

  // Add text content in firesbase
  const handleAddTextContent = async () => {
    // Check if the input is empty
    if (!newTextContent.trim() || !newHeadingTextContent.trim()) {
      // Display an error message or handle empty input
      alert("Please fill your text");
      return;
    }

    const newMedia = {
      pageName: pageName,
      mediaType: "text",
      heading: newHeadingTextContent,
      longDescription: newTextContent,
      category: category,
      section: section,
      status: "active",
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(newMedia);

    if (!response.error) {
      // Clear the input field after adding the video
      setNewTextContent("");
      setNewHeadingTextContent("");
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="container">
        <div className="row">
          {/* start add video */}
          {addMoreVideosButton && user && user.role === "admin" && (
            <div>
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  placeholder="Paste your youtube video URL"
                  className="styled-input"
                  value={newMediaUrl}
                  onChange={(e) => setNewMediaUrl(e.target.value)}
                ></input>
                <button className="btn_fill" onClick={handleAddMedia}>
                  Add Video
                </button>
              </div>
            </div>
          )}
          {/* end add video */}

          {/* start add images */}
          {addMoreImagesButton && user && user.role === "admin" && (
            <div className="d-flex justify-content-center">
              {selectedImage ? (
                <>
                  <div className="image-preview">
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected offer image"
                      style={{
                        width: "100px",
                        borderRadius: "10px 0px 0px 10px",
                      }}
                    />
                  </div>
                  <button
                    className="btn_fill"
                    onClick={handleUpload}
                    disabled={isUploading} // Disable the button when uploading
                  >
                    {isUploading ? "Uploading..." : "Confirm"}
                  </button>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/*, video/*" // Accept both image and video files
                    id="imageInput"
                    multiple
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <button className="btn_fill" onClick={handleAddMoreImages}>
                    Add {category} Images
                  </button>
                </>
              )}
            </div>
          )}
          {/* end add images */}

          {/* start add text content */}
          {addMoreTextContentButton && user && user.role === "admin" && (
            <div
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                border: "1px solid #c4c4c4",
              }}
            >
              <div className="faq_input_parent">
                <input
                  type="text"
                  placeholder="Write Heading"
                  className="styled-input-text"
                  value={newHeadingTextContent}
                  required
                  onChange={(e) => setNewHeadingTextContent(e.target.value)}
                ></input>
                <input
                  type="text"
                  placeholder="Write Description"
                  className="styled-input-text"
                  value={newTextContent}
                  required
                  onChange={(e) => setNewTextContent(e.target.value)}
                ></input>
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn_fill" onClick={handleAddTextContent}>
                  Add {category}
                </button>
              </div>
            </div>
          )}
          {/* end add text content */}
        </div>
      </div>
    </div>
  );
};

export default AddMedia;

import React from 'react'
import './CorporateTwoPolicyHeader.scss'

const CorporateTwoPolicyHeader = () => {
  return (
    <div className='corporateTwo-policy-header'>
        <h1>Privacy Policies</h1>
    </div>
  )
}

export default CorporateTwoPolicyHeader
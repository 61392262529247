import React, { useState, useEffect } from 'react';
import useLocalStorage from './useLocalStorage'; // Import the local storage hook

const useWishlist = () => {
    const { data: wishlistItems, addItem, updateItem, deleteItem } = useLocalStorage('wishlist'); // Use local storage for wishlist

    const addToWishlist = (productId) => {
        addItem({ productId }); // Add item to local storage
    };

    const removeFromWishlist = (productId) => {
        const index = wishlistItems?.findIndex(item => item.productId === productId);
        if (index !== -1) {
            deleteItem(index); // Remove item from local storage
        }
    };

    return { wishlistItems, addToWishlist, removeFromWishlist };
};

export default useWishlist;
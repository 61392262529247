import React,{useEffect,useRef} from "react";
import "./CorporateTwoBlogs.scss";

const blogsData = [
  {
    id: 1,
    publishedDate: "15 Aug 2024",
    title: "How we can keep our clothes fresh for longer",
    blogImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fblog3.webp?alt=media&token=c117fecc-e3a6-47de-89b3-3c47129d5f06",
    tags: ["laundry", "drycleaning"],
  },
  {
    id: 2,
    publishedDate: "12 May 2024",
    title: "Best options of cleaning solutions in 2024",
    blogImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fblog2.webp?alt=media&token=21a666b9-7d14-452e-a6a3-372b7d0d2976",
    tags: ["tips", "laundry"],
  },
  {
    id: 3,
    publishedDate: "14 March 2024",
    title: "How to wash delicate fabric for better results",
    blogImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fblog1.webp?alt=media&token=92889cc0-6b8b-41e6-bf1f-3122bc18e72e",
    tags: ["DIY", "drycleaning"],
  },
];

const CorporateTwoBlogs = () => {
  // const sectionRef = useRef(null)
  // const headingRef = useRef(null)
  // const containerRef = useRef(null)

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add('in-view');
  //           headingRef.current.classList.add('animate-heading')
  //           containerRef.current.classList.add('animate-container')
  //         } else {
  //           // entry.target.classList.remove('in-view');
  //           // headingRef.current.classList.remove('animate-heading')
  //           // containerRef.current.classList.remove('animate-container')
  //         }
  //       });
  //     },
  //     {
  //       threshold: 0.5,
  //     }
  //   );

  //   const section = sectionRef.current;
  //   observer.observe(section);

  //   return () => {
  //     observer.unobserve(section);
  //   };
  // }, []);

  return (
    <div className="corporateTwo-home-blogs" >
      <div className="corporateTwo-home-blogs-heading">
        <h1>Whats Happening</h1>
        <h2>Blogs & News</h2>
      </div>

      <div className="corporateTwo-home-blogs-container">
        {blogsData.map((blogs, index) => {
          return (
            <div className="corporateTwo-home-blog" key={blogs.id}>
              <div className="corporateTwo-home-blog-image-container">
                <img src={blogs.blogImage} alt="" />
              </div>

              <div className="corporateTwo-home-blog-info">
                <h4>{blogs.publishedDate}</h4>
                <h2>{blogs.title}</h2>
                <div className="corporateTwo-home-blog-tags">
                  {blogs.tags.map((tag, index) => {
                    return (
                    <div className="corporateTwo-home-blog-tag">
                      <p>{tag}</p>
                    </div>
                    )
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CorporateTwoBlogs;

import React, { useState, useEffect, useRef } from "react";
import "./BusinessThreeTeam.scss";

const teamMembers = [
  {
    id: 1,
    name: "Atul Tripathi",
    role: "Pune",
    imgSrc: "assets/img/atul.jpg",
  },
  {
    id: 2,
    name: "Jalaj Pathak",
    role: "Delhi",
    imgSrc: "assets/img/jalaj.jpeg",
  },
  {
    id: 3,
    name: "Chaitali Yadav",
    role: "Kolhapur",
    imgSrc: "assets/img/chaitali.jpeg",
  },
  {
    id: 4,
    name: "Sanskar Solanki",
    role: "Ujjain",
    imgSrc: "https://hrcenter.com.gt/wp-content/uploads/2019/09/coach1.jpg",
  },
  {
    id: 5,
    name: "Ayushi Saxena",
    role: "Guna",

    imgSrc:
      "https://th.bing.com/th/id/OIP.K86XD8erZ9qA1dDbuRGsDwHaHu?w=1238&h=1291&rs=1&pid=ImgDetMain",
  },

  {
    id: 6,
    name: "Naman Gaur",
    role: "Gwalior",
    imgSrc:
      "https://zoneweymouth.com/wp-content/uploads/2018/01/quote-2-zone-weymouth-600x600.jpeg",
  },
  {
    id: 7,
    name: "Tanu Shrivastava",
    role: "Bhopal",
    imgSrc:
      "https://sm.askmen.com/t/askmen_in/article/f/facebook-p/facebook-profile-picture-affects-chances-of-gettin_fr3n.1200.jpg",
  },
  {
    id: 8,
    name: "Manisha Sood",
    role: "Pune",
    imgSrc: "assets/img/manisha.jpg",
  },
  {
    id: 9,
    name: "Sugandha Sahoo",
    role: "Jharkhand",
    imgSrc: "assets/img/Sungandha.jpg",
  },
];

const BusinessThreeTeam = ({ id }) => {
  const [centerMember, setCenterMember] = useState(teamMembers[0]);
  const headingRef = useRef(null);

  const handleMemberClick = (member) => {
    // const newTeamMembers = teamMembers.map((tm) =>
    //   tm.id === member.id
    //     ? centerMember
    //     : tm.id === centerMember.id
    //     ? member
    //     : tm
    // );
    setCenterMember(member);
  };

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING
  return (
    <div className="business-team-three-team-section" id={id}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="container business-three-box-container">
              <div ref={headingRef} className="business-three-heading-div">
                <h3 style={{ color: "white" }}>
                  Meet the Talents Behind <span>Our Success</span>
                </h3>
              </div>
              <div className="business-three-template-view-more-icon">
                <span class="material-symbols-outlined">arrow_forward_ios</span>{" "}
              </div>
            </div>

            <p className="business-team-three-team-description">
              <span>Our Team</span> consists of diverse and talented individuals
              committed to delivering excellence. With expertise ranging from
              design to development, management to support, our members work
              collaboratively to achieve our goals. Each team member brings
              unique skills and perspectives, making us stronger and more
              innovative. Whether it's brainstorming new ideas, solving complex
              problems, or providing top-notch customer service, our team is
              dedicated to making a positive impact. We believe in continuous
              learning and growth, fostering a culture where everyone can
              thrive. Meet our passionate team and discover how we can help you
              succeed.
            </p>
            <br />
            <button className="btn_fill">Meet All</button>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div className="business-team-three-circle">
              {teamMembers
                .filter((member) => member.id !== centerMember.id)
                .map((member) => (
                  <div
                    key={member.id}
                    className="business-team-three-team-member"
                    onClick={() => handleMemberClick(member)}
                  >
                    <img src={member.imgSrc} alt={member.name} />
                  </div>
                ))}
              {/* Central Image */}
              <div className="business-team-three-center-member">
                <img src={centerMember.imgSrc} alt={centerMember.name} />
                <div className="business-team-three-member-info">
                  <h4>{centerMember.name}</h4>
                  <p>{centerMember.role}</p>
                </div>
              </div>
              <div className="business-team-three-small-box"></div>
              <div className="business-team-three-biggest-box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessThreeTeam;

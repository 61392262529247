import React, { useEffect, useRef, useState } from "react";
import "./BusinessThreeServices.scss";

const BusinessThreeServices = ({ id }) => {
  const headingRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [slideClass, setSlideClass] = useState("slide-in");

  const icons = ["iron", "engineering", "apparel", "styler", "lock"];

  const services = [
    "Industrial Laundry",
    "Linen Rental",
    "Workwear Rental",
    "Logs & Embroideries",
    "Locker Valet",
  ];

  const serviceContents = [
    {
      heading: "Efficient Industrial Laundry Solutions for All Your Needs",
      description:
        "Industrial laundry services provide large-scale cleaning solutions for businesses, hospitals, hotels, and other institutions. They specialize in handling high volumes of linens, uniforms, and textiles, ensuring thorough cleaning, disinfection, and efficient turnaround times.",
      image:
        "https://images.pexels.com/photos/4700383/pexels-photo-4700383.jpeg?cs=srgb&dl=pexels-ekaterina-belinskaya-4700383.jpg&fm=jpg",
      alt: "Industrial Laundry",
    },
    {
      heading: "Premium Linen Rental Services for Any Occasion",
      description:
        "Linen rental services offer businesses a convenient solution for obtaining high-quality linens without the hassle of ownership. Ideal for hotels, restaurants, and events, these services provide fresh, clean linens on a regular basis.",
      image:
        "https://th.bing.com/th/id/R.9978de661f01a045fba23683f9c95915?rik=DJzJYd0RZ6yn3g&riu=http%3a%2f%2fwww.bespokeweddingsspain.com%2fwp-content%2fuploads%2f2017%2f05%2fDSC_7177.jpg&ehk=fEkYwDDcbsM2TDhTysdcx204NRk5ku8z64Z30HzppNc%3d&risl=&pid=ImgRaw&r=0",
      alt: "Linen Rental",
    },
    {
      heading: "Top-Quality Workwear Rental for Professional Environments",
      description:
        "Workwear rental services provide businesses with durable, professional clothing for their employees without the need for purchasing and maintaining uniforms. This ensures clean, well-fitted attire.",
      image:
        "https://www.localsyr.com/wp-content/uploads/sites/63/2020/04/Marquardt_Employee_Working.jpg?w=900",
      alt: "Workwear Rental",
    },
    {
      heading: "Custom Logs and Embroideries for Unique Branding",
      description:
        "Logs & embroideries services offer customized branding solutions, including embroidered logos and designs on uniforms and promotional items. This enhances brand visibility and professionalism.",
      image:
        "https://th.bing.com/th/id/R.d499379b53a426b4b0da397885242516?rik=cWvtF0PGJFwo2A&riu=http%3a%2f%2fyesofcorsa.com%2fwp-content%2fuploads%2f2017%2f06%2fEmbroidery-Wallpaper-Download.jpg&ehk=WlJLkBtjwl3FD%2fsmzqsLbxeIIvkoS6%2f3cvMNcgP%2b6bM%3d&risl=1&pid=ImgRaw&r=0",
      alt: "Logs & Embroideries",
    },
    {
      heading: "Secure and Convenient Locker Valet Services for Your Items",
      description:
        "Locker valet services provide convenient, secure storage solutions for personal belongings with features like digital locks and 24/7 access, enhancing security and convenience.",
      image:
        "https://media.istockphoto.com/id/1125804306/photo/cheerful-latin-american-blue-collar-worker-getting-ready-to-work-at-the-factory-smiling.jpg?s=612x612&w=0&k=20&c=Euj5VJ1Hnt57bF_r6gZO6eE-Z2KXIzNQCZ5SwnUYByk=",
      alt: "Locker Valet",
    },
  ];

  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setSlideClass("slide-out");
      setTimeout(() => {
        setActiveTab(index);
        setSlideClass("slide-in");
      }, 500); // Matches the duration of the transition
    }
  };

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING

  return (
    <>
      <div className="large_padding_div" id={id}></div>
      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
          <h3>
            <span>Laundry Services</span> designed for your needs
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span className="material-symbols-outlined">arrow_forward_ios</span>{" "}
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between business-three-services-top-selector">
          {services.map((service, index) => (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`business-three-service-tab ${
                activeTab === index ? "active" : ""
              }`}
            >
              <span className="material-symbols-outlined">{icons[index]}</span>
              &nbsp;
              {service}
            </div>
          ))}
        </div>
        <br />
        <div className="business-three-service-selected-service">
          <div
            className={`business-three-service-selected-service-content ${slideClass}`}
          >
            <div className="row d-flex align-items-center">
              <div className="col-12 col-md-6 ">
                <h4 style={{ color: "#4b4b4b" }}>
                  {serviceContents[activeTab].heading}
                </h4>
                <br />
                <p>{serviceContents[activeTab].description}</p>
                <br />
                <div>
                  <button className="btn_border">{services[activeTab]}</button>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-end">
                <img
                  src={serviceContents[activeTab].image}
                  alt={serviceContents[activeTab].alt}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="business-three-dots-container">
          {services.map((_, index) => (
            <div
              key={index}
              className={`business-three-dot ${
                activeTab === index ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
      </div>
      <div className="large_padding_div"></div>
    </>
  );
};

export default BusinessThreeServices;

import React from "react";

// import components
import EducationOneBanner from "../educationOneComponents/EducationOneBanner";
import EducationOneServices from "../educationOneComponents/EducationOneServices";
import EducationOneTestimonials from "../educationOneComponents/EducationOneTestimonials";
import EducationOneWork from "../educationOneComponents/EducationOneWork"
import EducationOneTutorjobs from "../educationOneComponents/EducationOneTutorjobs";
import EducationOneFooter from "../educationOneComponents/EducationOneFooter";
import EducationOneStudents from "../educationOneComponents/EducationOneStudents";
import EducationOneContact from "../educationOneComponents/EducationOneContact";
import EducationOneTestimonialsTwo from"../educationOneComponents/EducationOneTestimonialsTwo";
import EducationOneBannerTwo from "../educationOneComponents/EducationOneBannerTwo";
import EducationOneSuccess from"../educationOneComponents/EducationOneSuccess";
import EducationOneBannerThree from "../educationOneComponents/EducationOneBannerThree";
import EducationOneSupport from "../educationOneComponents/EducationOneSupport";



const EducationOneHome = () => {
  return (
    <div >
      < EducationOneBannerTwo/>
      <EducationOneSupport/>
      <EducationOneServices/>

      <EducationOneContact/>
      <EducationOneTestimonialsTwo/>
      <EducationOneSuccess/>
      <EducationOneWork/>




     



      
      
     
    </div>
  );
};

export default EducationOneHome;

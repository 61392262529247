import React from "react";
import { useCollection } from "../firebase files/hooks/useCollection";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import OwlCarousel from "react-owl-carousel";
import "./TopSellingProducts.scss"; // Ensure your custom styles are correctly imported

const TopSellingProducts = () => {
  const {
    documents: dbTopSellingProductsDocuments,
    error: dbTopSellingProductsError,
  } = useCollection("products", ["topSellingProducts", "==", true]);

  return (
    <div>
      {dbTopSellingProductsError && (
        <p>Error loading top-selling products: {dbTopSellingProductsError}</p>
      )}
      <div className="top-selling-products-container">
        <div className="left-sidebar">
          <div className="rotated-text">Top Selling Products</div>
        </div>
        <div className="top-selling-products">
          {dbTopSellingProductsDocuments &&
          dbTopSellingProductsDocuments.length > 0 ? (
            <div className="owl-carousel-wrapper">
              <OwlCarousel
                className="owl-theme"
                items={1}
                loop
                dots={false}
                nav={false}
                autoplay={true} // Enable autoplay
                autoplayTimeout={8000}
                smartSpeed={4000}
                responsive={{
                  0: {
                    items: 1,
                  },
                  650: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                  1300: {
                    items: 4,
                  },
                }}
              >
                {dbTopSellingProductsDocuments.map((product, index) => (
                  <Link to={`/product-details/${product.id}`} key={index}>
                    <div className="top-selling-product-item">
                      <img
                        src={product.images[0]}
                        alt={product.name}
                        className="top-selling-product-image"
                      />
                      <div className="top-selling-product-details">
                        <p
                          className="top-selling-product-name"
                          dangerouslySetInnerHTML={{
                            __html: product.name.toString("html"),
                          }}
                        ></p>
                        <p className="top-selling-product-rating">
                          Rating: {product.rating}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </OwlCarousel>
            </div>
          ) : (
            <p>No top-selling products available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopSellingProducts;

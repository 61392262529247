import React from "react";
import { Route, Routes } from "react-router-dom";

// import firebase
import { useDocument } from "../../../../firebase files/hooks/useDocument";

// import css 
import '../educationTwoCss/EducationTwoColor.scss'
import '../educationTwoCss/EducationTwoFont.scss'
import '../educationTwoCss/EducationTwoStyle.scss'

// import pages
import EducationTwoHome from "../educationTwoPages/EducationTwoHome";
import CorporateTwoTerms from "../../../corporate/corporateTwo/corporateTwoPages/CorporateTwoTerms";
import CorporateTwoPolicy from "../../../corporate/corporateTwo/corporateTwoPages/CorporateTwoPolicy";
import PGCorporateTwoAbout from "../../../corporate/corporateTwo/corporateTwoPages/PGCorporateTwoAbout";
import PGBusinessThreeProfile from "../../../business/businessThree/businessThreePages/PGBusinessThreeProfile";

// import components
import Navbar from "../../../../default components/Navbar";
import BusinessThreeNavbar from "../../../business/businessThree/businessThreeComponents/BusinessThreeNavbar";
import GlobalRoute from "../../../../global route/GlobalRoute";
import CorporateTwoFooter from "../../../corporate/corporateTwo/corporateTwoComponents/CorporateTwoFooter/CorporateTwoFooter";

const EducationTwoRoute = () => { 

  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  return (
    <div className="education_two"> 
     {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <Navbar />
      ) : (
        <BusinessThreeNavbar />
      )}

      <Routes>
        <Route path="/" element={<EducationTwoHome/>}></Route>   
        <Route path="/about" element={<PGCorporateTwoAbout />} />
        <Route path="/term-condition" element={<CorporateTwoTerms />} />
        <Route path="/privacy-policy" element={<CorporateTwoPolicy />} />
        <Route
          path="/profile"
          element={<PGBusinessThreeProfile />}
        />     
      </Routes>  
      <GlobalRoute />  
      <CorporateTwoFooter />    
    </div>
  )
}

export default EducationTwoRoute;































// this template is taken from 
// https://preview.themeforest.net/item/educate-university-online-courses-school-education-template/full_screen_preview/49606280?_ga=2.226409818.1736213968.1726109803-1924452171.1721566110



import React from "react";
import "./EventOneFooter.scss";

const Footer = () => {
  return (
    <div className="Footer-events">
      <div className="container">
        <div className="Footer_inner">
          <div className="footer_header">
            <div className="left">
                
                    <h2 className="title_footer">Paytm</h2>
                    <p className="para_card_para">Insider</p>
                    {/* <h6 className="sub_title">Explore. Discover. Make a Plan</h6> */}
                  </div>

            <div className="right">
                <img
                src="./assets/img/ecommerce/ecomTwo/icons/bookmark.png"
                alt=""
                className="icon"
              />
            </div>
              </div>
              
              <div className="footer_define">
                <p className="def">Paytm Insider is a platform that helps you discover and buy the best in events, travel and food in your city. We strive to curate experiences that are worth your time and money, possibly something you have never tried before.</p>
            </div>
            <div className="second_inner">
                <div className="title">For Event Organizer</div>
                <div className="footer_define">
                    <p className="def">Paytm Insider is a platform that helps you discover and buy the best in events, travel and food in your city. We strive to curate experiences that are worth your time and money, possibly something you have never tried before.</p>
                </div>
            </div>
            <div className="btn_footer">
                <button className="btn_item">About Us</button>
                <button className="btn_item">Blog</button>
                <button className="btn_item">Careers</button>
                <button className="btn_item"> Privacy Policy </button>
                <button className="btn_item"> Terms And Condition </button>
                <button className="btn_item">List with Us </button>
                <button className="btn_item">Contact Us</button>
                <button className="btn_item">FAQs</button>
            </div>
        
            </div>
        </div>
        </div>
  );
        };
          export  default Footer
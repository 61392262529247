import React from "react"
import "./EventOneBanner.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Feature = () => {
  const options = {
    items: 3,
    dots: true,
    loop: true,
    margin: 30,
    nav: false,
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 3000,
    center:true,
    responsive: {
      // Define breakpoints and the number of items to show at each breakpoint
      0: {
        items: 1,
        nav: false,
        

      },
      320:{
        items:1,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 3,
      },
      
      1199: {
        items: 3,
      },
    },
  };
  return (
    <div className="banner-events">
      <div className="banner_inner">
        <div className="event-container">
          <OwlCarousel className="owl-theme my_owl_carousel" {...options}>
            <div className="item event-card ">
              <div className="image-container">
                <img
                  src="./assets/img/ecommerce/ecomTwo/banner1.jpg"
                  className="event-image"
                />
              </div>

            </div>
              <div className="item event-card ">
                <div className="image-container">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/banner2.jpg"
                    className="event-image"
                  />
                </div>
            </div>
                <div className="item event-card ">
                  <div className="image-container">
                    <img
                      src="./assets/img/ecommerce/ecomTwo/banner3.jpg"
                      className="event-image"
                    />
                  </div>
                </div>


                <div className="item event-card ">
                  <div className="image-container">
                    <img
                      src="./assets/img/ecommerce/ecomTwo/banner4.webp"
                      className="event-image"
                    />
                  </div>
                </div>
                  <div className="item event-card ">
                    <div className="image-container">
                      <img
                        src="./assets/img/ecommerce/ecomTwo/banner2.jpg"
                        className="event-image"
                      />
                    </div>
                </div>
                    <div className="item event-card ">
                      <div className="image-container">
                        <img
                          src="./assets/img/ecommerce/ecomTwo/banner1.jpg"
                          className="event-image"
                        />
                      </div>
                    </div>
                  
              
            
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Feature;

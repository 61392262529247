import React, { useState, useEffect } from "react";
import "./Cart2.scss";
import { useNavigate } from "react-router-dom";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useDocument } from "../firebase files/hooks/useDocument";
import useCart from "../firebase files/hooks/useCart";
import Skeleton from "react-loading-skeleton";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { projectFirestore } from "../firebase config/config";
import Popup from "./Popup";

const Cart2 = () => {
  const navigate = useNavigate()
  const { documents: cartDocuments, error: cartErrors } = useCollection("cart");
  const { documents: productDocuments, error: productErrors } =
    useCollection("products");
  const { user } = useAuthContext();
  console.log(user);

  const { updateDocument, response } = useFirestore("user");

  const { document: currencyDocument, error: currencyError } = useDocument(
    "settings",
    "currency"
  );

  const defaultAddress = user.addresses.find((address) => {
    return address.isDefault;
  });

  console.log(defaultAddress);

  const [isLoading, setIsLoading] = useState(true);

  // const [isModalOpen, setIsModalOpen] = useState(false)

  // const [selectedAddress, setSelectedAddress] = useState(null)

  // const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  // const [editAddress,setEditAddress] = useState(null)

  // const handelModal = () => {
  //     setIsModalOpen(!isModalOpen)
  // }

  // const handleEditModal = (address) => {
  //     setEditAddress(address)
  //     setIsEditModalOpen(!isEditModalOpen)

  //}

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(defaultAddress);
  const [tempSelectedAddress, setTempSelectedAddress] = useState(null);

  const handleAddressModal = () => {
    setIsAddressModalOpen(true);
    setTempSelectedAddress(selectedAddress);
  };

  const handleAddressSelection = (address) => {
    setTempSelectedAddress(address);
  };

  const handleSaveAddress = () => {
    setSelectedAddress(tempSelectedAddress);
    setIsAddressModalOpen(false);
  };

  // const [newAddress, setNewAddress] = useState({
  //     houseNumber: "",
  //     streetLineOne: "",
  //     streetLineTwo: "",
  //     landmark: "",
  //     isPrimary: false
  // })

  // const handleSaveAddress = async (e) => {
  //     e.preventDefault();
  //     if (newAddress.houseNumber && newAddress.streetLineOne && newAddress.streetLineTwo && newAddress.landmark) {
  //         await projectFirestore
  //             .collection("users")
  //             .doc(user.uid)
  //             .update({ addresses: [...user.addresses, newAddress] });
  //         setIsModalOpen(false)
  //     } else {
  //         alert("Please fill all the fields")
  //     }
  // };

  // const handleUpdateAddress = async (e) => {
  //     e.preventDefault();
  //     if (editAddress.houseNumber && editAddress.streetLineOne && editAddress.streetLineTwo && editAddress.landmark) {
  //         const updatedAddresses = user.addresses.map(addr =>
  //             addr === selectedAddress ? editAddress : addr
  //         );
  //         await projectFirestore
  //             .collection("users")
  //             .doc(user.uid)
  //             .update({ addresses: updatedAddresses });
  //         setIsEditModalOpen(false);
  //         setEditAddress(null);
  //     } else {
  //         alert("Please fill all the fields");
  //     }
  // };

  useEffect(() => {
    if (currencyDocument && productDocuments) {
      setIsLoading(false);
    }
  }, [currencyDocument, productDocuments]);

  const { cartItems, updateCartItemQuantity, removeFromCart } = useCart();

  const getProduct = (productId) => {
    return (
      productDocuments &&
      productDocuments.find((product) => product.id === productId)
    );
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleRemoveFromCart = (cartItemId) => {
    setItemToDelete(cartItemId);
    setPopupMessage("Do you want to remove this item from cart?");
    setShowPopup(true);
  };

  const handlePopupConfirm = (confirmed) => {
    if (confirmed && itemToDelete) {
      removeFromCart(itemToDelete);
    }
    setShowPopup(false);
    setItemToDelete(null);
  };

  const handleQuantityChange = (cartItem, newQuantity) => {
    if (newQuantity > 0) {
      updateCartItemQuantity(cartItem.id, newQuantity);
    } else {
      handleRemoveFromCart(cartItem.id);
    }
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh", padding: "10px" }}>
        <Skeleton count={5} height={100} className="mb-4" />
      </div>
    );
  }

  return (
    <div className="cart2-page">
      {isAddressModalOpen ? (
        <div className="address-list-modal-overlay">
          <div className="address-list-modal">
            <div className="address-list-modal-header">
              <h2>Select Address</h2>
              <span
                className="material-symbols-outlined"
                onClick={() => setIsAddressModalOpen(false)}
              >
                close
              </span>
            </div>

            <div className="address-list-modal-body">
              {user &&
                user.addresses.map((address, index) => {
                  return (
                    <div
                      key={index}
                      className={`address-list-modal-body-single-address ${
                        tempSelectedAddress === address
                          ? "selected-address"
                          : ""
                      }`}
                      onClick={() => handleAddressSelection(address)}
                    >
                      <div className="address-list-modal-body-single-address-type">
                        <span className="material-symbols-outlined">
                          {address.type === "home"
                            ? "home"
                            : address.type === "work"
                            ? "work"
                            : "place"}
                        </span>
                        <span>
                          {address.type.charAt(0).toUpperCase() +
                            address.type.slice(1)}
                        </span>
                      </div>

                      <div className="address-list-modal-body-single-address-details">
                        <h4>{address.name}</h4>
                        <p>
                          {address.houseNumber} , {address.streetLineOne},{" "}
                          {address.streetLineTwo}, {address.landmark} ,{" "}
                          {address.city}, {address.pincode}, {address.state},{" "}
                          {address.country}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="address-list-modal-footer">
              <button onClick={() => setIsAddressModalOpen(false)}>
                Cancel
              </button>
              <button onClick={handleSaveAddress}>Save</button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* {
                isModalOpen
                    ?
                    (
                        <div className='cart-address-modal'>
                            <div className='cart-new-address-form'>
                                <h4>Add new Address</h4>

                                <form onSubmit={handleSaveAddress}>
                                    <input type='text' value={newAddress.houseNumber} onChange={(e) => setNewAddress({ ...newAddress, houseNumber: e.target.value })} placeholder='Flat No./House No.' />
                                    <input type='text' value={newAddress.streetLineOne} onChange={(e) => setNewAddress({ ...newAddress, streetLineOne: e.target.value })} placeholder='Street Line 1' />
                                    <input type='text' value={newAddress.streetLineTwo} onChange={(e) => setNewAddress({ ...newAddress, streetLineTwo: e.target.value })} placeholder='Street Line 2' />
                                    <input type='text' value={newAddress.landmark} onChange={(e) => setNewAddress({ ...newAddress, landmark: e.target.value })} placeholder='Near By Landmark' />
                                    <div className='cart-new-address-form-btn-container'>
                                        <button className='btn-new-address-form cancel-address-form-btn' onClick={handelModal}>Cancel</button>
                                        <button className='btn-new-address-form save-address-form-btn'>Save</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    )
                    :
                    ""
            }

            {
                isEditModalOpen
                ?
                (
                    <div className='cart-address-modal'>
                    <div className='cart-new-address-form'>
                        <h4>Update Address</h4>

                        <form onSubmit={handleUpdateAddress}>
                            <input type='text' value={editAddress.houseNumber} onChange={(e) => setEditAddress({ ...editAddress, houseNumber: e.target.value })} placeholder='Flat No./House No.' />
                            <input type='text' value={editAddress.streetLineOne} onChange={(e) => setEditAddress({ ...editAddress, streetLineOne: e.target.value })} placeholder='Street Line 1' />
                            <input type='text' value={editAddress.streetLineTwo} onChange={(e) => setEditAddress({ ...editAddress, streetLineTwo: e.target.value })} placeholder='Street Line 2' />
                            <input type='text' value={editAddress.landmark} onChange={(e) => setEditAddress({ ...editAddress, landmark: e.target.value })} placeholder='Near By Landmark' />
                            <div className='cart-new-address-form-btn-container'>
                                <button className='btn-new-address-form cancel-address-form-btn' onClick={()=>setIsEditModalOpen(!isEditModalOpen)}>Cancel</button>
                                <button className='btn-new-address-form save-address-form-btn'>Save</button>
                            </div>
                        </form>

                    </div>
                </div>
                )
                :
                ""
            } */}

      <div className="cart2-info">
        <div className="cart2-details">
          <div className="cart2-header">
            <h2>Cart</h2>
            <h2>{cartItems.length} Items</h2>
          </div>
          <div className="cart2-items">
            <div className="cart2-item-header">
              <span className="cart2-item-product">Product</span>
              <span className="cart2-item-price">Price</span>
              <span className="cart2-item-quantity">Quantity</span>
              <span className="cart2-item-total">Total</span>
            </div>
            {cartItems &&
              cartItems.map((item) => {
                const product = getProduct(item.productId);
                return (
                  <div className="cart2-item" key={item.id}>
                    <div className="cart2-item-product">
                      <img src={product.images[0]} alt={product.name} />
                      <div className="cart2-item-detail">
                        <h3>{product.name}</h3>
                        {(item.color || item.size || item.variantName) && (
                          <div className="cart-2-item-variant-info">
                            <div className="cart-2-item-variant-info-name">
                              <span>{item.variantName}</span>
                            </div>

                            <div>
                              <div className="cart-2-item-variant-info-color">
                                <span>Color:</span>
                                <div
                                  style={{
                                    backgroundColor: `${item.color.color}`,
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    border: "1px solid black",
                                  }}
                                ></div>
                              </div>
                              <div className="cart-2-item-variant-info-size">
                                {item.size && <span>Size: {item.size}</span>}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="cart2-item-misc-functionality">
                          <div className="cart2-item-sfl">
                            <span className="material-symbols-outlined">
                              bookmark
                            </span>
                            <span>Wishlist</span>
                          </div>
                          <div
                            className="cart2-item-remove"
                            onClick={() => handleRemoveFromCart(item.id)}
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                            <span>Remove</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cart2-item-price">
                      <h5>
                        {currencyDocument.symbol}
                        {item.discountedPrice
                          ? item.discountedPrice
                          : product.price}
                      </h5>
                    </div>
                    <div className="cart2-item-quantityhandler">
                      <button
                        onClick={() =>
                          handleQuantityChange(item, item.quantity - 1)
                        }
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() =>
                          handleQuantityChange(item, item.quantity + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                    <div className="cart2-item-itemTotal">
                      <h5>
                        {currencyDocument.symbol}
                        {(
                          (item.discountedPrice
                            ? item.discountedPrice
                            : product.price) * item.quantity
                        ).toFixed(2)}
                      </h5>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="cart2-summary-secondary">
        <div className="cart2-address-details">
          <div className="cart2-address-header">
            <h2>Shipping Address</h2>

            {/* <button onClick={handelModal}>+</button> */}
            <button onClick={handleAddressModal}>Select Other Address</button>
          </div>

          <div className="cart2-address-list">
            <div className="cart2-single-address">
              <div className="cart2-single-address-type">
                <span className="material-symbols-outlined">
                  {selectedAddress.type === "home"
                    ? "home"
                    : selectedAddress.type === "work"
                    ? "work"
                    : "place"}
                </span>

                <span>
                  {selectedAddress.type.charAt(0).toUpperCase() +
                    selectedAddress.type.slice(1)}
                </span>
              </div>

              <div className="cart2-single-address-details">
                <div className="cart2-single-address-info">
                  <h4>{selectedAddress.name}</h4>
                  <p>
                    {selectedAddress.houseNumber} ,{" "}
                    {selectedAddress.streetLineOne},{" "}
                    {selectedAddress.streetLineTwo}, {selectedAddress.landmark}{" "}
                    , {selectedAddress.city}, {selectedAddress.pincode},{" "}
                    {selectedAddress.state}, {selectedAddress.country}
                  </p>
                </div>

                {/* <span className='material-symbols-outlined'>
                            edit
                        </span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="cart2-summary">
          <h2>Cart Summary</h2>

          <div className="cart2-summary-details">
            <div className="cart2-summary-order-total">
              <h5>Cart Total</h5>
              <h5>5000</h5>
            </div>

            <div className="cart2-summary-order-shipping">
              <h5>Shipping</h5>
              <h5>250</h5>
            </div>

            <div className="cart2-summary-order-discount">
              <h5>Discount</h5>
              <h5>350 (15%)</h5>
            </div>
          </div>

          <hr />

          <div className="cart2-summary-final-payment-detail">
            <div className="cart2-summary-final-amount">
              <h5>Order Total</h5>
              <h5>4500</h5>
            </div>

            <button onClick={()=>navigate("/checkout?checkoutStarted=true")}>Proceed to CheckOut</button>
          </div>

          {/* <hr /> */}

          {/* <div className="cart2-summary-promocode">
                    <span>Promo Code</span>

                    <div className="cart2-promocode-form">
                        <input type="text" placeholder="Enter a Promo Code" />
                        <button>Apply Code</button>
                    </div>
                </div> */}
        </div>
      </div>

      <Popup
        showPopupFlag={showPopup}
        setShowPopupFlag={setShowPopup}
        msg={popupMessage}
        setPopupReturn={handlePopupConfirm}
      />
    </div>
  );
};

export default Cart2;

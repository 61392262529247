import React from "react";
import "./EventOneEvents.scss";

const Events = () => {
  return (
    <div className="Browse_Events">
      <div className="container">
        <div className="Events_inner">       
          <div className="section_header">
            <div className="left">
              <div className="icon">
                <span class="material-symbols-outlined">
                  browse
                  </span>
              </div>
              <div>
              <h2 className="title">browse events by genre</h2>
              </div>
            </div>
            <div className="right">
            </div>
          
          </div>
          <div className="Events-images">
            <div className="image-item">
              <img src="./assets/img/ecommerce/ecomTwo/icons/training.png" alt="workshop" />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/children.png"
                alt="Course"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/health-care.png"
                alt="health"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/theater.png"
                alt="theatre"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/game-controller.png"
                alt="games"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/joystick.png"
                alt="kids"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/musical-note.png"
                alt="Music"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>
            <div className="image-item">
              <img
                src="./assets/img/ecommerce/ecomTwo/icons/student.png"
                alt="Workshops"
              />
              <p className="img_title">Workshops</p>
              <text claaName="img_para">299 events</text>
            </div>

            

          </div>
          <div className="ecom_btn_event_button">
            <button>Show More</button>
          </div>
        </div>
    </div>
</div>
  )
}
          export default Events
import React, { useState, useEffect, useCallback } from "react";
import "./CorporateTwoServiceTwo.scss";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

const serviceTwoData = [
  {
    id: 1,
    title: "Dry Cleaning",
    body: "Efficient, eco-friendly laundry services specializing in stain removal, delicate care, and quick turnaround.",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice1.webp?alt=media&token=c282ed54-433f-4788-9fec-7bb16e47590f",
  },
  {
    id: 2,
    title: "Laundry by Kg",
    body: "Professional dry cleaning services offering meticulous care for delicate fabrics, stain removal, and fast service.",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice2.webp?alt=media&token=3766bbf3-36f6-43c3-a732-3cf7835ebdb7",
  },
  {
    id: 3,
    title: "Steam Wash",
    body: "Premium steam wash services ensuring deep cleaning, fabric care, and wrinkle removal. Gentle on clothes.",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice3.webp?alt=media&token=6cb7997a-1f46-42bf-b0af-63f9b4101dae",
  },
  {
    id: 4,
    title: "Household Laundry",
    body: "Reliable household laundry services, offering wash, dry, fold, and ironing. Convenient, affordable, and efficient",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice4.webp?alt=media&token=527c20d8-2455-43e1-aee6-94647d49db10",
  },
  {
    id: 5,
    title: "Shoes Cleaning",
    body: "Convenient pickup laundry services with wash, dry, and fold. Hassle-free, time-saving, and reliable care.",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice5.webp?alt=media&token=1dc776fb-2bfb-4b19-8dd2-d14670d92722",
  },
  {
    id: 6,
    title: "Premium Laundry",
    body: "Premium laundry and dryclean service for all types of premium fabrics and clothes and premium places",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice6.webp?alt=media&token=4cf9494a-a047-4c85-9168-6a0f9c1e0136",
  },
  {
    id: 7,
    title: "Carpet Cleaning",
    body: "Professional Carpet Cleaning with exclusive machines and expert team with gaurnteed results and best cleaning",
    serviceImage:
      "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fservice7.webp?alt=media&token=450180e5-846a-4f9e-b474-119647ad2542",
  },
];

const CorporateTwoServiceTwo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoSlideKey, setAutoSlideKey] = useState(0);
  const slidesToShow = 3;
  const autoSlideInterval = 5000; // 5 seconds

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceTwoData.length);
    setAutoSlideKey((prev) => prev + 1); // Reset timer
  }, []);

  const prevSlide = useCallback(() => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + serviceTwoData.length) % serviceTwoData.length
    );
    setAutoSlideKey((prev) => prev + 1); // Reset timer
  }, []);

  useEffect(() => {
    const intervalId = setInterval(nextSlide, autoSlideInterval);

    // Clear the interval when the component unmounts or when autoSlideKey changes
    return () => clearInterval(intervalId);
  }, [nextSlide, autoSlideKey]);

  const getVisibleSlides = () => {
    const visibleSlides = [];
    for (let i = 0; i < slidesToShow; i++) {
      const index = (currentIndex + i) % serviceTwoData.length;
      visibleSlides.push(serviceTwoData[index]);
    }
    return visibleSlides;
  };

  return (
    <div className="corporateTwo-home-services">
      <div className="corporateTwo-home-services-heading">
        <h1>What we Offer</h1>
        <h2>Our Services</h2>
      </div>

      <div className="corporateTwo-home-services-container">
        {getVisibleSlides().map((service) => (
          <div key={service.id} className="service-slide">
            <img src={service.serviceImage} alt="service pic" />

            <div className="corporateTwo-home-service-info">
              <h2>{service.title}</h2>
              <p>{service.body}</p>
              {/* <button>Read More</button> */}
            </div>
          </div>
        ))}
      </div>

      <div className="corporateTwo-home-services-controls">
        <button onClick={prevSlide} className="control-button">
          <GrLinkPrevious size={30} />
        </button>
        <button onClick={nextSlide} className="control-button">
          <GrLinkNext size={30} />
        </button>
      </div>
    </div>
  );
};

export default CorporateTwoServiceTwo;

import React, { useRef, useEffect, useState } from "react";
import "./BusinessTwoAboutUs.scss";
import CountUp from "react-countup";

const BusinessTwoAboutUs = () => {
  const [inView, setInView] = useState(false);
  const countersRef = useRef();

  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setInView(true);
    } else {
      setInView(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% of the element is in view
    });

    if (countersRef.current) {
      observer.observe(countersRef.current);
    }

    return () => {
      if (countersRef.current) {
        observer.unobserve(countersRef.current);
      }
    };
  }, [countersRef]);

  return (
    <div className="business-two-about-us">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 business-two-about-us-video">
            <video
              controls
              poster="https://pixelz.cc/wp-content/uploads/2018/07/road-with-mountains-uhd-4k-wallpaper.jpg"
            >
              <source src="your-video-file.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-12 col-md-6 business-two-about-us-text">
            <div
              className="row business-two-about-us-counters"
              ref={countersRef}
            >
              <div>
                <div class="wrapper">
                  <svg>
                    <text x="0%" y="50%" dy=".35em" text-anchor="start">
                      ABOUT US
                    </text>
                  </svg>
                </div>
              </div>
              <div className="col-6 business-two-about-us-counter bg-color-1">
                <div className="business-two-about-us-counter-content">
                  <div className="business-two-about-us-counter-icon">
                    <span className="material-symbols-outlined">
                      workspace_premium
                    </span>
                  </div>
                  <div>
                    <div className="business-two-about-us-counter-number">
                      {inView && (
                        <CountUp
                          start={0}
                          end={50}
                          duration={3.5}
                          separator=","
                        />
                      )}
                    </div>
                    <div className="business-two-about-us-counter-label">
                      Experience
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 business-two-about-us-counter bg-color-2">
                <div className="business-two-about-us-counter-content">
                  <div className="business-two-about-us-counter-icon">
                    <span className="material-symbols-outlined">
                      sentiment_satisfied
                    </span>
                  </div>
                  <div>
                    <div className="business-two-about-us-counter-number">
                      {inView && (
                        <CountUp
                          start={0}
                          end={10}
                          duration={3.5}
                          separator=","
                        />
                      )}
                    </div>
                    <div className="business-two-about-us-counter-label">
                      Happy
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 business-two-about-us-counter bg-color-3">
                <div className="business-two-about-us-counter-content">
                  <div className="business-two-about-us-counter-icon">
                    <span className="material-symbols-outlined">
                      support_agent
                    </span>
                  </div>
                  <div>
                    <div className="business-two-about-us-counter-number">
                      {inView && (
                        <CountUp
                          start={0}
                          end={10}
                          duration={3.5}
                          separator=","
                        />
                      )}
                    </div>
                    <div className="business-two-about-us-counter-label">
                      Services
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 business-two-about-us-counter bg-color-4">
                <div className="business-two-about-us-counter-content">
                  <div className="business-two-about-us-counter-icon">
                    <span className="material-symbols-outlined">
                      diversity_3
                    </span>
                  </div>
                  <div>
                    <div className="business-two-about-us-counter-number">
                      {inView && (
                        <CountUp
                          start={0}
                          end={100}
                          duration={3.5}
                          separator=","
                        />
                      )}
                    </div>
                    <div className="business-two-about-us-counter-label">
                      Staff
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTwoAboutUs;

import React, { useState } from "react";
import { useDocument } from "../../firebase files/hooks/useDocument";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { Tabs, Tab, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'; // Import the confirm icon

const ColorSettings = () => {
  const { updateDocument } = useFirestore("settings");
  const { document: colorsDocument } = useDocument("settings", "Colors");

  // Get the existing LightScreenTheme and DarkScreenTheme objects from the document
  const LightScreenTheme = (colorsDocument && colorsDocument.LightScreenTheme) || {};
  const DarkScreenTheme = (colorsDocument && colorsDocument.DarkScreenTheme) || {};

  const [selectedTab, setSelectedTab] = useState("light");
  const [clickColor, setClickColor] = useState("");
  const [headingColor, setHeadingColor] = useState("");
  const [cardBgColor, setCardBgColor] = useState("");
  const [subheadingColor, setSubheadingColor] = useState("");
  const [logoPrimaryColor, setLogoPrimaryColor] = useState("");

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    const selectedTheme = newValue === "light" ? LightScreenTheme : DarkScreenTheme;

    setClickColor(selectedTheme.clickColor || "");
    setHeadingColor(selectedTheme.headingColor || "");
    setCardBgColor(selectedTheme.cardBgColor || "");
    setSubheadingColor(selectedTheme.subheadingColor || "");
    setLogoPrimaryColor(selectedTheme.logoPrimaryColor || "");
  };

  const handleSubmit = async (colorName) => {
    try {
      let colorValue;
      switch (colorName) {
        case "clickColor":
          colorValue = clickColor;
          break;
        case "logoPrimaryColor":
          colorValue = logoPrimaryColor;
          break;
        case "headingColor":
          colorValue = headingColor;
          break;
        case "cardBgColor":
          colorValue = cardBgColor;
          break;
        case "subheadingColor":
          colorValue = subheadingColor;
          break;
        default:
          colorValue = "";
      }

      if (!colorValue || colorValue.length < 2) {
        alert("Please enter a valid color name or code.");
        return;
      }

      const themeToUpdate = selectedTab === "light" ? "LightScreenTheme" : "DarkScreenTheme";
      const existingTheme = selectedTab === "light" ? LightScreenTheme : DarkScreenTheme;

      const updatedTheme = {
        ...existingTheme,
        [colorName]: colorValue,
      };

      await updateDocument("Colors", {
        [themeToUpdate]: updatedTheme,
      });

      console.log(`${colorName} updated successfully in ${themeToUpdate}!`);
    } catch (error) {
      console.error(`Error updating ${colorName}:`, error);
    }
  };

  return (
    <div className="container">
      <div className="section-title">
        <p>Colors Setting</p>
        <h2>Customize Colour Combinations</h2>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="color mode tabs">
          <Tab label="Light Mode" value="light" />
          <Tab label="Dark Mode" value="dark" />
        </Tabs>
      </Box>
      <div className="row">
        <ColorInputField
          label="Click Color"
          value={clickColor}
          setValue={setClickColor}
          handleSubmit={() => handleSubmit("clickColor")}
        />
        <ColorInputField
          label="Logo Primary Color"
          value={logoPrimaryColor}
          setValue={setLogoPrimaryColor}
          handleSubmit={() => handleSubmit("logoPrimaryColor")}
        />
        <ColorInputField
          label="Heading Color"
          value={headingColor}
          setValue={setHeadingColor}
          handleSubmit={() => handleSubmit("headingColor")}
        />
        <ColorInputField
          label="Card Background Color"
          value={cardBgColor}
          setValue={setCardBgColor}
          handleSubmit={() => handleSubmit("cardBgColor")}
        />
        <ColorInputField
          label="Subheading Color"
          value={subheadingColor}
          setValue={setSubheadingColor}
          handleSubmit={() => handleSubmit("subheadingColor")}
        />
      </div>
      <br />
      <p style={{ fontSize: "13px" }}>
        <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
        Change Colors&nbsp;:&nbsp;
        </span>
         You can switch between tabs to add colors for both dark and light modes.
      </p>
    </div>
  );
};

const ColorInputField = ({ label, value, setValue, handleSubmit }) => (
  <div className="d-flex align-items-center justify-content-center gap-4 col-12 col-md-3 col-lg-4 mt-4">
    <TextField
      type="text"
      label={label}
      variant="filled"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{
        borderRight: `5px solid ${value}`,
        borderLeft: `5px solid ${value}`,
        borderBottom: "none",
        position: "relative",
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={handleSubmit}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              width: "auto",
              padding: 8,
            }}
          >
            <CheckIcon />
          </IconButton>
        ),
      }}
    />
  </div>
);

export default ColorSettings;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./BusinessTwoAddTestimonials.scss";
import {
  projectFirestore,
  projectStorage,
} from "../../../../firebase config/config";

const BusinessTwoAddTestimonials = ({ showPopup, setShowPopup }) => {
  const [testimonialData, setTestimonialData] = useState({
    image: null,
    name: "",
    review: "",
    rating: "",
    location: "",
    companyName: "",
  });
  const [fetchingLocation, setFetchingLocation] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTestimonialData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTestimonialData((prevData) => ({
        ...prevData,
        image: file,
      }));
    }
  };

  const handleAddTestimonial = async () => {
    if (!testimonialData.image) {
      alert("Please upload an image.");
      return;
    }

    try {
      // Create document in Firestore without imageUrl
      const docRef = await projectFirestore.collection("testimonials").add({
        name: testimonialData.name,
        review: testimonialData.review,
        rating: testimonialData.rating,
        location: testimonialData.location,
        companyName: testimonialData.companyName,
      });

      // Upload image to Firebase Storage with document ID in path
      const storageRef = projectStorage.ref(
        `testimonials_images/${docRef.id}/${testimonialData.image.name}`
      );
      const uploadTask = storageRef.put(testimonialData.image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Image upload error:", error);
        },
        async () => {
          const imageUrl = await storageRef.getDownloadURL();

          // Update Firestore document with imageUrl
          await projectFirestore
            .collection("testimonials")
            .doc(docRef.id)
            .update({
              imageUrl,
            });

          setShowPopup(false);
          setTestimonialData({
            image: null,
            name: "",
            review: "",
            rating: "",
            location: "",
            companyName: "",
          });
        }
      );
    } catch (error) {
      console.error("Error adding testimonial:", error);
    }
  };

  const fetchLocation = () => {
    if (navigator.geolocation) {
      setFetchingLocation(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const apiKey = "d0135345fc704c7d8a60dee71c958b90";
          const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${apiKey}`;

          try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.results.length > 0) {
              setTestimonialData((prevData) => ({
                ...prevData,
                location: data.results[0].formatted,
              }));
            } else {
              setTestimonialData((prevData) => ({
                ...prevData,
                location: "Location not found",
              }));
            }
          } catch (error) {
            console.error("Error fetching location:", error);
            setTestimonialData((prevData) => ({
              ...prevData,
              location: "Error fetching location",
            }));
          } finally {
            setFetchingLocation(false);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          setTestimonialData((prevData) => ({
            ...prevData,
            location: "Error getting location",
          }));
          setFetchingLocation(false);
        }
      );
    } else {
      setTestimonialData((prevData) => ({
        ...prevData,
        location: "Geolocation is not supported by this browser.",
      }));
      setFetchingLocation(false);
    }
  };

  useEffect(() => {
    const body = document.body;
    if (showPopup) {
      body.style.overflow = "hidden";
      fetchLocation(); // Fetch location when popup is shown
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto"; // Ensure scrolling is restored on unmount
    };
  }, [showPopup]);

  const popupContent = (
    <div className="ht-testimonials-popup">
      <div className="ht-testimonials-popup-inner">
        <h2>Add Testimonial</h2>
        <form>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileChange}
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={testimonialData.name}
            onChange={handleInputChange}
          />
          <textarea
            name="review"
            placeholder="Write Review"
            value={testimonialData.review}
            onChange={handleInputChange}
          ></textarea>
          <input
            type="text"
            name="rating"
            placeholder="Rating"
            value={testimonialData.rating}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={testimonialData.location}
            readOnly
          />
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={testimonialData.companyName}
            onChange={handleInputChange}
          />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn_border"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn_fill"
              onClick={handleAddTestimonial}
              disabled={fetchingLocation}
            >
              {fetchingLocation ? "Fetching Location..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  if (!showPopup) return null;

  return ReactDOM.createPortal(popupContent, document.body);
};

export default BusinessTwoAddTestimonials;

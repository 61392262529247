import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Ads.css";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Adcarousel = () => {
  return (
    <div className="swipercomp">
      <div className="swipercomp_inner">
        <Swiper
          navigation
          pagination={{ clickable: true }}
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 3000 }} // Autoplay configuration
          loop // Enable looping behavior
        >
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad1.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad2.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad3.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad4.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad5.jpg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="ad_container">
              <img src="./assets/img/ad6.jpg" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Adcarousel;

import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

// import component 
// import BottomCurve from '../curveDivider/BottomCurve';

import CorporateOneBannerLogin from './CorporateOneBannerLogin';
import CorporateOneCurveDividerBottom from './CorporateOneCurveDivider/CorporateOneCurveDividerBottom';

// import css 
import './CorporateOneBanner.scss'

const CorporateOneBanner = () => {
  return (
    <div className="banner relative">
            <CorporateOneCurveDividerBottom/>
            <Carousel
                interval={5000000} // Set time interval (in milliseconds)
                controls={true} // Hide the control arrows
                indicators={false} // Hide the slide indicators
                pause={true} // Disable auto-pause on hover
                touch // Enable touch gestures for swipe
                // fade // Enable fade effect
                loop={true}
            >
                <Carousel.Item>
                    <img
                        src="./assets/img/corporate/corporateone/home/banner1.jpg"
                        alt="First slide"
                        className="slide_img"
                    />
               
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src="./assets/img/corporate/corporateone/home/banner2.jpg" // Make sure to use different images for each slide
                        alt="Second slide"
                        className="slide_img"
                    />
              
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src="./assets/img/corporate/corporateone/home/banner1.png" // Make sure to use different images for each slide
                        alt="Third slide"
                        className="slide_img"
                    />              
                </Carousel.Item>
            
            </Carousel>
            <Carousel.Caption className="carousel-caption">
                    <h6 className='banner_subtitle'>ERP System</h6>
                <h1 className='banner_title'>Happy ERP System Management Solution</h1>  
                <CorporateOneBannerLogin/>             
            </Carousel.Caption>
           
        </div>
  )
}

export default CorporateOneBanner

import React from "react";
import "./PGCorporateTwoAbout.scss";
import CorporateTwoPGAboutInfo from "../corporateTwoComponents/CorporateTwoPGAboutInfo/CorporateTwoPGAboutInfo";
import CorporateTwoPGAboutHeader from "../corporateTwoComponents/CorporateTwoPGAboutHeader/CorporateTwoPGAboutHeader";
import CorporateTwoCallus from "../corporateTwoComponents/CorporateTwoCallus/CorporateTwoCallus";
import CorporateTwoClients from "../corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import CorporateTwoDownloadApp from "../corporateTwoComponents/CorporateTwoDownloadApp/CorporateTwoDownloadApp";

const PGCorporateTwoAbout = () => {
  return (
    <div>
      <CorporateTwoPGAboutHeader />
      <CorporateTwoPGAboutInfo />
      <CorporateTwoCallus />
      <CorporateTwoClients
        category="partners"
        pageName="about"
        section="partners"
        addMoreVideosButton={false}
        addMoreImagesButton={true}
        addMoreTextContentButton={false}
      />
      <CorporateTwoDownloadApp />
    </div>
  );
};

export default PGCorporateTwoAbout;

import React from "react";
import "./CorporateTwoAbout.scss";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { CiCircleCheck } from "react-icons/ci";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import { Link } from "react-router-dom";

const CorporateTwoAbout = () => {
  const { document: contactDetails, error: contactDetailserror } = useDocument(
    "settings",
    "contactUs"
  );

  return (
    <div className="corporateTwo-about">
      <div className="corporateTwo-about-main-info">
        <div className="corporateTwo-about-left">
          <h1>About Us</h1>
          <h2>Professional Laundry & Drycleaning services</h2>
          <p>
            We are professionals in the laundry and dry cleaning business, which
            means we always stay up to date on the latest technologies, cleaning
            methods, and solutions for dealing with stains or delicate fabrics.
            With our Industrial Washing process , you will actually save a lot
            of resources while insuring your garments stay safe &
            hygienic.Whether it's heavy-duty industrial garments or delicate
            linens, our processes ensure deep cleaning, proper disinfection, and
            fabric care, meeting the highest standards of hygiene and safety.
          </p>

          <div className="corporateTwo-about-left-info">
            <div className="corporateTwo-about-left-info-features">
              <ul>
                <li>
                  <span>
                    <CiCircleCheck />
                  </span>
                  <span>Best Quality</span>
                </li>

                <li>
                  <span>
                    <CiCircleCheck />
                  </span>
                  <span>100% Customer Satisfaction</span>
                </li>

                <li>
                  <span>
                    <CiCircleCheck />
                  </span>
                  <span>Eco-Friendly</span>
                </li>

                <li>
                  <span>
                    <CiCircleCheck />
                  </span>
                  <span>Expert Team</span>
                </li>
              </ul>
            </div>

            <div className="corporateTwo-about-left-activity">
              <Link to="/about">
                <button>Learn More</button>
              </Link>
              <div className="corporateTwo-about-left-calling-info">
                <a
                  href={
                    "tel:" + (contactDetails && contactDetails.contactNumber)
                  }
                  style={{ color: "var(--black-color)" }}
                >
                  <span>
                    <MdOutlineWifiCalling3 />
                  </span>
                  <div className="corporateTwo-about-left-calling-detail">
                    <p>Call us Now</p>
                    {/* <h4>+91 9022-11-8383</h4> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="corporateTwo-about-right">
          <div className="corporateTwo-about-right-img"></div>

          <div className="corporateTwo-about-right-yoe">
            <span>10 +</span>
            <div className="corporateTwo-about-right-yoe-info">
              <h5>Years of experience</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoAbout;

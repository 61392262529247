import React, { useEffect, useState } from "react";
import { useCollection } from "../firebase files/hooks/useCollection";

const RecentlyClickedProducts = ({ onProductsChange }) => {
  const { documents: products } = useCollection("products");
  const [recentProducts, setRecentProducts] = useState([]);

  console.log("recentProducts", recentProducts);

  useEffect(() => {
    if (!products) return;

    // Get the two most recent subcategories from localStorage
    const recentSubcategories =
      JSON.parse(localStorage.getItem("clickedSubcategories")) || [];

    // Filter products to match the recent subcategories
    const filteredProducts = products.filter((product) =>
      recentSubcategories.includes(product.subCategory)
    );

    // Update the state and call the callback with the filtered products
    setRecentProducts(filteredProducts);
    if (onProductsChange) {
      onProductsChange(filteredProducts);
    }
  }, [products, onProductsChange]);

  return null;
};

export default RecentlyClickedProducts;

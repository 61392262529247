import { useState } from "react";
import "./PGBusinessThreeAboutUs.scss";
import BusinessThreeWhyUs from "../businessThreeComponents/BusinessThreeWhyUs";

const PGBusinessThreeAboutUs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [slideClass, setSlideClass] = useState("slide-in");

  const icons = ["iron", "engineering", "apparel", "styler", "lock"];

  const services = ["The Why", "The What", "The How"];

  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setSlideClass("slide-out");
      setTimeout(() => {
        setActiveTab(index);
        setSlideClass("slide-in");
      }, 500);
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="container">
        <br />
        <div className="business-three-about-us-heading">
          <h1>About <span style={{color:"var(--click-color)"}}>Us</span></h1>
        </div>
        <br />
        <div className="d-flex align-items-center justify-content-between business-three-about-top-selector">
          {services.map((service, index) => (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`business-three-service-tab ${
                activeTab === index ? "active" : ""
              }`}
            >
              {service}
            </div>
          ))}
        </div>
        <br />

        {activeTab === 0 && (
          <div className={`about-the-why-section ${slideClass}`}>
            <div className="large_margin_div"></div>{" "}
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>
                  We didn't find it for us,
                  <span>so we created it for you</span>
                </h3>
                <br />
                <p>
                  That's essentially our story in one sentence. Crammed up
                  hostels or cooped up PGs - not much of a choice, is it? That's
                  why we created Stanza Living - a place designed by people
                  who've been in your shoes. Understand you. And are inspired by
                  you.
                </p>
                <br />
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Fw_800%2Fv1653991914%2FWebsite%2520v5%2FAbout_Us%2Fabout-us-image1.jpg&w=1920&q=75"
                  alt="about us img"
                />
              </div>
            </div>
            <div className="large_margin_div"></div>
            <div className="large_margin_div"></div>

            <div className="row business-three-about-us-reverse-sec">
              <div className="col-12 col-md-6">
                <h3>
                You needed a place like home,
                              <span>so we moved back home</span>
                </h3>
                <br />
                <p>
                  That's essentially our story in one sentence. Crammed up
                  hostels or cooped up PGs - not much of a choice, is it? That's
                  why we created Stanza Living - a place designed by people
                  who've been in your shoes. Understand you. And are inspired by
                  you.
                </p>
                <br />
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Fw_800%2Fv1653991919%2FWebsite%2520v5%2FAbout_Us%2Fabout-us-image2.jpg&w=1920&q=75"
                  alt="about us img"
                />
              </div>
            </div>
            <div className="large_margin_div"></div>
            <div className="large_margin_div"></div>

          </div>

        )}

        {activeTab === 1 && (
          <div className={`business-three-about-us ${slideClass}`}>
            <div className="business-three-about-us-content">
              <div className="row d-flex align-items-center">
                <div className="col-12 col-md-12">
                  <BusinessThreeWhyUs />
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div>
                        <div className="large_margin_div"></div>

            <div
              className={`business-three-service-selected-service ${slideClass}`}
            >
              <div className="business-three-service-selected-service-content">
                <div className="row d-flex align-items-center">
                  <div className="col-12 col-md-6">
                    <h4 style={{ color: "#4b4b4b" }}>
                      We designed the backdrop of your life{" "}
                    </h4>
                    <br />
                    <p>
                      The sofa in the lounge will make you see your bestie and
                      you arguing about whose day was more hectic. The bed in
                      your room will give you a picture of where you'll be
                      scrolling through insta stories. The microwave in the
                      pantry will make you imagine slurping on a piping hot bowl
                      of noodles in the middle of the night. Simply put, you'll
                      see a bit of your life in every spot of the residence. And
                      a place that does that is home
                    </p>
                    <br />
                    <div>
                      <button className="btn_border">Contact Us</button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <img
                      src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Fv1654681234%2FWebsite%2520v5%2FAbout_Us%2Fhow1.jpg&w=1920&q=75"
                      alt="about img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div
              className={`business-three-service-selected-service ${slideClass}`}
            >
              <div className="business-three-service-selected-service-content">
                <div className="row d-flex align-items-center business-three-service-selected-service-content-inside">
                  <div className="col-12 col-md-6">
                    <h4 style={{ color: "#4b4b4b" }}>
                      We designed the backdrop of your life{" "}
                    </h4>
                    <br />
                    <p>
                      The sofa in the lounge will make you see your bestie and
                      you arguing about whose day was more hectic. The bed in
                      your room will give you a picture of where you'll be
                      scrolling through insta stories. The microwave in the
                      pantry will make you imagine slurping on a piping hot bowl
                      of noodles in the middle of the night. Simply put, you'll
                      see a bit of your life in every spot of the residence. And
                      a place that does that is home
                    </p>
                    <br />
                    <div>
                      <button className="btn_border">Contact Us</button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <img
                      src="https://www.stanzaliving.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fstanza-living%2Fimage%2Fupload%2Fv1654681234%2FWebsite%2520v5%2FAbout_Us%2Fhow3.jpg&w=1920&q=75"
                      alt="about img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="large_margin_div"></div>
            <div className="large_margin_div"></div>

          </div>
        )}
      </div>
    </div>
  );
};

export default PGBusinessThreeAboutUs;

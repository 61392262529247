import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
  MDBRow,
  MDBTooltip,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import useCart from "../firebase files/hooks/useCart";
import Skeleton from "react-loading-skeleton";

export default function Cart() {
  const { user } = useAuthContext();
  const { documents: cartDocuments, error: cartErrors } = useCollection("cart");
  const { documents: productDocuments, error: productErrors } =
    useCollection("products");

  const { document: currencyDocument, error: currencyError } = useDocument(
    "settings",
    "currency"
  );

  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    if(currencyDocument && productDocuments){
      setIsLoading(false)
    }
  },[currencyDocument,productDocuments])

  const { cartItems, updateCartItemQuantity, removeFromCart } = useCart();


  console.log(cartItems);
  console.log(productDocuments);

 
  const getProduct = (productId) => {
    return productDocuments && productDocuments.find((product) => product.id === productId);
  };

  const handleQauntityChange = (cartItem,newQuantity) => {
     if(newQuantity>0){
       updateCartItemQuantity(cartItem.id,newQuantity)
     }else{
      removeFromCart(cartItem.id)
     }
  }

  if(isLoading){
    return (
      <MDBContainer className="py-5 h-100">
        <Skeleton count={5} height={100} className="mb-4" />
      </MDBContainer>
    )
  }

  return (
    <section className="h-100 gradient-custom">
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center my-4">
          <MDBCol md="8">
            <MDBCard className="mb-4">
              <MDBCardHeader className="py-3">
                <MDBTypography tag="h5" className="mb-0">
                  Cart - {cartItems.length} items
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                {cartItems &&
                  cartItems.map((cartItem, index) => {
                    return (
                      <>
                        <MDBRow>
                          <MDBCol lg="3" md="12" className="mb-4 mb-lg-0">
                            <MDBRipple
                              rippleTag="div"
                              rippleColor="light"
                              className="bg-image rounded hover-zoom hover-overlay"
                            >
                              <img
                                src={getProduct(cartItem.productId).images[0]}
                                className="w-100"
                              />
                              <a href="#!">
                                <div
                                  className="mask"
                                  style={{
                                    backgroundColor: "rgba(251, 251, 251, 0.2)",
                                  }}
                                ></div>
                              </a>
                            </MDBRipple>
                          </MDBCol>

                          <MDBCol lg="5" md="6" className=" mb-4 mb-lg-0">
                            <p>
                              <strong>
                                {getProduct(cartItem.productId).name}
                              </strong>
                            </p>

                            <MDBTooltip
                              wrapperProps={{ size: "sm" }}
                              wrapperClass="me-1 mb-2"
                              title="Remove item"
                            >
                              <span className="material-symbols-outlined">
                                delete
                              </span>
                            </MDBTooltip>

                            <MDBTooltip
                              wrapperProps={{ size: "sm", color: "danger" }}
                              wrapperClass="me-1 mb-2"
                              title="Move to the wish list"
                            >
                              <span className="material-symbols-outlined">
                                bookmark
                              </span>
                            </MDBTooltip>
                          </MDBCol>


                          <MDBCol lg="4" md="6" className="mb-4 mb-lg-0">
                            <div
                              className="d-flex mb-4"
                              style={{ maxWidth: "300px" }}
                            >
                              <MDBBtn className="px-3 me-2" onClick={()=>handleQauntityChange(cartItem,cartItem.quantity-1)}> 
                                <span className="material-symbols-outlined">
                                  remove
                                </span>
                              </MDBBtn>

                              <MDBInput
                                value={cartItem.quantity}
                                min={0}
                                type="number"
                              />

                              <MDBBtn className="px-3 ms-2">
                                <span className="material-symbols-outlined" onClick={()=>handleQauntityChange(cartItem,cartItem.quantity+1)}>
                                  add
                                </span>
                              </MDBBtn>
                            </div>

                            <p className="text-start text-md-center">
                              <strong>
                                {currencyDocument.symbol}{" "}
                                {getProduct(cartItem.productId).price}
                              </strong>
                            </p>
                          </MDBCol>
                        </MDBRow>

                        <hr className="my-4" />
                      </>
                    );
                  })}
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4">
              <MDBCardBody>
                <p>
                  <strong>Expected shipping delivery</strong>
                </p>
                <p className="mb-0">12.10.2020 - 14.10.2020</p>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-lg-0">
              <MDBCardBody>
                <p>
                  <strong>We accept</strong>
                </p>
                <MDBCardImage
                  className="me-2"
                  width="45px"
                  src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/visa.svg"
                  alt="Visa"
                />
                <MDBCardImage
                  className="me-2"
                  width="45px"
                  src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/amex.svg"
                  alt="American Express"
                />
                <MDBCardImage
                  className="me-2"
                  width="45px"
                  src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg"
                  alt="Mastercard"
                />
                <MDBCardImage
                  className="me-2"
                  width="45px"
                  src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce/includes/gateways/paypal/assets/images/paypal.png"
                  alt="PayPal acceptance mark"
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography tag="h5" className="mb-0">
                  Summary
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Products
                    <span>$53.98</span>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center px-0">
                    Shipping
                    <span>Gratis</span>
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                      <strong>
                        <p className="mb-0">(including VAT)</p>
                      </strong>
                    </div>
                    <span>
                      <strong>$53.98</strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>

                <MDBBtn block size="lg">
                  Go to checkout
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

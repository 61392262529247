import { React, useState, useEffect } from "react";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import PGLoaders from "../../default pages/PGLoaders/PGLoaders";
const LoaderSetting = () => {
  const { user } = useAuthContext();

  const { updateDocument, deleteDocument } = useFirestore("settings");
  const [activeLoader, setActiveLoader] = useState("");

  const { document: loaderTypeDoc, error: loaderTypeDocerror } = useDocument(
    "settings",
    "loaders"
  );

  useEffect(() => {
    if (loaderTypeDoc) {
      setActiveLoader(loaderTypeDoc.activeLoader || "");
    }
  }, [loaderTypeDoc]);

  const loaderTypeLabels = {
    dottedspinner: "Dotted Loader",
    skeletonloader: "Skeleton Loader",
    circularloader: "Circular Loader",
    cubeloader: "Cube Loader",
  };

  const handleLoaderChange = async (loaderTypeLabel) => {
    try {
      const selectedLoader = loaderTypeLabel;
      await updateDocument("loaders", { activeLoader: selectedLoader });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            {user && user.roles.includes("admin") && (
              <div className="role">
                <div className="section-title">
                  <p>Loader Setting</p>
                  <h2>Select Your Best Loader</h2>
                </div>
                <div className="radio-container">
                  {Object.keys(loaderTypeLabels).map((loaderType, index) => (
                    <label
                      key={index}
                      className={`radio-container ${
                        activeLoader === loaderType
                          ? "radio_btn_checked"
                          : "service_details_radion_btn"
                      }`}
                      style={{
                        color:
                          activeLoader === loaderType
                            ? "var(--white-color)"
                            : "inherit",
                        backgroundColor:
                          activeLoader === loaderType
                            ? "var(--click-color)"
                            : "inherit",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="nav"
                          value={loaderType}
                          onChange={() => {
                            setActiveLoader(loaderType);
                            handleLoaderChange(loaderType);
                          }}
                          className="radio-button"
                        />
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "21px" }}
                        >
                          {activeLoader === loaderType ? "done" : "add"}
                        </span>
                        {loaderTypeLabels[loaderType]}
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
            <PGLoaders />
          </div>
        </div>
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note:
          </span>
          Loader Design: After selecting the Loader button, you can explore the
          design of the selected Loader on the website.
        </p>
      </div>
    </div>
  );
};

export default LoaderSetting;

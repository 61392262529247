import React from "react";

// import component
import BusinessOneBanner from "../../businessOneComponents/BusinessOneBanner";
import BusinessOneBitAbout from "../../businessOneComponents/BusinessOneBitAbout";
import BusinessOneServices from "../../businessOneComponents/BusinessOneServices";
import BusinessOneOffer from "../../businessOneComponents/BusinessOneOffer";
import BusinessOneCounter from "../../businessOneComponents/BusinessOneCounter";
import BusinessOneTestimonial from "../../businessOneComponents/BusinessOneTestimonial";
import BusinessOneFaq from "../../businessOneComponents/BusinessOneFaq";
import BusinessOneOurTeam from "../../businessOneComponents/BusinessOneOurTeam";

const BusinessOneHome = () => {
  return (
    <div className="home_pg">
      <BusinessOneBanner />
      {/* <HomeHeroBanners/> */}
      <BusinessOneBitAbout />
      <BusinessOneServices />
      <BusinessOneOffer />
      <BusinessOneCounter />
      <BusinessOneTestimonial />
      <BusinessOneFaq />
      <BusinessOneOurTeam />
      {/* <BusinessOneCopyright /> */}
    </div>
  );
};

export default BusinessOneHome;

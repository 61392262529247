import { React, useState, useEffect } from "react";
import "./TemplateSettings.scss";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
const ThemePalette = () => {
  const { user } = useAuthContext();

  const { updateDocument } = useFirestore("settings");
  const [activeThemePalette, setActiveThemePalette] = useState("");

  const { document: themePaletteTypeDoc } = useDocument("settings", "themePalette");
  
  useEffect(() => {
    if (themePaletteTypeDoc) {
      setActiveThemePalette(themePaletteTypeDoc.activeThemePalette || "");
    }
  }, [themePaletteTypeDoc]);

  const themePaletteTypeLabels = {
    defaultThemePalette: "Default Theme",
    diwaliThemePalette: "Diwali Theme",
    holiThemePalette: "Holi Theme",
  };

  const handleTemplateChange = async (themePaletteTypeLabel) => {
    try {
      const selectedTemplate = themePaletteTypeLabel;
      await updateDocument("themePalette", { activeThemePalette: selectedTemplate });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };
  return (
    <div>
      <div className="container">
        {user && user.role==="admin" && (
          <div className="role">
            <div className="section-title">
              <p>Theme Palette Setting</p>
              <h2>Select Your Theme</h2>
            </div>
            <div className="radio-container">
              {Object.keys(themePaletteTypeLabels).map((templatePaletteType, index) => (
                <label
                  key={index}
                  className={`radio-container ${
                    activeThemePalette === templatePaletteType
                      ? "radio_btn_checked"
                      : "service_details_radion_btn"
                  }`}
                  style={{
                    color:
                      activeThemePalette === templatePaletteType
                        ? "var(--white-color)"
                        : "inherit",
                    backgroundColor:
                      activeThemePalette === templatePaletteType
                        ? "var(--click-color)"
                        : "inherit",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="nav"
                      value={templatePaletteType}
                      onChange={() => {
                        setActiveThemePalette(templatePaletteType);
                        handleTemplateChange(templatePaletteType);
                      }}
                      className="radio-button"
                    />
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "21px" }}
                    >
                      {activeThemePalette === templatePaletteType ? "done" : "add"}
                    </span>
                    {themePaletteTypeLabels[templatePaletteType]}
                  </div>
                </label>
              ))}
            </div>
          </div>
        )}
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note :
          </span>
          &nbsp;Theme Selection: After selecting an occasion theme like Diwali, Holi, or Christmas, you can explore and preview the design tailored to that celebration on the website.
        </p>
      </div>
    </div>
  );
};

export default ThemePalette;

import React from 'react'

// import css 
import './CorporateOneBitAbout.scss'

const CorporateOneBitAbout = () => {
  return (
    <section className="sect_padding a_bit_about">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="about_content about_left">
            <h6 className="small_title_with_line">
              About Happy ERP
            </h6>
            <h2 className='df45 text_black semibold'>We Are Fully Dedicated To Support</h2>
            <p className='df15 text_grey regular lh26'>There's this notion that to grow a business, you have to be rutless But we know there's a better way to grow</p>

            <div className="ac_more">
              <div className="icon">
                <img src="assets/img/icons/mission.png" alt="image" />
              </div>
              <h3 className='df25 medium text_black'>Our Mission</h3>
              <p className='df15 regular text_grey lh26'>Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
            </div>

            <div className="ac_more">
              <div className="icon">
                <img src="assets/img/icons/story.png" alt="image" />
              </div>
              <h3 className='df25 medium text_black'>Our Story</h3>
              <p className='df15 regular text_grey lh26'>Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="about_right relative">
            <img src="assets/img/corporate/corporateone/home/about_left.jpg" alt="image" />
            <div className="exp">
              <p><span>16</span>Years Experience</p>
              <div className="rotate_circle"></div>                 
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CorporateOneBitAbout

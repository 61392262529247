import React from "react";

import "./EducationTwoCourses.scss";

const EducationTwoCourses = () => {
  return (
    <section className="et_coursed sect_padding relative">
      <img
        src="./assets/img/educationtwo/line_img.png"
        alt=""
        className="line_img"
      />
      <div className="container relative">
        <div className="hat_img">
          <img src="./assets/img/educationtwo/hateimg.png" alt="" />
        </div>
            <div className="sect_header">
          <span className="sect_badge">Top Popular Course</span>
          <h3 className="sect_title">
            TutorTot Course{" "}
            <span className="relative">
              Student
              <span class="title-shape">
                <svg
                  width="150"
                  height="65"
                  viewBox="0 0 168 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="highligt_text"
                >
                  <path
                    d="M73.3761 8.49147C78.4841 6.01353 82.5722 4.25154 88.8933 3.3035C94.2064 2.50664 99.6305 2.0701 104.981 1.94026C120.426 1.56549 135.132 4.90121 146.506 9.70405C158.628 14.8228 166.725 22.5638 166.074 31.6501C165.291 42.5779 151.346 51.7039 133.508 56.8189C110.253 63.4874 81.7065 63.8025 58.5605 60.8285C37.5033 58.123 11.6304 51.7165 3.58132 40.0216C-3.43085 29.8337 12.0728 18.1578 27.544 11.645C40.3656 6.24763 55.7082 2.98328 70.8043 4.08403C81.9391 4.89596 93.2164 6.87822 102.462 9.99561C112.874 13.5066 120.141 18.5932 127.862 23.6332"
                    stroke="var(--click-color)"
                    stroke-width="3"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
            </span>{" "}
            can join with us.
          </h3>
        </div>
        <div className="courses_card">
            <div className="courses_card_single">
                         <div className="img_area">
              <img src="./assets/img/educationtwo/course_one.jpg" alt="" />
              <span>
                Development
              </span>
                                </div>
            <div className="content_area">
              <div className="ca_top">
                      <div className="stars">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                      </div>
                <h4 className="title">
                  It statistics data science and Business analysis
                </h4>
                     <div className="other_info">
                       <div className="oi_single">
                    <span class="material-symbols-outlined">description</span>
                    <span className="text">Lesson 10</span>
                        </div>
                         <div className="oi_single">
                    <span class="material-symbols-outlined">schedule</span>
                    <span className="text">19h 30m</span>
                           </div>
                          <div className="oi_single">
                    <span class="material-symbols-outlined">group</span>
                    <span className="text">Students 20+</span>
                                </div>                 
                                 </div>
                                 </div>
                     <div className="ca_bottom">
                            <div className="teacher_info">
                  <img src="./assets/img/educationtwo/teacherone.jpg" alt="" />
                  <span>By</span>
                               </div>
                               <div className="price_purchase">
                  <h3 className="price">
                    ₹ 15,000
                  </h3>
                  <h4>
                    Buy Now
                  </h4>
                          </div>
                             </div>
                    </div>
                      </div>
          <div className="courses_card_single">
                           <div className="img_area">
              <img src="./assets/img/educationtwo/course_one.jpg" alt="" />
              <span>
                Development
              </span>
            </div>
            <div className="content_area">
              <div className="ca_top">
                <div className="stars">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <h4 className="title">
                  It statistics data science and Business analysis
                </h4>
                <div className="other_info">
                  <div className="oi_single">
                    <span class="material-symbols-outlined">description</span>
                    <span className="text">Lesson 10</span>
                  </div>
                  <div className="oi_single">
                    <span class="material-symbols-outlined">schedule</span>
                    <span className="text">19h 30m</span>
                  </div>
                  <div className="oi_single">
                    <span class="material-symbols-outlined">group</span>
                    <span className="text">Students 20+</span>
                  </div>                 
                </div>
              </div>
              <div className="ca_bottom">
                <div className="teacher_info">
                  <img src="./assets/img/educationtwo/teacherone.jpg" alt="" />
                  <span>By</span>
                </div>
                <div className="price_purchase">
                  <h3 className="price">
                    ₹ 15,000
                  </h3>
                  <h4>
                    Buy Now
                  </h4>
                </div>
              </div>
            </div>
                              </div>
          <div className="courses_card_single">
            <div className="img_area">
              <img src="./assets/img/educationtwo/course_one.jpg" alt="" />
              <span>
                Development
              </span>
            </div>
            <div className="content_area">
              <div className="ca_top">
                <div className="stars">
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                  <span class="material-symbols-outlined">star</span>
                </div>
                <h4 className="title">
                  It statistics data science and Business analysis
                </h4>
                <div className="other_info">
                  <div className="oi_single">
                    <span class="material-symbols-outlined">description</span>
                    <span className="text">Lesson 10</span>
                  </div>
                  <div className="oi_single">
                    <span class="material-symbols-outlined">schedule</span>
                    <span className="text">19h 30m</span>
                  </div>
                  <div className="oi_single">
                    <span class="material-symbols-outlined">group</span>
                    <span className="text">Students 20+</span>
                  </div>                 
                </div>
              </div>
              <div className="ca_bottom">
                <div className="teacher_info">
                  <img src="./assets/img/educationtwo/teacherone.jpg" alt="" />
                  <span>By</span>
                </div>
                <div className="price_purchase">
                  <h3 className="price">
                    ₹ 15,000
                  </h3>
                  <h4>
                    Buy Now
                  </h4>
                </div>
              </div>
            </div>
                                  </div>
                   </div>
      </div>
    </section>
  );
};

export default EducationTwoCourses;

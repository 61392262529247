import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Checkout.scss';
import useCart from '../firebase files/hooks/useCart';
import { useCollection } from '../firebase files/hooks/useCollection';
import { useDocument } from '../firebase files/hooks/useDocument';
import Skeleton from "react-loading-skeleton";

const Checkout = () => {
    const { cartItems } = useCart();
    const { documents: productDocuments, error: productErrors } =
        useCollection("products");

    const { document: currencyDocument, error: currencyError } = useDocument(
        "settings",
        "currency"
    );
    const [step, setStep] = useState(1);
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        flatNumber: '',
        streetLine1: '',
        streetLine2: '',
        landmark: '',
        city: '',
        state: '',
        pincode: ''
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (currencyDocument && productDocuments) {
            setIsLoading(false);
        }
    }, [currencyDocument, productDocuments]);



    const getProduct = (productId) => {
        return (
            productDocuments &&
            productDocuments.find((product) => product.id === productId)
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleNext = () => {
        setStep(prevStep => Math.min(prevStep + 1, 4));
    };

    const handleBack = () => {
        setStep(prevStep => Math.max(prevStep - 1, 1));
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="step-content">
                        <div className="personal-info">
                            <h2>Personal Information</h2>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" id="firstName" name="firstName" value={personalInfo.firstName} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" id="lastName" name="lastName" value={personalInfo.lastName} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" value={personalInfo.email} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <input type="tel" id="phone" name="phone" value={personalInfo.phone} onChange={handleChange} />
                                </div>
                            </div>
                            <h3>Address</h3>
                            <div className="form-group">
                                <label htmlFor="flatNumber">Flat/House Number</label>
                                <input type="text" id="flatNumber" name="flatNumber" value={personalInfo.flatNumber} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="streetLine1">Street Address Line 1</label>
                                <input type="text" id="streetLine1" name="streetLine1" value={personalInfo.streetLine1} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="streetLine2">Street Address Line 2</label>
                                <input type="text" id="streetLine2" name="streetLine2" value={personalInfo.streetLine2} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="landmark">Nearby Landmark</label>
                                <input type="text" id="landmark" name="landmark" value={personalInfo.landmark} onChange={handleChange} />
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input type="text" id="city" name="city" value={personalInfo.city} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <input type="text" id="state" name="state" value={personalInfo.state} onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pincode">Pincode</label>
                                    <input type="text" id="pincode" name="pincode" value={personalInfo.pincode} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="checkout-cart-summary">
                            <div className='checkout-cart-summary-header'>
                                <h3>Cart Summary</h3>
                                <Link to="/cart2" className="edit-cart-btn">Edit Cart</Link>
                            </div>
                            {
                                cartItems && cartItems.map((item, index) => {
                                    const product = getProduct(item.productId);
                                    return (
                                        <div key={index} className="checkout-cart-item">
                                            <img src={product.images && product.images[0]} alt={product.name} />
                                            <div className="checkout-cart-item-details">
                                                <h4>{product.name}</h4>
                                                <div>
                                                    <span>Price: {currencyDocument.symbol}{product.price}</span>
                                                    <span>Qty: {item.quantity}</span>
                                                </div>
                                                <p>Total: {currencyDocument.symbol}{product.price * item.quantity}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                );
            case 2:
                return <h2 className="step-content">Payment Information</h2>;
            case 3:
                return <h2 className="step-content">Order Review</h2>;
            case 4:
                return <h2 className="step-content">Order Confirmation</h2>;
            default:
                return null;
        }
    };

    if (isLoading) {
        return (
            <div style={{ height: "100vh", padding: "10px" }}>
                <Skeleton count={5} height={100} className="mb-4" />
            </div>
        );
    }

    return (
        <div className="checkout-container">
            <div className="new-progress-bar">
                <div className='progress' style={{ width: `${(step - 1) * 33.33}%` }}></div>
                <div className={`step-indicator ${step >= 1 ? 'active-step' : ''}`}>
                    <span className="step-number">1</span>
                    <span className="step-name">Personal Info</span>
                </div>
                <div className={`step-indicator ${step >= 2 ? 'active-step' : ''}`}>
                    <span className="step-number">2</span>
                    <span className="step-name">Payment</span>
                </div>
                <div className={`step-indicator ${step >= 3 ? 'active-step' : ''}`}>
                    <span className="step-number">3</span>
                    <span className="step-name">Review</span>
                </div>
                <div className={`step-indicator ${step >= 4 ? 'active-step' : ''}`}>
                    <span className="step-number">4</span>
                    <span className="step-name">Confirmation</span>
                </div>
            </div>
            {renderStep()}
            <div className="button-container">
                <button className="back-btn" onClick={handleBack} disabled={step === 1}>Back</button>
                <button className="next-btn" onClick={handleNext} disabled={step === 4}>Next</button>
            </div>
        </div>
    );
};

export default Checkout;

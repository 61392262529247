import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

// import css 
import './CorporateOneTestimonial.scss'

const CorporateOneTestimonial = () => {
  return (
    <div className='tesimonial sect_padding'>
    <div className="container">
        <div className="testimonial_inner">
            <Carousel
                interval={5000} // Set time interval (in milliseconds)
                controls={false} // Hide the control arrows
                indicators={false} // Hide the slide indicators
                pause={false} // Disable auto-pause on hover
                touch // Enable touch gestures for swipe                   
                loop={true}
            >
                <Carousel.Item>
                    <div className="top">
                        <div className="star">
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                        </div>
                        <div className="name_pic">
                        <div className="left">
                            <h5 className="df22 text_black medium">
                                Sanskar Solanki
                            </h5>
                            <h6 className="df15 text_grey">
                                Developer
                            </h6>
                        </div>
                        <div className="right">
                            <img src="/assets/img/corporate/corporateone/home/demo_customer.jpg" alt="" />
                        </div>
                    </div>
                    </div>

                   
                    <h4 className="say df20 regular text_black">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, recusandae! Optio velit expedita corporis obcaecati, suscipit quis libero dolores minima quam itaque fugit? Esse, maiores minima repudiandae praesentium nobis consectetur.
                    </h4>

                </Carousel.Item>
                <Carousel.Item>
                    <div className="top">
                        <div className="star">
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                        </div>
                        <div className="name_pic">
                        <div className="left">
                            <h5 className="df22 text_black medium">
                           Naman Gaur
                            </h5>
                            <h6 className="df15 text_grey">
                                Developer
                            </h6>
                        </div>
                        <div className="right">
                            <img src="/assets/img/corporate/corporateone/home/demo_customer.jpg" alt="" />
                        </div>
                    </div>
                    </div>

                   
                    <h4 className="say df20 regular text_black">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, recusandae! Optio velit expedita corporis obcaecati, suscipit quis libero dolores minima quam itaque fugit? Esse, maiores minima repudiandae praesentium nobis consectetur.
                    </h4>

                </Carousel.Item>
                <Carousel.Item>
                    <div className="top">
                        <div className="star">
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                        </div>
                        <div className="name_pic">
                        <div className="left">
                            <h5 className="df22 text_black medium">
                               Rahul Kumar
                            </h5>
                            <h6 className="df15 text_grey">
                                Developer
                            </h6>
                        </div>
                        <div className="right">
                            <img src="/assets/img/corporate/corporateone/home/demo_customer.jpg" alt="" />
                        </div>
                    </div>
                    </div>

                   
                    <h4 className="say df20 regular text_black">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, recusandae! Optio velit expedita corporis obcaecati, suscipit quis libero dolores minima quam itaque fugit? Esse, maiores minima repudiandae praesentium nobis consectetur.
                    </h4>

                </Carousel.Item>
                <Carousel.Item>
                    <div className="top">
                        <div className="star">
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                            <span class="material-symbols-outlined">
                                star
                            </span>
                        </div>
                        <div className="name_pic">
                        <div className="left">
                            <h5 className="df22 text_black medium">
                         Mohan Sharma
                            </h5>
                            <h6 className="df15 text_grey">
                                Developer
                            </h6>
                        </div>
                        <div className="right">
                            <img src="/assets/img/corporate/corporateone/home/demo_customer.jpg" alt="" />
                        </div>
                    </div>
                    </div>

                   
                    <h4 className="say df20 regular text_black">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, recusandae! Optio velit expedita corporis obcaecati, suscipit quis libero dolores minima quam itaque fugit? Esse, maiores minima repudiandae praesentium nobis consectetur.
                    </h4>

                </Carousel.Item>

            </Carousel>
        </div>
    </div>
    <div className="band">
    </div>
</div>
  )
}

export default CorporateOneTestimonial

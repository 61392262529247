import React from "react";
import "./EventOneWorkshop.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const WorkShop = () => {
  const options = {
    items: 3,
    dots: false,
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 1500,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      // Define breakpoints and the number of items to show at each breakpoint
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 3,
      },
      1199: {
        items: 3,
      },
    },
  };
  return (
    <div className="Music-events">
      <div className="container">
        <div className="Music_inner">
          <div className="section_header">
            <div className="left">
              <div className="icon">
                <span className="music-icon material-symbols-outlined">
                  music_note
                </span>
              </div>
              <div>
                <h2 className="title">Workshops</h2>
              </div>
            </div>
            <div className="right">
            <button className="btn_inr">View All</button>
            </div>
          
          </div>
          <div div className="event-container">
            <OwlCarousel className="owl-theme my_owl_carousel" {...options}>
              <div className="item event-card">
                <div className="image-container">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/work1.jpg"
                    className="event-image"
                  />
                  <p>Speed Date Online</p>
                  <div className="badge">WORKSHOPS</div>
                </div>
                <div className="event-details">
                  <div className="text">
                    <span class="material-symbols-outlined">
                      calendar_month
                    </span>
                    <h6>Video on demand</h6>
                  </div>
                  <div className="text">
                    <span class="material-symbols-outlined">play_circle</span>
                    <h6>Watch On insider</h6>
                  </div>
                </div>
                <div className="buy-now">
                  <h6 className="price">₹499</h6>
                  <h6 className="buy_now">Buy now</h6>
                </div>
              </div>
              <div className="item event-card">
                <div className="image-container">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/work2.webp"
                    className="event-image"
                  />
                  <p>Terence Lewis Dance-Contemporary Tutorials</p>
                  <div className="badge">WORKSHOPS</div>
                </div>
                <div className="event-details">
                  <div className="text">
                    <span class="material-symbols-outlined">
                      calendar_month
                    </span>
                    <h6>Video on demand</h6>
                  </div>
                  <div className="text">
                    <span class="material-symbols-outlined">play_circle</span>
                    <h6>Watch On insider</h6>
                  </div>
                </div>
                <div className="buy-now">
                  <h6 className="price">₹499</h6>
                  <h6 className="buy_now">Buy now</h6>
                </div>
              </div>
              <div className="item event-card">
                <div className="image-container">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/work3.webp"
                    className="event-image"
                  />
                  <p>Free Trading Conclave, this weekend!</p>
                  <div className="badge">Workshop</div>
                </div>
                <div className="event-details">
                  <div className="text">
                    <span class="material-symbols-outlined">
                      calendar_month
                    </span>
                    <h6>Video on demand</h6>
                  </div>
                  <div className="text">
                    <span class="material-symbols-outlined">play_circle</span>
                    <h6>Watch On insider</h6>
                  </div>
                </div>
                <div className="buy-now">
                  <h6 className="price">₹499</h6>
                  <h6 className="buy_now">Buy now</h6>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkShop;

import React, { useRef, useEffect } from "react";
import "./CorporateTwoCallus.scss";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import { MdOutlineWifiCalling3 } from "react-icons/md";

const CorporateTwoCallus = () => {
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  const { document: contactDetails, error: contactDetailserror } = useDocument(
    "settings",
    "contactUs"
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
            // headingRef.current.classList.add('animate-heading')
            containerRef.current.classList.add("animate-container");
            imageRef.current.classList.add("animate-image");
          } else {
            // entry.target.classList.remove('in-view');
            // headingRef.current.classList.remove('animate-heading')
            // containerRef.current.classList.remove('animate-container')
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const section = sectionRef.current;
    observer.observe(section);

    return () => {
      observer.unobserve(section);
    };
  }, []);

  return (
    <div className="corporateTwo-home-callus" ref={sectionRef}>
      <div className="corporateTwo-home-callus-vehicle" ref={imageRef}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fcallustruck.webp?alt=media&token=880892b8-f418-46a4-b3ca-5349fa646245"
          alt="vehicle"
        />
      </div>
      <div className="corporateTwo-home-callus-info" ref={containerRef}>
        <h2>Need Professional Drycleaning and Laundry?</h2>
        <div className="corporateTwo-home-callus-action">
          <a
            href={"tel:" + (contactDetails && contactDetails.contactNumber)}
            style={{ color: "var(--black-color)" }}
          >
            <span>
              <MdOutlineWifiCalling3 />
            </span>
            <div className="corporateTwo-home-callus-action-text">
              <p>Call us Now</p>
            </div>
          </a>
        </div>
        {/* <button>Contact Us</button> */}
      </div>
    </div>
  );
};

export default CorporateTwoCallus;

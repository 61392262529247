import React from 'react'

// import css 
import './BusinessOneCounter.scss'

const BusinessOneCounter = () => {
  return (
    <section className="counter">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-6">
                <div className="counter_left">
                    <div className="counter_card">
                        <div className="counter_single">
                            <div className="cs_inner">
                                <h5>
                                    12+
                                </h5>
                                <div className="wave">
                                    <span class="material-symbols-outlined">
                                        water
                                    </span>
                                </div>
                                <h6>
                                Helpful Staff
                                </h6>
                            </div>
                        </div>
                        <div className="counter_single">
                            <div className="cs_inner">
                                <h5>
                                    10+
                                </h5>
                                <div className="wave">
                                    <span class="material-symbols-outlined">
                                        water
                                    </span>
                                </div>
                                <h6>
                                    Years Of Experience
                                </h6>
                            </div>
                        </div>
                        <div className="counter_single">
                            <div className="cs_inner">
                                <h5>
                                    200+
                                </h5>
                                <div className="wave">
                                    <span class="material-symbols-outlined">
                                        water
                                    </span>
                                </div>
                                <h6>
                                Happy Customers
                                </h6>
                            </div>
                        </div>
                        <div className="counter_single">
                            <div className="cs_inner">
                                <h5>
                                    30+
                                </h5>
                                <div className="wave">
                                    <span class="material-symbols-outlined">
                                        water
                                    </span>
                                </div>
                                <h6>
                                Customer Services
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="counter_right">
                    <h4 className="line_title">
                        <span></span>
                        Explore More
                    </h4>
                    {/* <h6 className="main_desc">
                    Discover all the exciting features and functionalities of our website.
                    </h6> */}
                    <div className="video_parent">
                        <video src="assets/video/dummy_video.mp4" autoPlay muted controls loop></video>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
  )
}

export default BusinessOneCounter

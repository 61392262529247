import React from "react";
import "./EducationOneWork.scss";

const EducationOneWork = () => {
  return (
    <div className="Education-One-Work-page">
      <div className="container">
        <div className="Education-One-Work_inner">
          <div className="Education-One-Work-section_header">
            <div className="Education-One-Work-left">
              
              
                <h2 className="Education-One-Work-title">HOW ITs WORKS</h2>
              </div>
            
            <div className="Education-One-Work-right">
              <div className="Education-One-Work-images">
                <div className="Education-One-Work-image-item">
                  <div className="circle"></div>
                  <img src="https://tse3.mm.bing.net/th?id=OIP.e-R05YSZupYbUq6ObtavtAHaHa&pid=Api&P=0&h=220" alt="Start Your Search" />
                  <div className="education-card">
                    <h2 className="education-work-contain">Start Your Search</h2>
                    <p className="education-work-para">Search for online tutoring. Need help with your search? Request a tutor and we’ll have tutors contact you very soon!</p>
                  </div>
                </div>
                <div className="Education-One-Work-image-item">
                  <div className="circle"></div>
                  <img src="https://tse2.mm.bing.net/th?id=OIP.KsE8Fw8yEBUi3n9vSvvRVgHaH0&pid=Api&P=0&h=220" />
                  <div className="education-card">
                    <h2 className="education-work-contain">Review</h2>
                    <p className="education-work-para">Read feedback and ratings from parents and students. Detailed tutor profiles also include photos more.</p>
                  </div>
                </div>
                <div className="Education-One-Work-image-item">
                  <div className="circle"></div>
                  <img src="https://tse4.mm.bing.net/th?id=OIP.MSQd56oVQl_yrfuXCHr9ZAHaH0&pid=Api&P=0&h=220" alt="Schedule" />
                  <div className="education-card">
                    <h2 className="education-work-contain">Schedule</h2>
                    <p className="education-work-para">Communicate directly with tutors to find a time that works for you.</p>
                  </div>
                </div>
                <div className="Education-One-Work-image-item">
                  <div className="circle"></div>
                  <img src="https://tse2.mm.bing.net/th?id=OIP.Ept-yVl3HlEDGwGmX2WGUgHaHa&pid=Api&P=0&h=220" alt="Start Learning" />
                  <div className="education-card">
                    <h2 className="education-work-contain">Start Learning</h2>
                    <p className="education-work-para">One-on-one instruction is the most effective way to learn.</p>
                  </div>
                </div>
            </div>
          </div>

          
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationOneWork;

import React, { useState } from 'react';
import './BlogsAndArticles.scss';
import { Link } from 'react-router-dom';
import Popup from './Popup';
import { useFirestore } from '../firebase files/hooks/useFirestore';
import { useCollection } from '../firebase files/hooks/useCollection';
import { useAuthContext } from '../firebase files/hooks/useAuthContext';
import { projectFirestore, projectStorage } from '../firebase config/config';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const BlogAndArticles = () => {
    const { user } = useAuthContext();

    const { updateDocument, deleteDocument } = useFirestore("blogs_and_articles");
    const { documents: blogsData } = useCollection('blogs_and_articles');

    const [newBlog, setNewBlog] = useState({
        title: '',
        description: '',
        link: '',
        imgUrl: '',
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [blogToDelete, setBlogToDelete] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBlog((prevBlog) => ({
            ...prevBlog,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const handleClick = () => {
        document.getElementById('hiddenFileInput').click();
    };

    const handleSubmit = async () => {
        setIsUploading(true); // Disable button on upload start
        try {
            const newBlogRef = await projectFirestore.collection("blogs_and_articles").add({
                title: newBlog.title,
                description: newBlog.description,
                link: newBlog.link,
                imgUrl: '',
            });

            if (selectedImage) {
                const newBlogDocId = newBlogRef.id;
                const storageRef = projectStorage.ref(`blogs_and_articles/${newBlogDocId}`);
                const imageRef = storageRef.child(selectedImage.name);
                await imageRef.put(selectedImage);
                const imageUrl = await imageRef.getDownloadURL();

                await newBlogRef.update({
                    imgUrl: imageUrl,
                });

                setNewBlog({
                    title: '',
                    description: '',
                    link: '',
                    imgUrl: '',
                });
                setSelectedImage(null);
                alert('Blog and image uploaded successfully!');
            } else {
                alert('Please select an image!');
            }
        } catch (error) {
            console.error('Error uploading blog and image:', error);
        } finally {
            setIsUploading(false); // Enable button after upload
        }
    };

    const handleConfirmDelete = async () => {
        if (blogToDelete) {
            try {
                await deleteDocument(blogToDelete.id);
                const storageRef = projectStorage.ref(`blogs_and_articles/${blogToDelete.id}`);
                const folderContents = await storageRef.listAll();
                const deletePromises = folderContents.items.map(item => item.delete());
                await Promise.all(deletePromises);
                alert('Blog deleted successfully!');
            } catch (error) {
                console.error('Error deleting blog:', error);
            } finally {
                setIsDeletePopupOpen(false);
                setBlogToDelete(null);
            }
        }
    };

    return (
        <div>
            <Popup
                showPopupFlag={isDeletePopupOpen}
                setShowPopupFlag={setIsDeletePopupOpen}
                msg="Are you sure you want to delete this Blog?"
                setPopupReturn={(confirmed) => {
                    if (confirmed) {
                        handleConfirmDelete();
                    }
                }}
            />
            <div className="blog-and-articles-blog_sect">
                <div className='container'>
                    <div className="section_title">
                        <h2 className="blog-and-section_heading_effect">News & Media</h2>
                        <h3 className='blog-and-section_sub-heading_effect'>Journeying Through Untold Stories and Hidden Reals</h3>
                    </div>

                    <div className="blog-article-grid">
                        {user && user.role === "admin" && (
                            <div className="blog-article-card-container">
                                <div className="upload-container">
                                    <input
                                        id="hiddenFileInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />

                                    <div className="blog-media-image-upload" onClick={handleClick}>
                                        {selectedImage ? (
                                            <div className="blog-media-image-preview blog-media-card-image">
                                                <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
                                                <span className="blog-media-image-upload-icon-overlay material-symbols-outlined">
                                                    perm_media
                                                </span>
                                            </div>
                                        ) : (
                                            <div className='d-flex align-items-center justify-content-center blog-media-card-image'>
                                                <span className="material-symbols-outlined blog-media-image-upload-icon">
                                                    perm_media
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <Box component="form" sx={{ maxWidth: '95%' }} validate autoComplete="on">
                                            <TextField
                                                id="outlined-title"
                                                label="Title"
                                                variant="outlined"
                                                name="title"
                                                size="small"
                                                value={newBlog.title}
                                                onChange={handleInputChange}
                                                fullWidth
                                                sx={{ marginBottom: 2, marginTop: 2 }}
                                            />
                                            <TextField
                                                id="outlined-description"
                                                label="Description"
                                                name="description"
                                                size="small"
                                                value={newBlog.description}
                                                onChange={handleInputChange}
                                                fullWidth
                                                sx={{ marginBottom: 2 }}
                                            />
                                            <TextField
                                                id="outlined-link"
                                                label="Link"
                                                variant="outlined"
                                                name="link"
                                                size="small"
                                                value={newBlog.link}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </Box>
                                    </div>

                                    <br />
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <button onClick={handleSubmit} style={{ width: "100%" }} className="btn_fill" disabled={isUploading}>
                                            {isUploading ? 'Uploading...' : 'Confirm'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {blogsData && blogsData.map((blog, index) => (
                            <div key={index} className="blog-article-card-container">
                                <div className="blog-media-card-image">
                                    <img src={blog.imgUrl} alt={`Blog ${index + 1}`} />
                                </div>
                                <div className="blog-media-card-body">
                                    <h3>{blog.title}</h3>
                                    <p>{blog.description}</p>
                                    <div className="d-flex justify-content-between" style={{ marginTop: 'auto' }}>
                                        <div>
                                            <Link className="pointer d-flex align-items-center" to={blog.link} target='_blank'>
                                                <span className="material-symbols-outlined">visibility</span> &nbsp; Read More
                                            </Link>
                                        </div>
                                        {user && user.role === "admin" && (
                                            <div>
                                                <span
                                                    className="material-symbols-outlined pointer"
                                                    onClick={() => {
                                                        setBlogToDelete(blog);
                                                        setIsDeletePopupOpen(true);
                                                    }}
                                                >
                                                    delete
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
};

export default BlogAndArticles;

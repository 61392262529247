import React, { useEffect, useRef } from "react";
import "./CorporateTwoClients.scss";
import AddMedia from "../../../../../default components/AddMedia";
import { useCollection } from "../../../../../firebase files/hooks/useCollection";

// const clients = [
//   "https://smart-laundry.developmentalphawizz.com/brand/1713966373.png",
//   "https://smart-laundry.developmentalphawizz.com/brand/1713966313.png",
//   "https://smart-laundry.developmentalphawizz.com/brand/1713966292.png",
//   "https://smart-laundry.developmentalphawizz.com/brand/1713966268.png",
//   "https://smart-laundry.developmentalphawizz.com/brand/1713966345.png",
// ];

const CorporateTwoClients = ({
  category,
  pageName,
  section,
  addMoreVideosButton,
  addMoreTextContentButton,
}) => {
  const headingRef = useRef(null);

  const { documents: dbPartnersDocument, error: dbPartnersError } =
    useCollection("media", ["section", "==", "partners"]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className="corporateTwo-home-clients">
      <div className="corporateTwo-home-clients-heading">
        <h1>Our Clients</h1>
        <h2>Trusted by 100+ Clients</h2>
      </div>

      <div className="business-three-partners-container">
        <div className="business-three-partners-marquee">
          <div className="business-three-partners-row">
            {dbPartnersDocument &&
              dbPartnersDocument
                .concat(dbPartnersDocument)
                .map((client, index) => (
                  <div
                    key={index}
                    className="business-three-partners-image-wrapper"
                  >
                    <img
                      src={client.mediaUrl}
                      alt="partners"
                      className="business-three-partners-image"
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
      <AddMedia
        category={category}
        pageName={pageName}
        section={section}
        addMoreVideosButton={addMoreVideosButton}
        addMoreTextContentButton={addMoreTextContentButton}
      />
    </div>
  );
};

export default CorporateTwoClients;

import React, { useEffect } from "react";
import "./EducationOneSuccess.scss";

const SuccessSection = () => {
  useEffect(() => {
    const stats = document.querySelectorAll(".stat");
    stats.forEach((stat, index) => {
      setTimeout(() => {
        stat.style.opacity = "1";
        stat.style.transform = "translateY(0)";
      }, index * 300);
    });
  }, []);

  return (
    <section className="success-section">
      <div className="header-content">
        <h2>Learn More About Our Success Stories</h2>
        <button className="cta-button">Our Events</button>
      </div>
      <div className="stats-wrapper">
        <div className="stat">
          <h3>85%</h3>
          <p>Happy Customers</p>
        </div>
        <div className="stat">
          <h3>78%</h3>
          <p>Workout Sessions</p>
        </div>
        <div className="stat">
          <h3>92%</h3>
          <p>Days of Program</p>
        </div>
      </div>
    </section>
  );
};

export default SuccessSection;

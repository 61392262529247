import React from 'react'

// import css 
import './CorporateOneFeature.scss'

const CorporateOneFeature = () => {
  return (
    <section className='feature_sect sect_padding' style={{
        backgroundImage: "url('/assets/img/corporate/corporateone/home/features_bg.jpg')",
    }}>
        <div className="container">
            <div className='row align-items-center'>
                <div className="col-lg-6">
                    <h6 className="small_title_with_line">
                        Happy ERP FEATURES
                    </h6>
                    <h2 className='df45 text_white semibold'>Keep Up The Sales Remote Features</h2>
                    <p className='df15 text_white regular lh26'>Team ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                    <ul class="list">
                        <li>
                            <span class="material-symbols-outlined">
                                arrow_forward
                            </span>
                            Enterprise-class  applications
                        </li>
                        <li>
                            <span class="material-symbols-outlined">
                                arrow_forward
                            </span>
                            Leading ERP vendors
                        </li>
                        <li>
                            <span class="material-symbols-outlined">
                                arrow_forward
                            </span>
                            Relentless focus on re-invention
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <div className="row" style={{
                        rowGap:"30px",
                        justifyContent:"center"
                    }}>
                        <div className="col-lg-6">
                            <div className="feature_single">
                                <div className="icon">
                                    <img src="/assets/img/icons/shopping.png" alt="" />
                                </div>
                                <h3>
                                    conduct sales meetings
                                </h3>
                                <p>
                                    Meeting ipsum dolor sit amseteturing elit sed do eiusmod tempor incididunt
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature_single">
                                <div className="icon">
                                    <img src="/assets/img/icons/feature2.png" alt="" />
                                </div>
                                <h3>
                                    conduct sales meetings
                                </h3>
                                <p>
                                    Meeting ipsum dolor sit amseteturing elit sed do eiusmod tempor incididunt
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature_single">
                                <div className="icon">
                                    <img src="/assets/img/icons/feature3.png" alt="" />
                                </div>
                                <h3>
                                    conduct sales meetings
                                </h3>
                                <p>
                                    Meeting ipsum dolor sit amseteturing elit sed do eiusmod tempor incididunt
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
  )
}

export default CorporateOneFeature

import React, { useState, useEffect } from 'react';
import './Address.scss';
import Skeleton from "react-loading-skeleton";
import { useAuthContext } from '../firebase files/hooks/useAuthContext';
import { projectFirestore } from '../firebase config/config';
import Popup from './Popup';

const Address = () => {
    const { user } = useAuthContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAddressType, setSelectedAddressType] = useState('home');
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [addressToDelete, setAddressToDelete] = useState(null);

    const [newAddress, setNewAddress] = useState({
        type: "home",
        name: "",
        houseNumber: "",
        streetLineOne: "",
        streetLineTwo: "",
        landmark: "",
        city: "",
        state: "",
        pincode: "",
        isDefault: false
    });

    const stringTransform = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const handleSaveAddress = async (e) => {
        e.preventDefault();
        const updatedAddresses = [...user.addresses];

        if (newAddress.isDefault) {
            updatedAddresses.forEach(addr => addr.isDefault = false);
        }

        if (isEditMode) {
            const index = updatedAddresses.findIndex(addr => addr === editingAddress);
            updatedAddresses[index] = { ...newAddress, type: selectedAddressType };
        } else {
            updatedAddresses.push({ ...newAddress, type: selectedAddressType });
        }

        try {
            await projectFirestore.collection("users").doc(user.uid).update({ addresses: updatedAddresses });
            setIsModalOpen(false);
            setIsEditMode(false);
            setEditingAddress(null);
            setNewAddress({
                type: "home",
                name: "",
                houseNumber: "",
                streetLineOne: "",
                streetLineTwo: "",
                landmark: "",
                city: "",
                state: "",
                pincode: "",
                isDefault: false
            });
        } catch (error) {
            console.error("Error updating address:", error);
        }
    }

    const handleEditAddress = (address) => {
        setIsEditMode(true);
        setEditingAddress(address);
        setNewAddress(address);
        setSelectedAddressType(address.type);
        setIsModalOpen(true);
    }

    const handleDeleteAddress = async (addressToDelete) => {
        setAddressToDelete(addressToDelete);
        setPopupMessage('Are you sure you want to delete this address?');
        setShowPopup(true);
    }

    const handlePopupConfirm = async (confirmed) => {
        if (confirmed && addressToDelete) {
            const updatedAddresses = user.addresses.filter(addr => addr !== addressToDelete);
            try {
                await projectFirestore.collection("users").doc(user.uid).update({ addresses: updatedAddresses });
            } catch (error) {
                console.error("Error deleting address:", error);
            }
        }
        setShowPopup(false);
        setAddressToDelete(null);
    }



    const handleSetDefaultAddress = async (addressToSetDefault) => {
        const updatedAddresses = user.addresses.map(addr => ({
            ...addr,
            isDefault: addr === addressToSetDefault
        }));
        try {
            await projectFirestore.collection("users").doc(user.uid).update({ addresses: updatedAddresses });
        } catch (error) {
            console.error("Error setting default address:", error);
        }
    }

    const addressTypes = [
        { type: 'home', icon: 'home' },
        { type: 'work', icon: 'work' },
        { type: 'other', icon: 'place' }
    ];

    return (
        <div className='address-page'>
            <div className='address-container'>
                <div className="address-header">
                    <h1>Your Addresses</h1>
                    <button onClick={() => setIsModalOpen(true)}>Add New Address +</button>
                </div>
                <div className="address-cards-container">
                    <div className='address-cards'>
                    {
                        user && user.addresses && user.addresses.map((address, index) => {
                            return (
                                <div className={`address-card ${address.isDefault ? 'default-address' : ''}`} key={index}>
                                    <div className='address-card-info'>
                                        <div className='address-card-header'>
                                            <div>
                                            <span className="material-symbols-outlined">
                                                {address.type === "home"?"home":address.type === "work"?"work":"place"}
                                            </span>
                                            <span>{stringTransform(address.type)}</span>
                                            </div>
                                            {address.isDefault ? <span className="default-label">Default</span>
                                            :
                                            <span className="set-as-default" onClick={() => handleSetDefaultAddress(address)}>Set as Default</span>
                                        }
                                        </div>
                                        <div className='address-card-body'>
                                            <h3>{address.name ? address.name : "User"}</h3>
                                            <p>{address.houseNumber} <br /> {address.streetLineOne} <br /> {address.streetLineTwo} <br /> {address.landmark} <br />
                                                {stringTransform(address.city)}, {address.pincode}
                                                <br />
                                                {stringTransform(address.state)}, INDIA
                                            </p>
                                        </div>
                                    </div>
                                    <div className='address-card-actions'>
                                        
                                        <button className='address-action-btn address-edit-btn' onClick={() => handleEditAddress(address)}>
                                            <span className="material-symbols-outlined">edit</span>
                                        </button>
                                        <button className='address-action-btn address-delete-btn' onClick={() => handleDeleteAddress(address)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </button>
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    
                </div>
            </div>
            {
                isModalOpen
                &&
                <div className="address-modal-overlay">
                    <div className="address-modal">
                        <div className="address-modal-header">
                        <h2>{isEditMode ? 'Edit Address' : 'Add New Address'}</h2>
                        <div className="address-type-tabs">
                            {addressTypes.map(({ type, icon }) => (
                                <button
                                    key={type}
                                    className={`address-type-tab ${selectedAddressType === type ? 'active' : ''}`}
                                    onClick={() => setSelectedAddressType(type)}
                                >
                                    <span className="material-symbols-outlined">{icon}</span>
                                    {stringTransform(type)}
                                </button>
                            ))}
                        </div>
                        </div>
                        
                        <form className="address-form" onSubmit={handleSaveAddress}>
                            <div className="form-column">
                                <label>Name</label>
                                <input
                                    type="text"
                                    placeholder="Full Name"
                                    required
                                    value={newAddress.name}
                                    onChange={(e) => setNewAddress({ ...newAddress, name: e.target.value })}
                                />
                                <label>House/Flat/Apartment/Office No.</label>
                                <input
                                    type="text"
                                    placeholder="House/Flat/Apartment/Office No."
                                    required
                                    value={newAddress.houseNumber}
                                    onChange={(e) => setNewAddress({ ...newAddress, houseNumber: e.target.value })}
                                />
                                <label>Street Line 1</label>
                                <input
                                    type="text"
                                    placeholder="Street Line 1"
                                    required
                                    value={newAddress.streetLineOne}
                                    onChange={(e) => setNewAddress({ ...newAddress, streetLineOne: e.target.value })}
                                />
                                <label>Street Line 2</label>
                                <input
                                    type="text"
                                    placeholder="Street Line 2"
                                    value={newAddress.streetLineTwo}
                                    onChange={(e) => setNewAddress({ ...newAddress, streetLineTwo: e.target.value })}
                                />
                            </div>
                            <div className="form-column">
                                <label>Landmark</label>
                                <input
                                    type="text"
                                    placeholder="Landmark"
                                    value={newAddress.landmark}
                                    onChange={(e) => setNewAddress({ ...newAddress, landmark: e.target.value })}
                                />
                                <label>City</label>
                                <input
                                    type="text"
                                    placeholder="City"
                                    required
                                    value={newAddress.city}
                                    onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                                />
                                <label>State</label>
                                <input
                                    type="text"
                                    placeholder="State"
                                    required
                                    value={newAddress.state}
                                    onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                                />
                                <label>Pincode</label>
                                <input
                                    type="text"
                                    placeholder="Pincode"
                                    required
                                    value={newAddress.pincode}
                                    onChange={(e) => setNewAddress({ ...newAddress, pincode: e.target.value })}
                                />
                            </div>
                            <div className="form-actions">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={newAddress.isDefault}
                                        onChange={(e) => setNewAddress({ ...newAddress, isDefault: e.target.checked })}
                                    />
                                    Set as default address
                                </label>

                                <div>
                                <button type="submit">{isEditMode ? 'Update' : 'Save'} Address</button>
                                <button type="button" onClick={() => {
                                    setIsModalOpen(false);
                                    setIsEditMode(false);
                                    setEditingAddress(null);
                                    setSelectedAddressType("home");
                                    setNewAddress({
                                        type: "home",
                                        name: "",
                                        houseNumber: "",
                                        streetLineOne: "",
                                        streetLineTwo: "",
                                        landmark: "",
                                        city: "",
                                        state: "",
                                        pincode: "",
                                        isDefault: false
                                    });
                                }}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
            <Popup
                showPopupFlag={showPopup}
                setShowPopupFlag={setShowPopup}
                msg={popupMessage}
                setPopupReturn={handlePopupConfirm}
            />
        </div>
    )
}

export default Address;
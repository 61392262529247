import React from 'react';
import './EducationOneServices.scss'; // Include your SCSS for styling

function ERPSection() {
  return (
    <div className="erp-section">
      <div className="container">
     
       

      {/* Main Section */}
      <main className="erp-main">
        <h1>Why We're Awesome?</h1>
        <p>
          With more than 16 years of experience in this industry, we know what it’s like to be at every stage of a growing business.
        </p>

        {/* Cards Section */}
        <div className="erp-cards">
          <div className="erp-card">
            <img src="https://via.placeholder.com/400x200" alt="User Interface" />
            <div className="card-content">
              <h3>Simple Straight Forward User Interface Ect</h3>
              
            </div>
          </div>
          <div className="erp-card">
            <img src="https://tse2.mm.bing.net/th?id=OIP.JhcVttJ2_t-ecl4Wom-oWwHaFp&pid=Api&P=0&h=220" alt="Developer Platform" />
            <div className="card-content">
              <h3>Easy Access</h3>
            </div>
          </div>
          <div className="erp-card">
            <img src="https://tse2.mm.bing.net/th?id=OIP.JhcVttJ2_t-ecl4Wom-oWwHaFp&pid=Api&P=0&h=220" alt="Migration Wizard" />
            <div className="card-content">
              <h3>Migration Wizard To Data From Spreadly</h3>
            </div>
          </div>
          <div className="erp-card">
            <img src="https://via.placeholder.com/400x200" alt="Customer Support" />
            <div className="card-content">
              <h3>Customer Support with High Satisfaction</h3>
            </div>
          </div>
          <div className="erp-card">
            <img src="https://via.placeholder.com/400x200" alt="Customer Support" />
            <div className="card-content">
              <h3>Customer Support with High Satisfaction</h3>
            </div>
          </div>
          <div className="erp-card">
            <img src="https://via.placeholder.com/400x200" alt="Customer Support" />
            <div className="card-content">
              <h3>Customer Support with High Satisfaction</h3>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  );
}

export default ERPSection;

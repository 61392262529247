import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./BusinessThreeNavbar.scss";
import { Link } from "react-router-dom";
import DarkModeToggle from "react-dark-mode-toggle";
import SidebarNew from "../../../../default components/SidebarNew";

// FIREBASE FILES
import { useDocument } from "../../../../firebase files/hooks/useDocument";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import { projectStorage } from "../../../../firebase config/config";

const BusinessThreeNavbar = () => {
    // Scroll to the top of the page whenever the location changes start
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const { user } = useAuthContext();
    const navigate = useNavigate();
    // for show active tab with diff color
    const [activeMenu, setActiveMenu] = useState("dashboard");

    const { document: dbOrganizationData, error: dbOrganizationDataError } = useDocument("settings", "organisation");
    const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
        "settings",
        "EditNavbarMenus"
    );
    const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
        "settings",
        "navbarType"
    );
    const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
        "settings",
        "AppType"
    );
    const { document: pageControlDoc, error: pageControlDocError } = useDocument(
        "settings",
        "pagesControl"
    );

    const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
    useDocument("settings", "themePalette");

    const showHome = () => {
        navigate("/");
    };

    const showSecondPage = () => {
        navigate("/products");
    };

    const showThirdPage = () => {
        navigate("/contact");
    };

    // const showFourthPage = () => {
    //     navigate("/more");
    // };

    const showFifthPage = () => {
        navigate("/career");
    };

    const showSixthPage = () => {
        navigate("/customerdashboard");
    };

    // MENU NAMES
    let firstMenuIcon = "";
    let firstMenu = ""; //This is for all user type
    let secondMenuIcon = "";
    let secondMenu = "";
    let thirdMenuIcon = "";
    let thirdMenu = "";
    let fourthMenu = "";
    let fourthMenuIcon = "";
    let fifthMenu = "";
    let fifthMenuIcon = "";
    let sixthMenu = "";
    let sixthMenuIcon = "";

    if (user) {
        firstMenuIcon = "home";
        firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
        secondMenuIcon = "local_mall";
        secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
        thirdMenuIcon = "support_agent";
        thirdMenu = NavMenuNames && NavMenuNames.thirdMenuName;
        fourthMenuIcon = "apps";
        fourthMenu = NavMenuNames && NavMenuNames.fourthMenuName;
        fifthMenuIcon = "celebration";
        fifthMenu = NavMenuNames && NavMenuNames.fifthMenuName;
        sixthMenuIcon = "insert_chart";
        sixthMenu = "Dashboard";
    }

    if (!user) {
        firstMenuIcon = "home";
        firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
        secondMenuIcon = "local_mall";
        secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
        thirdMenuIcon = "support_agent";
        thirdMenu = NavMenuNames && NavMenuNames.thirdMenuName;
        fourthMenuIcon = "apps";
        fourthMenu = NavMenuNames && NavMenuNames.fourthMenuName;
        fifthMenuIcon = "celebration";
        fifthMenu = NavMenuNames && NavMenuNames.fifthMenuName;
        sixthMenuIcon = "insert_chart";
        sixthMenu = "Dashboard";
    }

    // START CODE FOR LIGHT/DARK MODE
    const toggleUserDarkMode = async () => {
        const currentModeStatus = localStorage.getItem("mode");

        const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
        localStorage.setItem("mode", newDisplayMode);
        window.dispatchEvent(new Event("storage"));
    };
    // END CODE FOR LIGHT/DARK MODE
    return (
        <>
            {navTypeDoc && navTypeDoc.activeNav == "secondaryNav" ? (
                <div className="business-three-nav-navbar sticky-top" 
                style={{
                    background: dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette"
                    ? "linear-gradient(to right, #F57000, #F5BF00)"
                    : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette"
                    ? "var(--page-bg)"
                    : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette"
                    ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
                       radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
                       radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
                       radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
                       radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
                       radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
                       radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
                       radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
                       radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
                       radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
                    : null
                  }}
                >
                    <div className="business-three-nav-logo-column">
                        <div className="business-three-nav-navbar-logo-container d-flex align-items-center">
                            <Link to="/">
                                <img
                                    src={
                                        dbOrganizationData && dbOrganizationData.shortLogo
                                    }
                                    alt="logo"
                                    className={
                                        dbOrganizationData && dbOrganizationData.full_name === ""
                                            ? "navbar-logo-only-without-title"
                                            : "navbar-logo-only-with-title"
                                    }
                                /></Link>
                            <p className="nav_logo_text">
                                {dbOrganizationData && dbOrganizationData.full_name}
                            </p>
                        </div>
                    </div>
                    <div className="default-navbar-small">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div></div>

                            {(appTypeDetails &&
                                appTypeDetails.apptype === "liteplus") ||
                                (appTypeDetails && appTypeDetails.apptype === "pro" && (
                                    <div className="d-flex">
                                        <Link to="/ticketdetail">
                                            <div className="navbar-notification-div">
                                                <span class="material-symbols-outlined">
                                                    support_agent
                                                </span>

                                                <div></div>
                                            </div>
                                        </Link>
                                        <Link to="/notifications">
                                            <div className="navbar-notification-div">
                                                <span className="material-symbols-outlined">
                                                    notifications
                                                </span>
                                                <div></div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="business-three-nav-menu-column">
                        <nav>
                            <div
                                onClick={showHome}
                                className={`${location.pathname === "/" ? "business_three_nav_active_menu" : "business_three_nav_inactive_menu"
                                    } `}
                            >
                                <span className="material-symbols-outlined">
                                    {firstMenuIcon}
                                </span>
                                <h5>{firstMenu}</h5>
                            </div>

                            {pageControlDoc && pageControlDoc.products === true && (
                                <div
                                    onClick={showSecondPage}
                                    className={`${location.pathname === "/products"
                                        ? "business_three_nav_active_menu"
                                        : "business_three_nav_inactive_menu"
                                        } `}
                                >
                                    <span className="material-symbols-outlined">
                                        {secondMenuIcon}
                                    </span>
                                    <h5>{secondMenu}</h5>
                                </div>
                            )}

                            {NavMenuNames && NavMenuNames.getQuoteButton == false && (
                                <div
                                    onClick={showThirdPage}
                                    className={`${location.pathname === "/contact"
                                        ? "business_three_nav_active_menu"
                                        : "business_three_nav_inactive_menu"
                                        } `}
                                >
                                    <span className="material-symbols-outlined">
                                        {thirdMenuIcon}
                                    </span>
                                    <h5>{thirdMenu}</h5>
                                </div>
                            )}

                            {pageControlDoc && pageControlDoc.career === true && (
                                <div
                                    onClick={showFifthPage}
                                    className={`${location.pathname === "/career"
                                        ? "business_three_nav_active_menu"
                                        : "business_three_nav_inactive_menu"
                                        } `}
                                >
                                    <span className="material-symbols-outlined">
                                        {fifthMenuIcon}
                                    </span>
                                    <h5>{fifthMenu}</h5>
                                </div>
                            )}

                            {user && <div
                                onClick={showSixthPage}
                                className={`${location.pathname === "/customerdashboard"
                                    ? "business_three_nav_active_menu"
                                    : "business_three_nav_inactive_menu"
                                    } `}
                            >
                                <span className="material-symbols-outlined">
                                    {sixthMenuIcon}
                                </span>
                                <h5>{sixthMenu}</h5>
                            </div>}

                            {/*START FOURTH PAGE */}
                            {/* <div
                                onClick={showFourthPage}
                                className={`${location.pathname === "/more" ? "business_three_nav_active_menu" : "business_three_nav_inactive_menu"
                                    } `}
                            >
                                <span className="material-symbols-outlined">
                                    {fourthMenuIcon}
                                </span>
                                <h5>{fourthMenu}</h5>
                            </div> */}
                            {/*END FOURTH PAGE */}
                        </nav>
                    </div>
                    <div className="business-three-nav-icons-column">
                        <Link to="/ticketdetail" className="d-flex align-items-center">
                            <span class="material-symbols-outlined" style={{color:"var(--black-color)"}}>
                                chat_bubble
                            </span>
                        </Link>

                        <DarkModeToggle
                            onChange={() => toggleUserDarkMode()}
                            checked={localStorage.getItem("mode") === "dark"}
                            size={40}
                        />
                        <Link to="/notifications" className="d-flex align-items-center">
                            <span class="material-symbols-outlined" style={{color:"var(--black-color)"}}>
                                notifications_active
                            </span></Link>

                    </div>
                    <div className="business-three-nav-profile-column">
                        <div className="business-three-nav-profile-info">
                            {user ? (
                                <Link
                                    to="/profile"
                                    className={`${location.pathname === "/profile" ? "business-three-nav-active" : ""
                                        }`}
                                >
                                    <div className="business-three-nav-profile_icon_div">
                                        {user.photoURL === "" ? (
                                            <img
                                                src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2&_gl=1*1bbo31y*_ga*MTEyODU2MDU1MS4xNjc3ODEwNzQy*_ga_CW55HF8NVT*MTY4NjIzODcwMC42OS4xLjE2ODYyMzkwMjIuMC4wLjA."
                                                alt=""
                                                className="business-three-nav-profile_icon"
                                            />
                                        ) : (
                                            <img src={user.photoURL} alt="" className="business-three-nav-profile_icon" />
                                        )}
                                        <h6>Hi, {user.fullName.split(' ')[0]}</h6>
                                    </div>

                                </Link>
                            ) : (
                                <Link to="/profile">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ gap: "5px", border: "2px solid var(--click-color)", borderRadius: "20px", padding: "5px 10px" }}
                                    >
                                        <span className="material-symbols-outlined">
                                            login
                                        </span>
                                        Login
                                    </div>
                                </Link>
                            )}
                        </div>
                        {NavMenuNames && NavMenuNames.getQuoteButton == true && (
                            <Link to="/contact">
                                <button className="business-three-nav-create-story-button">Get Quote
                                    <span class="material-symbols-outlined ba_animation">
                                        expand_circle_right
                                    </span>
                                </button></Link>
                        )}
                    </div>
                </div>
            ) : (
                <SidebarNew
                    navigate={navigate}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                />
            )}</>

    );
};

export default BusinessThreeNavbar;

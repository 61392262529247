import React, { useState, useEffect, useRef } from "react";
import "./BusinessThreeFaq.scss";

const BusinessThreeFaq = ({ id }) => {
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [displayAnswer, setDisplayAnswer] = useState("");
  const typingRef = useRef(null);
  const headingRef = useRef(null);

  const faqs = [
    {
      question: "What is your return policy?",
      answer:
        "Our return policy is that you can return items within 30 days of purchase. The items should be unused and in their original packaging. For more detailed information, please refer to our return policy page. We strive to make returns as easy as possible for our customers. If you have any issues, please contact our support team. They are available 24/7 to assist you with your return process. We hope this policy helps you shop with confidence.",
    },
    {
      question: "How do I track my order?",
      answer:
        "You can track your order by logging into your account and navigating to the order history section. There, you will find a tracking number that you can use to get updates on the delivery status of your order. Additionally, you will receive email notifications at every step of the shipping process. If you encounter any issues, please don't hesitate to reach out to our support team. They are here to ensure your shopping experience is smooth and satisfactory.",
    },
    {
      question: "Can I purchase items again?",
      answer:
        "Yes, you can purchase items again by visiting the product page and clicking on the 'Buy Again' button. This feature is available for all products in our store. It makes repurchasing your favorite items quick and easy. Additionally, you can add items to your wishlist to keep track of products you may want to buy in the future. Our aim is to provide you with a seamless and convenient shopping experience every time you visit our store.",
    },
  ];

  const preprocessAnswer = (answer) => {
    return answer
      .split("\n")
      .map((line) => (line.trim().length > 0 ? " " + line : line))
      .join("\n");
  };

  const handleFaqClick = (index) => {
    if (index !== selectedFaq) {
      setSelectedFaq(index);
      setDisplayAnswer("");
    }
  };

  useEffect(() => {
    if (selectedFaq !== null) {
      const answer = preprocessAnswer(faqs[selectedFaq].answer);
      let i = 0;

      if (typingRef.current) {
        clearTimeout(typingRef.current);
      }

      const typeAnswer = () => {
        if (i < answer.length) {
          setDisplayAnswer((prev) => prev + answer.charAt(i));
          i++;
          typingRef.current = setTimeout(typeAnswer, 10);
        }
      };

      typeAnswer();
    }
  }, [selectedFaq]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className="business-three-faq-container-parent" id={id}>
      <div className="small_padding_div"></div>
      <div className="container business-three-box-container">
        <div ref={headingRef} className="business-three-heading-div">
          <h3>
            <span>FAQ</span> What You Need to Know
          </h3>
        </div>
        <div className="business-three-template-view-more-icon">
          <span class="material-symbols-outlined">arrow_forward_ios</span>
        </div>
      </div>
      <div className="container business-three-faq-container">
        <div className="row">
          <div className="col-12 col-md-6 business-three-faq-questions">
            {faqs.map((faq, index) => (
              <div
                key={index}
                onClick={() => handleFaqClick(index)}
                className={`business-three-faq-question ${
                  selectedFaq === index ? "active" : ""
                }`}
              >
                {faq.question}
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </div>
            ))}
          </div>
          <div className="col-12 col-md-6 business-three-faq-answer">
            {selectedFaq == null ? (
              <div className="business-three-faq-answer-content">
                <h5>
                  World class
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    DRY CLEAN & LAUNDRY{" "}
                  </span>
                  service,
                  <span style={{ fontWeight: "700" }}> NOW IN INDIA </span>
                </h5>
                <hr />
                World Renowned Machines German Eco Friendly Cleaning Solutions
                <br />
                Upto 25% OFF On First Order
                <hr />
                <button className="btn_fill">More</button>
              </div>
            ) : (
              <div className="business-three-faq-answer-content">
                {displayAnswer}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="small_padding_div"></div>
    </div>
  );
};

export default BusinessThreeFaq;

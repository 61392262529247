import React from "react";
import "./PGBusinessThreeHome.scss";
import BusinessThreeAboutUs from "../businessThreeComponents/BusinessThreeAboutUs"
import BusinessThreePartners from "../businessThreeComponents/BusinessThreePartners";
import BusinessThreeFaq from "../businessThreeComponents/BusinessThreeFaq";
import BusinessThreeWhyUs from "../businessThreeComponents/BusinessThreeWhyUs";
import BusinessThreeServices from "../businessThreeComponents/BusinessThreeServices";
import BusinessThreeTestimonials from "../businessThreeComponents/BusinessThreeTestimonials";
import BusinessThreeNewsAndBlogs from "../businessThreeComponents/BusinessThreeNewsAndBlogs";
import BusinessThreeDownloadApp from "../businessThreeComponents/BusinessThreeDownloadApp";
import BusinessThreeTeam from "../businessThreeComponents/BusinessThreeTeam";
import { useDocument } from "../../../../firebase files/hooks/useDocument";


// IMPORT BANNERS
import BusinessOneBanner from "../../businessOne/businessOneComponents/BusinessOneBanner"
import BusinessThreeBanners from "../businessThreeComponents/BusinessThreeBanners";
import HomeHeroBanners from "../../../../default components/HomeHeroBanners";
import CorporateTwoBanner from "../../../corporate/corporateTwo/corporateTwoComponents/CorporateTwoBanner/CorporateTwoBanner";
import CorporateOneBanner from "../../../corporate/corporateOne/corporateOneComponent/CorporateOneBanner";

const PGBusinessThreeHome = () => {

  // BANNERS DOCUMENT
  const { document: dbBannersDocuments, error: dbBannersError } =
    useDocument("settings", "banners");


  // ACTIVE TEMPLATE DOCUMENT
  const { document: dbActiveTemplateDocuments, error: dbActiveTemplateError } =
    useDocument("settings", "templates");

  let activeTemplate = dbActiveTemplateDocuments && dbActiveTemplateDocuments.activeTemplate;

  // Mapping activeBanner values to components
  const bannerComponents = {
    HomeHeroBanners: HomeHeroBanners,
    BusinessOneBanner:BusinessOneBanner,
    BusinessThreeBanners: BusinessThreeBanners,
    CorporateOneBanner:CorporateOneBanner,
    CorporateTwoBanner:CorporateTwoBanner,
  };

  return (
    <div>

      {dbBannersDocuments &&
        dbBannersDocuments.banners.map((banner, index) => {
          if (banner.templateName === activeTemplate) {
            const BannerComponent = bannerComponents[banner.activeBanner];
            return (
              <div key={index}>
                {BannerComponent && <BannerComponent 
                  category="banner"
                  pageName="home"
                  section="homeherobanners"
                  addMoreVideosButton={false}
                  addMoreTextContentButton={false} />}
              </div>
            );
          } else {
            return null;
          }
        })}


      <BusinessThreeAboutUs />
      <div className="large_margin_div"></div>
      <BusinessThreeServices />
      <div className="large_margin_div"></div>
      <BusinessThreeTestimonials />
      <BusinessThreeFaq />
      <BusinessThreeWhyUs />
      <BusinessThreeDownloadApp />
      <BusinessThreeNewsAndBlogs />
      <BusinessThreeTeam />
      <BusinessThreePartners />

      {/* <div className="large_margin_div"></div> */}
    </div>
  );
};

export default PGBusinessThreeHome;

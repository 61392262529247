import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PartnerSlider from "../../default components/PartnerSlider";
const TermsAndCondition = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <div className="top_header_pg pa_bg">
        <div className="pa_inner_page info_in_ul_li max-991">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2>Terms and Conditions</h2>
            <p>
              Welcome to our platform! By using our services, you agree to abide
              by our Terms and Conditions.
            </p>
          </div>
          <ul className="pvcy_polcy_head_ul_div">
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">info</span> &nbsp;
                <b> Acceptance of Terms</b>
              </div>

              <p>
                By using our app, you agree to comply with our Terms and
                Conditions. If you disagree, please refrain from using our
                platform.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">quiz</span> &nbsp;
                <b> User Conduct</b>{" "}
              </div>
              <p>
                Users must adhere to ethical and legal standards. we reserve the
                right to terminate accounts violating our guidelines.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">lock</span> &nbsp;
                <b> Intellectual Property</b>{" "}
              </div>
              <p>
                All content on app is protected by intellectual property laws.
                Users may not reproduce, distribute, or use content without
                permission.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">cookie</span> &nbsp;
                <b> Limitation of Liability</b>
              </div>
              <p>
                We are not liable for any direct, indirect, or consequential
                damages arising from platform use.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">alt_route</span> &nbsp;
                <b> Termination</b>
              </div>
              <p>
                We reserve the right to terminate or suspend accounts for any
                reason without notice.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">update</span> &nbsp;
                <b> Governing Law</b>
              </div>
              <p>
                These terms are governed by indian goverments laws. Any disputes
                will be resolved through arbitration.
              </p>
            </li>
            <li style={{ marginTop: "20px" }}>
              <div className="d-flex align-items-center">
                <span class="material-symbols-outlined">update</span> &nbsp;
                <b> Updates to Terms</b>
              </div>
              <p>
                We may update our Terms and Conditions. Users are responsible
                for reviewing changes.
              </p>
            </li>
          </ul>
        </div>
      </div>
      {/* Our Partner section */}
      <PartnerSlider
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreTextContentButton={false}
      />
    </div>
  );
};

export default TermsAndCondition;

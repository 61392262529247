import React, { useState, useEffect } from "react";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import "./ContactUs.css";
import CorporateTwoClients from "../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import { BeatLoader } from "react-spinners";
import CorporateTwoDownloadApp from "../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoDownloadApp/CorporateTwoDownloadApp";

const ContactUs = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const { document: contactDetails } = useDocument("settings", "contactUs");
  const { document } = useDocument("settings", "organisation");
  const { addDocument, response } = useFirestore("enquiries");

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [optionType, setOptionType] = useState("General Inquiries");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = projectFirestore.collection("users").doc(user.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();
          setFormData({
            fullName: userData.fullName || "",
            email: userData.email || "",
            phoneNumber: userData.phoneNumber || "",
            message: "",
          });
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);

    const contactconfirm = {
      ...formData,
      issueType: optionType,
      createdAt: timestamp.fromDate(new Date()),
    };

    await addDocument(contactconfirm);
    setIsPending(false);
    setSuccessMessage(
      "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
    );
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="pg_contact_us">
        <section className="loc_em_ph">
          <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
            <h2
              className="map_heading"
              style={{ textAlign: "center", color: "white" }}
            >
              {document && document.short_name}
            </h2>
          </div>
          <div className="container">
            <div
              className="loc_em_ph_inner"
              style={{
                backgroundImage: "url('./assets/img/counterbg.png')",
                color: "var(--black-color)",
              }}
            >
              <div className="lep_single">
                <div>
                  <div className="icon_div ">
                    <span className="material-symbols-outlined ">home</span>
                  </div>
                  <h4>Address</h4>
                </div>
                <h6 className="lep_single_address">
                  {document && document.address}
                </h6>
              </div>

              <div className="lep_single">
                <a
                  href={` mailto:${
                    contactDetails && contactDetails.contactEmail
                  }`}
                  style={{ color: "var(--black-color)" }}
                >
                  <div>
                    <div className="icon_div">
                      <span className="material-symbols-outlined ">
                        outgoing_mail
                      </span>
                    </div>
                    <h4>Email</h4>
                    <h6>{contactDetails && contactDetails.contactEmail}</h6>
                  </div>
                </a>
              </div>

              <div className="lep_single">
                <a
                  href={
                    "tel:" + (contactDetails && contactDetails.contactNumber)
                  }
                  style={{ color: "var(--black-color)" }}
                >
                  <div>
                    <div className="icon_div">
                      <span className="material-symbols-outlined ">
                        deskphone
                      </span>
                    </div>
                    <h4>Phone</h4>
                    <h6>{contactDetails && contactDetails.contactNumber}</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="contact-container">
            <div className="contact-image">
              <img
                src="/assets/img/contact-us.jfif"
                alt="Contact"
              />
            </div>

            <div className="contact-form">
              {successMessage ? (
                <div className="thank-you-message">
                  <h2>Thank You!</h2>
                  <p>{successMessage}</p>
                </div>
              ) : (
                <>
                  <h2>Contact Us</h2>
                  {/* <div className="contact-form-fixed-details">
                    <div>
                      <p>PHONE:</p>
                      <p>
                        <b>{contactDetails && contactDetails.contactNumber}</b>
                      </p>
                    </div>
                    <div>
                      <p>EMAIL:</p>
                      <p>
                        <b>{contactDetails && contactDetails.contactEmail}</b>
                      </p>
                    </div>
                  </div> */}

                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Name"
                      value={formData.fullName}
                      onChange={handleInputChange}
                      disabled={!!user}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled={!!user}
                    />
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Mobile"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      disabled={!!user}
                    />
                    <textarea
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="d-flex justify-content-center align-items-center">
                      {isPending ? (
                        <BeatLoader color="var(--logo-primary-color)" />
                      ) : (
                        <button type="submit" className="btn_fill">
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </section>

        <section className="form_sec">
          <h2 className="map_top_heading">{document && document.short_name}</h2>
          <iframe
            src={contactDetails && contactDetails.contactmap}
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </section>
        <CorporateTwoClients
          category="partners"
          pageName="contact"
          section="partners"
          addMoreVideosButton={false}
          addMoreImagesButton={true}
          addMoreTextContentButton={false}
        />
        <CorporateTwoDownloadApp/>
      </div>
    </>
  );
};

export default ContactUs;

import React from "react";
import "./CorporateTwoHome.scss";
import CorporateTwoAbout from "../corporateTwoComponents/CorporateTwoAbout/CorporateTwoAbout";
import CorporateTwoServiceTwo from "../corporateTwoComponents/CorporateTwoServiceTwo/CorporateTwoServiceTwo";
// import CorporateTwoSteps from '../corporateTwoComponents/CorporateTwoSteps/CorporateTwoSteps'
import CorporateTwoNumber from "../corporateTwoComponents/CorporateTwoNumber/CorporateTwoNumber";
import CorporateTwoBlogs from "../corporateTwoComponents/CorporateTwoBlogs/CorporateTwoBlogs";
import CorporateTwoFooter from "../corporateTwoComponents/CorporateTwoFooter/CorporateTwoFooter";
import CorporateTwoFAQ from "../corporateTwoComponents/CorporateTwoFAQ/CorporateTwoFAQ";
import CorporateTwoTestimonials from "../corporateTwoComponents/CorporateTwoTestimonials/CorporateTwoTestimonials";
import { useDocument } from "../../../../firebase files/hooks/useDocument";

// IMPORT BANNERS
import BusinessOneBanner from "../../../business/businessOne/businessOneComponents/BusinessOneBanner";
import BusinessThreeBanners from "../../../business/businessThree/businessThreeComponents/BusinessThreeBanners";
import HomeHeroBanners from "../../../../default components/HomeHeroBanners";
import CorporateTwoBanner from "../../../corporate/corporateTwo/corporateTwoComponents/CorporateTwoBanner/CorporateTwoBanner";
import CorporateOneBanner from "../../../corporate/corporateOne/corporateOneComponent/CorporateOneBanner";
import CorporateTwoProcess from "../corporateTwoComponents/CorporateTwoProcess/CorporateTwoProcess";
import CorporateTwoClients from "../corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import CorporateTwoCallus from "../corporateTwoComponents/CorporateTwoCallus/CorporateTwoCallus";
import CorporateTwoDownloadApp from "../corporateTwoComponents/CorporateTwoDownloadApp/CorporateTwoDownloadApp";

const CorporateTwoHome = () => {
  // BANNERS DOCUMENT
  const { document: dbBannersDocuments, error: dbBannersError } = useDocument(
    "settings",
    "banners"
  );

  // ACTIVE TEMPLATE DOCUMENT
  const { document: dbActiveTemplateDocuments, error: dbActiveTemplateError } =
    useDocument("settings", "templates");

  let activeTemplate =
    dbActiveTemplateDocuments && dbActiveTemplateDocuments.activeTemplate;

  // Mapping activeBanner values to components
  const bannerComponents = {
    HomeHeroBanners: HomeHeroBanners,
    BusinessOneBanner: BusinessOneBanner,
    BusinessThreeBanners: BusinessThreeBanners,
    CorporateOneBanner: CorporateOneBanner,
    CorporateTwoBanner: CorporateTwoBanner,
  };

  return (
    <div>
      {/* <CorporateTwoBanner/> */}
      {dbBannersDocuments &&
        dbBannersDocuments.banners.map((banner, index) => {
          if (banner.templateName === activeTemplate) {
            const BannerComponent = bannerComponents[banner.activeBanner];
            return (
              <div key={index}>
                {BannerComponent && (
                  <BannerComponent
                    category="banner"
                    pageName="home"
                    section="homeherobanners"
                    addMoreVideosButton={false}
                    addMoreTextContentButton={false}
                  />
                )}
              </div>
            );
          } else {
            return null;
          }
        })}

      <CorporateTwoAbout />
      <CorporateTwoServiceTwo />
      {/* <CorporateTwoSteps/> */}
      <CorporateTwoProcess />
      <CorporateTwoNumber />
      <CorporateTwoBlogs />
      <CorporateTwoTestimonials />
      <CorporateTwoFAQ />
      <CorporateTwoCallus />
      <CorporateTwoClients
        category="partners"
        pageName="home"
        section="partners"
        addMoreVideosButton={false}
        addMoreImagesButton={true}
        addMoreTextContentButton={false}
      />
      <CorporateTwoDownloadApp />
      {/* <CorporateTwoFooter/> */}
    </div>
  );
};

export default CorporateTwoHome;

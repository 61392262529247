import React from "react";
import "./EventOneArtist.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Artist = () => {
  const options = {
    items: 3,
    dots: false,
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 1500,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      // Define breakpoints and the number of items to show at each breakpoint
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 4,
      },
      1199: {
        items: 6,
      },
    },
  };
  return (
    <div className="Artist_Events">
      <div className="container">
        <div className="artist_inner">
          <div className="section_header">
            <div className="left">
              <div className="icon">
                <span className="music-icon material-symbols-outlined">
                  music_note
                </span>
              </div>
              <div>
                <h2 className="title">Artist</h2>
              </div>
            </div>
            <div className="right">
            <button className="btn_inr">View All</button>
            </div>
          
          </div>
          <div className="event-container_artist">
          
            <OwlCarousel className="owl-theme my_owl_carousel" {...options}>
            <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist1.jpg"
                    className="event-image_artist"
                  />
                </div>
                  <h5 className="contain_artist_name">arijit singh</h5>
               

              
            </div>
                
              <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist2.jpg"
                    className="event-image_artist"
                  />
                  <h5 className="contain_artist_name">arijit singh</h5>
               

                  </div>
                </div>
                
              
              <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist3.jpg"
                    className="event-image_artist"
                  />
                  <h5 className="contain_artist_name">arijit singh</h5>
               


                </div>
              </div>
              <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist4.jpg"
                    className="event-image_artist"
                  />
                  <h5 className="contain_artist_name">arijit singh</h5>
               

    
                </div>
              </div>
              <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist5.png"
                    className="event-image_artist"
                  />
                  <h5 className="contain_artist_name">arijit singh</h5>
               

                </div>
              </div>
              <div className="item event-card_artist ">
                <div className="image-container_artist">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/artist6.jpg"
                    className="event-image_artist"
                  />
                  <h5 className="contain_artist_name">arijit singh</h5>
               
               

                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artist;

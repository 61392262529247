import React, { Component } from "react";
import "./EducationOneBannerTwo.scss";

class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the section is visible
    };

    this.observer = new IntersectionObserver(this.handleObserver, options);
    this.observer.observe(this.sectionRef.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleObserver = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  render() {
    const { isVisible } = this.state;

    return (
      <section
        ref={this.sectionRef}
        className={`EducationOneBannerTwo_banner-section ${
          isVisible ? "start-animation" : ""
        }`}
      >
        <div className="container">
          <div className="EducationOneBannerTwo_content-wrapper">
            <div className="EducationOneBannerTwo_left">
              <div className="text-content">
                <p className="log_head">TutorTot</p>
                <p className="sub-heading">Learn New Things Daily 
                  On One Platform</p>
                <h1 className="main-heading">
                  Education Is A Path To Success In Life
                </h1>
                <p className="description">
                  Empower yourself with the knowledge and skills gained through
                  online education and best instructors.
                </p>
                <button className="cta-button">Get Started Today</button>
              </div>
            </div>
            <div className="EducationOneBannerTwo_right">
              <div className="image-container">
                <img
                  src="https://demo.themexpert.com/wordpress/edumodo-update/learndash-v1/wp-content/uploads/sites/10/2023/01/3.jpg"
                  className="main_image"
                
                  alt="Main Background"
                />
                <img
                  src="https://eduvibe.devsvibe.com/main/wp-content/uploads/2022/10/hero-04-round-2.png"
                  alt="Learner"
                  className="hero-image"
                />
                <div className="badge">
                  <span className="badge-number">100%</span>
                  <span className="badge-text">Satisfaction</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroSection;

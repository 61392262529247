import React from 'react'
import { Link } from 'react-router-dom'


// import css 
import './CorporateOneFooter.scss'

const CorporateFooterBottom = () => {
  return (
    <div className="copyright">
    <div className="container">
        <div className="row">
            <div className="col-6">
                <p className="df16 text_white">
                    Copyright @2024 <span className='text_orange'>Happy ERP</span>. All Rights Reserved by <span className="text_orange">
                        Happy ERP
                    </span>
                </p>
            </div>
            <div className="col-6">
                <p className="text_white text-end">
                    Design & Developed By <Link to="https://hyperclouddigital.com/" className='text_orange' target='_blank'>Hyper Cloud Digital Solution</Link>
                </p>
            </div>
        </div>
    </div>
</div>
  )
}

export default CorporateFooterBottom

import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

function TypographyDemo(props) {
  return (
    <div>
      <Typography component="div">
        <Skeleton variant="text" height={80} />
      </Typography>
      <Typography component="div">
        <Skeleton variant="text" height={80} />
      </Typography>
      <Typography component="div">
        <Skeleton variant="text" height={80} />
      </Typography>
      <Typography component="div">
        <Skeleton variant="text" height={80} />
      </Typography>
    </div>
  );
}

TypographyDemo.propTypes = {
  loading: PropTypes.bool,
};

export default function SkeletonLoader() {
  return (
    <Grid container spacing={8}>
      <Grid item xs>
        <TypographyDemo loading />
      </Grid>
      <Grid item xs>
        <TypographyDemo />
      </Grid>
    </Grid>
  );
}

import React,{useState,useEffect} from "react";
import "./CorporateTwoTermsBody.scss";
import { useFirestore } from "../../../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import RichTextEditor from "react-rte";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";

const termsData = [
  {
    id: 1,
    termBody: "Urgent delivery of garments will be charged 100% Extra",
  },
  {
    id: 2,
    termBody:
      "We reserve the rights to cancel/modify/change the terms and conditions at any point in time without any prior intimation or notice.",
  },
  {
    id: 3,
    termBody:
      "We put in our best efforts to ensure timely pick-up and delivery; howeverthere might be incidents beyond our control or incidents of Force Majeurewhere we are unable to stick to the timelines. In such cases, customerscannot claim any compensation, refunds or any reduction in charges.",
  },
  {
    id: 4,
    termBody:
      "Customers are requested to examine garments at the time of delivery",
  },
  {
    id: 5,
    termBody:
      "We are not responsible for fastness / running of color / shrinkage / damageto embellishments / embroidery work on the garments during the process.",
  },
  {
    id: 6,
    termBody:
      "Every effort is made to remove stains, but We will not be responsible forstubborn and unremovable stains",
  },
  {
    id: 7,
    termBody: "All articles are accepted at customers risk.",
  },
  {
    id: 8,
    termBody:
      "Please note that we will not be responsible for the upkeep of the garments,if the garments are not collected within 10 days from the date of scheduled delivery.",
  },
  {
    id: 9,
    termBody:
      "We accept no liability for any loss or damage of the clothes arising due tofire, burglary or any natural causes etc.",
  },
  {
    id: 10,
    termBody:
      "The tariff of garments will be decided on a case to case basis depending on the complexity of the garments. The rates mentioned in the price list are indicative and minimal.We will undertake the cleaning of the garments, in the best possible manner that our technicians deem fit. The various cleaning methods deployed by us will vary from Wash in Emulsifier, Detergent and Softeners, Soft wash for delicate garments, Technologies like Perchloroethylene, HydroCarbon.",
  },
  {
    id: 11,
    termBody: "We are digitally active on all the social media platforms.",
  },
  {
    id: 12,
    termBody:
      "All disputes are subject to the jurisdiction of Courts in Pune only",
  },
  {
    id: 13,
    termBody: "We may use the images of your clothes for promotional purposes",
  },
  {
    id: 14,
    termBody:
      "We shall not be held responsible for shrinkage, damage, cuts, holes, scratches, stains etc. becoming apparent during the wash process due to defective manufacturing, adulteration, deterioration, wear & tear.",
  },
];

const CorporateTwoTermsBody = () => {
    const { user } = useAuthContext();
  const { updateDocument, response } = useFirestore("pages-data");
  const { document: pagesTextDataDocument, error: pagesTextDataError } =
    useDocument("pages-data", "termAndCondition");

  // State to hold the editor value
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hasChanges, setHasChanges] = useState(false);

  // Load the data into the editor when the document is fetched
  useEffect(() => {
    if (pagesTextDataDocument) {
      setValue(
        RichTextEditor.createValueFromString(
          pagesTextDataDocument.termBody,
          "html"
        )
      );
    }
  }, [pagesTextDataDocument]);

  // Function to handle save click
  const handleSaveClick = async () => {
    try {
      const content = value.toString("html");

      // Update the document in Firestore
      await updateDocument("termAndCondition", { termBody: content });

      if (response.error) {
        console.error("Error updating document:", response.error);
      } else {
        console.log("Document updated successfully");
        setHasChanges(false); // Reset changes tracking after successful save
      }
    } catch (error) {
      console.error("An error occurred while updating the document:", error);
    }
  };

  // Function to handle changes in the editor
  const handleEditorChange = (newValue) => {
    setValue(newValue);
    // Compare the current editor value with the original document to detect changes
    if (newValue.toString("html") !== pagesTextDataDocument.termBody) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };
  return (
      <div className="corporateTwo-terms-body">
        {user && user.role == "admin" && (
          <div>
            <RichTextEditor value={value} onChange={handleEditorChange} />
            {hasChanges && (
              <button
                className="product_edit_save_btn"
                onClick={handleSaveClick}
              >
                Save
              </button>
            )}
          </div>
        )}
         <p
          dangerouslySetInnerHTML={{
            __html:
              pagesTextDataDocument &&
              pagesTextDataDocument.termBody.toString("html"),
          }}
        ></p>
      </div>
  );
};

export default CorporateTwoTermsBody;

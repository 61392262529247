import React from "react";
import "./EventOneMagazine.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Magazine = () => {
  const options = {
    items: 3,
    dots: false,
    loop: true,
    margin: 30,
    nav: true,
    smartSpeed: 1500,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      // Define breakpoints and the number of items to show at each breakpoint
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
      },
      992: {
        items: 4,
      },
      1199: {
        items: 4,
      },
    },
  };
  return (

    <div className=".featured-events">
      <div className="container">
        <div className="feature_inner">
          <div className="section_header">
            <div className="left">
              <div className="icon">
                <span className="music-icon material-symbols-outlined">
                  music_note
                </span>
              </div>
              <div>
                <h2 className="title">MAGAZINE</h2>
              </div>
            </div>
            <div className="right">
            <button className="btn_inr">View All</button>
            </div>
          
          </div>
          <div className="magzine-container">
            <OwlCarousel className="owl-theme my_owl_carousel" {...options}>
            <div className="event-card">
              <div className="image-container">
                <img
                  src="./assets/img/ecommerce/ecomTwo/mag1.jpg"
                  className="event-image"
                />
                <p>
                  Aakash & Prakash Mehta's Dark- Stand up Comedy Aakash &
                  Prakash Mehta's Dark- Stand up Comedy{" "}
                </p>
                <div className="badge">MUSIC</div>
              </div>

            </div>
            <div className="event-card">
              <div className="image-container">
                <img
                  src="./assets/img/ecommerce/ecomTwo/mag2.jpg"
                  className="event-image"
                />
                <p>
                  Aakash & Prakash Mehta's Dark- Stand up Comedy Aakash &
                  Prakash Mehta's Dark- Stand up Comedy{" "}
                </p>
                <div className="badge">COMEDY</div>
              </div>

                </div>
                
            
            <div className="event-card">
              <div className="image-container">
                <img
                  src="./assets/img/ecommerce/ecomTwo/mag3.jpg"
                  className="event-image"
                />
                <p>
                  Aakash & Prakash Mehta's Dark- Stand up Comedy Aakash &
                  Prakash Mehta's Dark- Stand up Comedy{" "}
                </p>
                <div className="badge">DANCE</div>
            </div>
            </div>
            <div className="event-card">
                <div className="image-container">
                  <img
                    src="./assets/img/ecommerce/ecomTwo/mag4.jpg"
                    className="event-image"
                  />
                  <p>
                    Aakash & Prakash Mehta's Dark- Stand up Comedy Aakash &
                    Prakash Mehta's Dark- Stand up Comedy{" "}
                  </p>
                  <div className="badge">WORKSHOP</div>
              </div>
              </div>
            </OwlCarousel>
            </div>
        </div>

        
    </div>
</div>
    
  );
};

export default Magazine;

import { React } from "react";
import Faq from "../../default components/Faq";
import PartnerSlider from "../../default components/PartnerSlider";
import Team from "../../default components/Team";
// style
import "./PGFaq.css";
const PGFaq = () => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
          <h2>FAQ</h2>
          <p>Answers to Your Questions</p>
        </div>
        <div className="medium_margin_div"></div>
        <div className="faq-container">
          <div className="row">
            <div className="top_header_pg pa_bg">
              <div className="pa_inner_page info_in_ul_li max-991">
                <Faq
                  category="faq"
                  pageName="home"
                  section="faq"
                  addMoreVideosButton={false}
                  addMoreImagesButton={false}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Our Team */}
        {/* <Team
          category="team"
          pageName="home"
          section="team"
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
          addMoreImagesButton={false}
        /> */}
        {/* Our Partner section */}
        {/* <PartnerSlider
          category="partners"
          pageName="home"
          section="partners"
          addMoreVideosButton={false}
          addMoreTextContentButton={false}
        /> */}
      </div>
    </>
  );
};

export default PGFaq;

import React, { useState, useCallback } from "react";
import RecentlyClickedProducts from "../../../../default components/RecentlyClickedProducts";
import "./BusinessTwoForYou.scss";

const BusinessTwoForYou = () => {
  const [products, setProducts] = useState([]);

  const handleProductsChange = useCallback((products) => {
    setProducts(products);
  }, []);

  const firstProduct = products[0];
  const otherProducts = products.slice(1);

  return (
    <div className="business-two-for-you">
      <RecentlyClickedProducts onProductsChange={handleProductsChange} />
      {products.length > 1 && (
        <div className="container">
          <div className="large_margin_div"></div>

          <div className="row">
            <div style={{ margin: "0px 0px 20px 0px" }}>
              <div class="wrapper">
                <svg>
                  <text x="0%" y="50%" dy=".35em" text-anchor="start">
                    FOR YOU
                  </text>
                </svg>
              </div>
            </div>
            {firstProduct && (
              <div className="col-12 col-md-4 business-two-for-you-left-image">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="business-two-for-you-card business-two-for-you-shadow-sm d-flex flex-column">
                    <div className="business-two-for-you-card-img-container position-relative">
                      <img
                        className="business-two-for-you-card-img-left"
                        src={firstProduct.images}
                        alt={firstProduct.name}
                      />
                      <div className="image-overlay">
                        <div className="d-flex justify-content-between mb-2">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: firstProduct.name.toString(),
                            }}
                          ></p>
                          <h5 className="business-two-for-you-card-rating">
                            {firstProduct.rating}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h5 className="business-two-for-you-card-price">
                            40,000/-
                          </h5>
                          <h5 className="business-two-for-you-card-discount">
                            upto 20%
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-md-8 business-two-for-you-right-cards">
              <div className="row">
                {otherProducts.map((product) => (
                  <div
                    key={product.id}
                    className="col-12 col-md-12 col-lg-6"
                    style={{ margin: "10px 0px" }}
                  >
                    <div className="business-two-for-you-card business-two-for-you-shadow-sm d-flex flex-row">
                      <img
                        className="business-two-for-you-card-img-right"
                        src={product.images}
                        alt={product.name}
                      />
                      <div className="business-two-for-you-card-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product.name.toString(),
                          }}
                        ></p>
                        <h3 className="business-two-for-you-card-rating">
                          {product.rating}
                        </h3>
                        <div className="d-flex justify-content-between">
                          <p className="business-two-for-you-card-price">
                            40,000/-
                          </p>
                          <p className="business-two-for-you-card-discount">
                            upto 20%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="large_padding_div"></div>
        </div>
      )}
    </div>
  );
};

export default BusinessTwoForYou;

import React from "react";
import { Link } from "react-router-dom";
import { useDocument } from "../firebase files/hooks/useDocument";
import "./HelpAndSupportIcon.css";

const HelpAndSupportIcon = () => {
  const { document: iconTypeDocument, error: iconTypeDocumentError } =
    useDocument("settings", "Icons");

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  return (
    <div>
      {iconTypeDocument &&
        iconTypeDocument.BottomRightFixedIcons.support == true &&
        dbAppTypeDocuments &&
        (dbAppTypeDocuments.apptype === "liteplus" ||
          dbAppTypeDocuments.apptype === "pro") && (
          <Link to="/ticketdetail" className="ab_whatsapp_icon">
            <img src="/assets/img/icons8-online-support.gif" />
          </Link>
        )}
    </div>
  );
};

export default HelpAndSupportIcon;

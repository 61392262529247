import React from 'react'
import Dashboard from './Dashboard'

const PGDashboard = () => {
  return (
    <div>
      <Dashboard/>
    </div>
  )
}

export default PGDashboard

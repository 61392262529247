import React from 'react';
import './EducationOneSupport.scss';

function TrustYourSales() {
  return (
    <section className="support-section">
        <div className="container">
            <div className="Support_inner">
      <div className="content-left-support">
        <h2>
          <span className="highlight">Why Choose US</span>
          <br />
          
        </h2>
        <h3>Trust our platfrom</h3>
        <p>
          Engage with Tutors and grow their skills. Transform your skills with the world's favorite  platform.
        </p>
        <div className="stats">
          <div className="stat-item">
            <div className="stat-item-left">
                <img src="https://cdn.icon-icons.com/icons2/1670/PNG/512/10207manstudentlightskintone_110568.png"/>
            </div>
            <div className="stat-item-right">
                <p className="para_support">Students</p>
                <h6 className="count_support"><span></span></h6>
           
          </div>
          </div>
          <div className="stat-item">
            <div className="stat-item-left">
                <img src="https://tse3.mm.bing.net/th?id=OIP.9rVexK7pzhlpQQesDRASwQHaHa&pid=Api&P=0&h=220"/>
            </div>
            <div className="stat-item-right">
                <p className="para_support">Teachers</p>
                <h6 className="count_support"><span></span></h6>
           
          </div>
          </div>
          <div className="stat-item">
            <div className="stat-item-left">
                <img src="https://tse3.mm.bing.net/th?id=OIP.Ty5UORzrwFdEEwd9xGdmPwHaGt&pid=Api&P=0&h=220"/>
            </div>
            <div className="stat-item-right">
                <p className="para_support">Professionals</p>
                <h6 className="count_support"><span></span></h6>
           
          </div>
        </div>
        <div className="stat-item">
            <div className="stat-item-left">
                <img src="https://tse2.mm.bing.net/th?id=OIP.4_9DWchOszXpNHXKGdbe3gHaHa&pid=Api&P=0&h=220"/>
            </div>
            <div className="stat-item-right">
                <p className="para_support">Access</p>
                <h6 className="count_support"><span></span></h6>
           
          </div>
        </div>
         
        </div>
      </div>

      <div className="images-right-support">
        <div className="image-container-support">
          <img src="https://happyerp.hypercloudapps.in/assets/img/home/counter_right.jpg" alt="Teamwork" />
          <img src="https://happyerp.hypercloudapps.in/assets/img/home/counter_right2.jpg" alt="Sales Meeting" />
        </div>
        <div className="image-container-support_two">
          <img src="https://happyerp.hypercloudapps.in/assets/img/home/counter_right2.jpg" alt="Sales Meeting" />
          <img src="https://happyerp.hypercloudapps.in/assets/img/home/counter_right.jpg" alt="Teamwork" />

        </div>
      </div>
    </div>
</div>
    </section>
  );
}

export default TrustYourSales;

import React from "react";
import { useDocument } from "../../firebase files/hooks/useDocument";
import CubeLoader from "../../default components/loaders/CubeLoader";
import CircularLoader from "../../default components/loaders/CircularLoader";
import DottedSpinner from "../../default components/loaders/DottedSpinner";
import SkeletonLoader from "../../default components/loaders/SkeletonLoader";
const PGLoaders = () => {
  const { document: loaderTypeDoc, error: loaderTypeDocerror } = useDocument(
    "settings",
    "loaders"
  );
  return (
    <div>
      {loaderTypeDoc && loaderTypeDoc.activeLoader === "skeletonloader" ? (
        <SkeletonLoader />
      ) : loaderTypeDoc && loaderTypeDoc.activeLoader === "dottedspinner" ? (
        <DottedSpinner />
      ) : loaderTypeDoc && loaderTypeDoc.activeLoader === "circularloader" ? (
        <CircularLoader />
      ) : loaderTypeDoc && loaderTypeDoc.activeLoader === "cubeloader" ? (
        <CubeLoader />
      ) : null}
    </div>
  );
};

export default PGLoaders;

import React from "react";
import { format } from "date-fns";

// import scss
import "./UserList.css";

// add class according to user role
const UserList = ({ user }) => {
  let userRoleClass = "";
  if (user.role == "admin") {
    userRoleClass = "admin";
  } else if (user.role == "frontdesk") {
    userRoleClass = "front_desk";
  } else {
    userRoleClass = "customer";
  }

  return (
    <div
      className={`user_card_single relative ${userRoleClass} ${
        user.status === "inactive" ? "inactive" : ""
      }`}
    >
      <div className="ucs_body">
        <div className="ucsb_left relative">
          <div className="img_container">
            <img src={user.photoURL}></img>
          </div>
        </div>
        <div className="ucsb_right">
          <div className="ucs_header">
            <div className="ucsh_left">
              <h6>Created at</h6>
              {/* <h5>{ user.createdAt }</h5> */}
              <h5>{format(user.createdAt.toDate(), "MMM dd, yyyy")}</h5>
            </div>
            <div className="ucsh_right">
              <h6>Last login</h6>
              <h5>
                {format(user.lastLoginTimestamp.toDate(), "MMM dd, yyyy")}
              </h5>
            </div>
          </div>
          <h4 className="u_name">{user.fullName}</h4>
          <h4 className="u_email">{user.email}</h4>
          <h4 className="u_phone">{user.phoneNumber}</h4>
        </div>
      </div>
      <div className="ucs_footer ">
        <h4 className="u_status relative">
          {user.status}
          <div className="pointer edit_bg">
            <img
              src="./assets/img/edit_icon.png"
              className="edit_sq_icon"
            ></img>
          </div>
        </h4>
        <h4 className="u_role relative">
          {user.role}
          <div className="pointer edit_bg">
            <img
              src="./assets/img/edit_icon.png"
              className="edit_sq_icon"
            ></img>
          </div>
        </h4>
      </div>
    </div>
  );
};

export default UserList;

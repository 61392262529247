import React from "react";
import "./EventOneWeek.scss";

const Week = () => {
  return (
    <div className="Week-events">
      <div className="container">
        <div className="Week_inner">
         
        <div className="section_header">
          <div className="left">
            <div className="icon">
              <span className="music-icon material-symbols-outlined">
                music_note
              </span>
            </div>
            <div>
              <h2 className="title">Events</h2>
            </div>
          </div>
          
        </div>
          <div className="cards">
            <div className="cards_inside">
                <h4 className="Cards_head">Today</h4>
                <p className="card_para">Mon, 12 Aug</p>
            </div>
            <div className="cards_inside">
                <h4 className="Cards_head">Today</h4>
                <p className="card_para">Mon, 12 Aug</p>
            </div>
            <div className="cards_inside">
                <h4 className="Cards_head">Today</h4>
                <p className="card_para">Mon, 12 Aug</p>
            </div>
        </div>
        </div>
    </div>
</div>
  );
};
export default Week
import React from "react";
import { Route, Routes } from "react-router-dom";

// import css 
// import '../eventOneCss/EventOneCss.scss'

// import pages
import EducationOneHome from "../educationOnePages/PGEducationOneHome";
import GlobalRoute from "../../../../global route/GlobalRoute";
import Navbar from "../../../../default components/Navbar";
import BusinessThreeNavbar from "../../../business/businessThree/businessThreeComponents/BusinessThreeNavbar";
import PGBusinessThreeProfile from "../../../business/businessThree/businessThreePages/PGBusinessThreeProfile";
import { useDocument } from "../../../../firebase files/hooks/useDocument";

const EducationOneRoute = () => {
  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  return (
    <div className="education_one">   
          {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? <Navbar /> : <BusinessThreeNavbar />}      
      <Routes>
        <Route path="/" element={<EducationOneHome/>}></Route>   
        <Route
            path="/profile"
            element={<PGBusinessThreeProfile />}
          />    
      </Routes> 
      <GlobalRoute/>   
    </div>
  )
}

export default EducationOneRoute;

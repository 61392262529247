import React from "react";
import "./CubeLoader.scss";

const CubeLoader = () => {
  return (
    <div className="cube_container_parent">
      <div className="h1Container">
        <div className="cube h1 w1 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w1 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w1 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w2 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w2 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w2 l3">
          <div className="face cubeloader-top"></div> 
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w3 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w3 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h1 w3 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>
      </div>

      <div className="h2Container">
        <div className="cube h2 w1 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w1 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w1 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w2 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w2 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w2 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w3 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w3 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h2 w3 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>
      </div>

      <div className="h3Container">
        <div className="cube h3 w1 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w1 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w1 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w2 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w2 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w2 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w3 l1">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w3 l2">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>

        <div className="cube h3 w3 l3">
          <div className="face cubeloader-top"></div>
          <div className="face cubeloader-left"></div>
          <div className="face cubeloader-right"></div>
        </div>
      </div>
    </div>
  );
};

export default CubeLoader;

import React from "react";
import "./DottedSpinner.css";
const DottedSpinner = () => {
  return (
    <div>
      <div className="loading"></div>
    </div>
  );
};

export default DottedSpinner;
